import axios from "axios";
import * as API from "api";
import storage from "utils/storage";
import * as commonService from "utils/CommonService";
export const preUploadData = (file, file_category, getUserId) => {
  // remove spaces from name
  let name = file.name.replace(/\s/g, '');
  // form pre upload data
  const uploadData = {
          data : {file_name : name,
              content_type : file.type,
              file_size : file.size,
              file_category: file_category
          },
            userId : getUserId ? getUserId : storage.get("humlog_user")
      };
  return uploadData;
}
export const uploadFileOnS3 = async (data, file) => {
  commonService.isLoading.onNext(true);
  // form data for s3 upload
  let formData = new FormData();
  formData.append('key', data.fields.key);
  formData.append('acl', data.file_acl);
  formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
  formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
  formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
  formData.append('Policy', data.fields.Policy);
  formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
  formData.append('Content-Type', data.fields['Content-Type']);
  formData.append('file', file);
  // post data to s3 bucket
  await axios.post(data.url, formData)
    .then( (res) => {
      commonService.isLoading.onNext(false);
    })
    .catch ( (err) => {
      commonService.isLoading.onNext(false);
      console.log("error", err);
    })
}
export const uploadFile = async (file,file_category,file_type, getUserId) => {
  // get pre upload data
  const uploadData = preUploadData(file,file_category, getUserId);
  const userId = uploadData.userId;
  let imageUrl;
  const preUpload = API.preUpload(uploadData.data, userId);
  // post pre upload api here
  await preUpload.then( (res) => {
    if(res.success) {
      const url = new URL(res.data.url).hostname;
      if(file_type === "private"){
        imageUrl = res.data.file_id;
      } else {
        imageUrl = `https://${res.data.fields.bucket}.${url}/${res.data.fields.key}`;
      }
      
      return res.data;
    }
  })
  .then ( async (data) => {
    // upload file on s3
    await uploadFileOnS3(data, file);
    return data.file_id;
  })
  .then ( (fileId) => {
    const data = {
      file_id : fileId
    }
    // acknowledge upload file
    const postUpload = API.postUpload(data,userId);
    postUpload
    .then( (res) => {})
    .catch( (err) => {
      console.log("error in post upload", err);
    })
  })
  .catch( (err) => {
    console.log("error in pre upload", err);
  })
    return imageUrl;
}
export const downloadFile = async (fileId) => {
  const userId = storage.get("humlog_user");
  let fileUrl = "";
  const download = API.downloadFile(fileId,userId);
  await download.then((response) => {
    if(response && response.success){
      fileUrl = response.data.url;
    }
  })
  .catch((error)=>{console.log("error in downloading",error)});;
  return fileUrl;
}