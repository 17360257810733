import axios from 'config';
import * as API from 'api';
import storage from 'utils/storage';
import { SaarthiActionTypes } from './actionType';
export const createSaarthi = (body) => dispatch => dispatch({
    type: SaarthiActionTypes.CREATE_SAARTHI,
    payload: API.createSaarthi(body).then(response => {
        return response;
    })
});
// Saarthi List
export const getSaarthiList = (query) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_SAARTHI_LIST,
    payload: API.getSaarthilist(query).then(response => {
        return response;
    })
});
// Saarthi details by Id
export const saarthiDetails = (userId) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_SAARTHI_DETAILS,
    payload: API.saarthiDetails(userId),
});
// Job seeker List
export const getJobseekerList = (query) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_JOBSEEKER_LIST,
    payload: API.getJobSeekerList(query).then(response => {
        return response;
    })
});
// Saarthi details by Id
export const jobSeekerDetails = (userId) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_JOBSEEKER_DETAILS,
    payload: API.jobSeekerDetails(userId),
});
// Dashbord Counts
export const countOnDashboard = () => dispatch => dispatch({
    type: SaarthiActionTypes.GET_DASHBOARD_COUNT,
    payload: API.saarthiDashboard(),
});

// Saarthi Change Password
export const changePassword = (body) => dispatch => dispatch({
    type: SaarthiActionTypes.CHANGE_PASSWORD,
    payload: API.changePassword(body),
});

export const updateJSProfile = (body) => dispatch => dispatch({
    type: SaarthiActionTypes.UPDATE_JS_PROFILE,
    payload: API.updateJSProfileBySaarthi(body),
});

export const getSaarthiJobs = (body, page) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_JOBS,
    payload: API.getSaarthiJobs(body, page).then(response => {
        return response;
    })
});
// Update Job details by Saarthi
export const saarthiUpdateJob = (body, jobId) => dispatch => dispatch({
    type: SaarthiActionTypes.SAARTHI_UPDATE_JOB,
    payload: API.saarthiUpdateJob(body, jobId).then(response => {
        return response;
    })
})
export const getSaarthiJobDetails = (jobId) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_JOB_DETAILS,
    payload: API.getSaarthiJobDetails(jobId).then(response => {
        return response;
    })
});

export const downloadJobSeekerData = (body) => dispatch => dispatch({
    type: SaarthiActionTypes.DOWNLOAD_JOBSEEKER_DATA,
    payload: API.downloadJobSeekerData(body).then(response => {
        return response;
    })
});

export const addCommunityVideo = (body) => dispatch => dispatch({
    type: SaarthiActionTypes.ADD_COMMUNITY_VIDEO,
    payload: API.addCommunityVideo(body).then(response => {
        return response;
    })
});

export const getCommunityVideoList = (query) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_COMMUNITY_VIDEOS_LIST,
    payload: API.getCommunityVideoList(query).then(response => {
        return response;
    })
});
export const getSaarthiJobSeekers = (body, page) => dispatch => dispatch({
    type: SaarthiActionTypes.GET_SAARTHI_JOBSEEKER,
    payload: API.getSaarthiJobSeekers(body, page).then(response => {
        return response;
    })
})