import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action Toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip
import { FiMoreVertical } from "react-icons/fi";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaTimes } from "react-icons/fa";
import "../../../assets/style/performance.scss";
import { Image } from "components/shared/imagecomponent/image";
import avatar from "../../../assets/images/male_avatar.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(
  id,
  name, // variable for jobseeker name
  district, // variable for district
  registrationDate, // variable for registration date
  jobVisible, // variable for job visible count
  applied, // variable for jobs applied by the particular job seeker
  shortlisted // variable for jobs shortlisted by the particular job seeker)
) {
  return {
    id,
    name,
    district,
    registrationDate,
    jobVisible,
    applied,
    shortlisted
  };
}

// Employee Table Columns
const headCells = [
  // header for name
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // header for district
  {
    id: "district",
    numeric: false,
    disablePadding: false,
    label: "Home District",
    align: "center",
  },
  // header for registration date
  {
    id: "registrationDate",
    numeric: false,
    disablePadding: false,
    label: "Registration Date",
    align: "center",
  },
  // header for job visible
  {
    id: "jobVisible",
    numeric: false,
    disablePadding: false,
    label: "Job Visible",
    align: "center",
  },
  {
    id: "applied",
    numeric: false,
    disablePadding: false,
    label: "Applied",
    align: "center",
  },
  // header for shortlisted
  {
    id: "shortlisted",
    numeric: false,
    disablePadding: false,
    label: "ShortListed",
    align: "center",
  },
  // header for action
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Custom sort when click on individual column
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const Performance = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 ); 
  const classes = useStyles();
  const { userList } = useSelector((state) => state.saarthiReducer); // get user data
  const [verificationStatus, setStatus] = useState("All");
  const [table, setTable] = useState([]); // storing table data
  const [employerCount, setEmployerCount] = useState(0); // storing total employer count
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [setCount, setTotalCount] = useState(0); // storing total count
  const sortingIconClicked = useRef(false); // check sorting icon is clicked or unclicked
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const [openDialogModal, setOpenDialogModal] = useState({ isOpen: false });
  const [jobVisibleModal, setJobVisibleModal] = useState({ isOpen: false });
  const [filterDesiredData, setfilterTableData] = useState();
  const [filterDesiredJobVisibleData, setfilterJobVisibleData] = useState();
  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setRows([]);
    async function fetchData(pageNumber) {
      const body = {
        user_type: 0
      };
      if (searched && searched.length) {
        body.search = searched;
      }
      if (sort) {
        body.sort = sort;
      }
      dispatch(saarthiActions.getSaarthiJobSeekers(body, pageNumber ? pageNumber : page))
        .then((response) => {
          if (response && response.value.success) {
            setEmployerCount((prev) => response.value.data.count);
            setTableData(response.value.data.data);
            setTotalCount((prev) => response.value.data.count); // get and set total count
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchData(pageNumber); //api to fetching table data
  }, [dispatch, verificationStatus, page, sort, searched]);

  // Close Job Share Modal
  const shareCloseDeactive = () => {
    setOpenDialogModal({ isOpen: false });
  };

  const closeJobVisibleModal = () => {
    setJobVisibleModal({ isOpen: false });
  };
  // set Table data method to update table values
  const setTableData = async (userList) => {
    let employerTable = [];
    if (userList && userList.length) {
      let tempArray = [...userList];
      for (let val of tempArray) {
        let applied = 0;
        let shortlisted = 0;
        if (val.jobs && val.jobs.length) {
          applied = val.jobs.filter((jobs) => jobs.status === 'Applied' && jobs.job_status === 0).length;
        }
        if (val.jobs && val.jobs.length) {
          shortlisted = val.jobs.filter((jobs) => jobs.status === 'Shortlisted' && jobs.job_status === 0).length;
        }
        let doc_no = "";
        if (val.documents) {
          Object.entries(val.documents).forEach((item) => {
            // iterate over documents
            if (item[1].doc_no && item[1].doc_no.length) {
              doc_no = item[1].doc_no;
            }
          });
        }
        // form table data
        let data = createData(
          val._id ? val._id : '',
          val.name ? val.name : "",
          val.address_home ? val.address_home : '',
          val.created_at ? new Date(val.created_at).toLocaleDateString() : '',
          val.matching_jobs ? val.matching_jobs : '',
          applied,
          shortlisted
        );
        employerTable.push(data);
      }
    }
    setTable(employerTable);
    setRows(employerTable); // get table data
    setTable((state) => {
      return state;
    });
    return employerTable;
  };
  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/performance', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/performance', search: `?page=${pageNumber}&sort=${sort}` });
  };
  /**
   * @description method to open/close the menu options
   * @param {param} e event 
   */
  const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('table_dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if (sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/performance', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/performance', search: `?page=${page}&sort=${'Asc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  // View Jobs whose status "Applied or shortlisted"
  const viewAppliedJobs = (jobs, status) => {
    let desiredJobs = [];
    if (jobs && jobs.length) {
      desiredJobs = jobs.filter((job) => job.status === status && job.job_status === 0);
    }
    setfilterTableData(desiredJobs);
    setOpenDialogModal({ isOpen: true });
  }
  // View All Job Visible
  const viewJobVisible = (matching_jobs) => {
    setfilterJobVisibleData(matching_jobs);
    setJobVisibleModal({ isOpen: true });
  }
  // Open Profile Component
  const jobSeekerProfile = (e, id) => {
    history.push({ pathname: '/saarthi-jobseeker-profile', search: "?user_id=" + id });
  }
  return (
    <>
      {/* performance-section start from here */}
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              {/* employer-total-count-section start from here */}
              <div className="table_menu_lft">
                <p>Job Seeker Count : {employerCount}</p>
              </div>
              {/* employer-total-count-section end */}
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
                {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index, status) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            {/* name-section start from here */}
                            <TableCell component="th" id={labelId} scope="row">
                              {row.name}
                            </TableCell>
                            {/* name-section start from here */}
                            {/* district-section start from here */}
                            <TableCell align="center">{row.district}</TableCell>
                            {/* district-section end */}
                            <TableCell align="center">{row.registrationDate}</TableCell>
                            {/* job-visible-section start from here */}
                            <TableCell align="center">{row.jobVisible.length === 0 ? <span className="jscount inactive">0</span> : <span data-tip="View Job Visible" className="jscount" onClick={() => viewJobVisible(userList.data[index].matching_jobs, 'JobVisible')}>{row.jobVisible.length}</span>}<ReactTooltip /></TableCell>
                            {/* job-visible-section end */}
                            {/* applied-section start from here */}
                            <TableCell align="center">{row.applied === 0 ? <span className="jscount inactive">0</span> : <span data-tip="View Applied" className="jscount" onClick={() => viewAppliedJobs(userList.data[index].jobs, 'Applied')}>{row.applied}</span>}<ReactTooltip /></TableCell>
                            {/* applied-section end */}
                            {/* shortlisted-section start from here */}
                            <TableCell align="center">{row.shortlisted === 0 ? <span className="jscount inactive">0</span> : <span data-tip="View ShortListed" className="jscount" onClick={() => viewAppliedJobs(userList.data[index].jobs, 'Shortlisted')}>{row.shortlisted}</span>}<ReactTooltip /></TableCell>
                            {/* shortlisted-section end */}
                            <TableCell
                              align="right"
                              style={{ position: "relative" }}
                            >
                              {
                                <div className="table_dropdownbox">
                                  <button
                                    type="button"
                                    className="table_moreoptns table_dropbtn"
                                    onClick={toggleMenu}
                                  >
                                    <FiMoreVertical />
                                  </button>
                                  <div className="table_dropdownmenu table_option_menu table_dropdown-content">
                                    <ul>
                                      <li>
                                        <a
                                          href="javascript:void(0)" onClick={(e) => jobSeekerProfile(e, row.id)}
                                        >
                                          Open Profile
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* pagination-section start from here */}
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button
                    type="button"
                    onClick={() => next(page + 1, sort)}
                    disabled={
                      userList && userList.data && userList.data.length === 30
                        ? false
                        : true
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
              {/* pagination-section end */}
            </div>
          </div>
        </div>
      </div>
      {/* performance-section end */}
      {/* Applied or shortlisted modal start from here */}
      <Dialog
        open={openDialogModal.isOpen}
        onClose={shareCloseDeactive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-box"
      >
        {/* Close-button-section*/}
        <button className="modal-cross-btn" onClick={shareCloseDeactive}>
          <FaTimes />
        </button>
        <DialogContent>
          {filterDesiredData && filterDesiredData.length && filterDesiredData.map(
            (data) => {
              return (
                <div>
                  <div className="content-main-box">
                    <div className="row p-3 border-bottom">
                      <div className="col-md-4">
                        {data.company_logo ? (
                          <div style={{ width: "80px", height: "80px" }}>
                            <Image picture={`${data.company_logo}?${Date.now()}`} alt="user_profile"></Image>
                          </div>
                        ) : (
                          <div style={{ width: "80px", height: "80px" }}>
                            <Image picture={avatar} alt="user_profile"></Image>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <p>{data.company_name}</p>
                        <p>{data.job_title}</p>
                        <p>{data.job_location}</p>
                        <p>{new Date(data.date).toLocaleDateString()}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </DialogContent>
      </Dialog>
      {/* Applied or shortlisted end */}
      {/* Job Visible Modal start from here */}
      <Dialog
        open={jobVisibleModal.isOpen}
        onClose={closeJobVisibleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-box"
      >
        {/* Close-button-section*/}
        <button className="modal-cross-btn" onClick={closeJobVisibleModal}>
          <FaTimes />
        </button>
        <DialogContent>
          {filterDesiredJobVisibleData && filterDesiredJobVisibleData.length && filterDesiredJobVisibleData.map(
            (data) => {
              return (
                <div>
                  <div className="content-main-box">
                    <div className="row p-3 border-bottom">
                      <div className="col-md-4">
                        {data.company_logo ? (
                          <div style={{ width: "80px", height: "80px" }}>
                            <Image picture={`${data.company_logo}?${Date.now()}`} alt="user_profile"></Image>
                          </div>
                        ) : (
                          <div style={{ width: "80px", height: "80px" }}>
                            <Image picture={avatar} alt="user_profile"></Image>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <p>{data.company_name}</p>
                        <p>{data.job_title}</p>
                        <p>{data.job_location}</p>
                        <p>{new Date(data.created_at).toLocaleDateString()}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </DialogContent>
      </Dialog>
      {/* Job Visible Modal end */}
    </>
  );
};
