import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../../common/enhancedTableHead";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { IoAddCircleOutline } from "react-icons/io5"; // roles Icon
import { Multiselect } from "multiselect-react-dropdown";
import * as adminActions from "redux/actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { RiEdit2Fill } from "react-icons/ri";
import { regexValidation } from "utils/regexValidation";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
}));
function createData(id, roleName, createdDate, permission, action) {
    return {
        id, roleName, createdDate, permission, action
    };
}

// Roles Table Columns
const headCells = [
    { id: 'roleName', numeric: false, disablePadding: false, label: 'Role Name', align: 'left' },
    { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created Date', align: 'center' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Custom sort when click on individual column
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const CreateRoles = () => {
    const { register, errors, handleSubmit, reset } = useForm({
        mode: "onChange",
    });
    const classes = useStyles();
    const [table, setTable] = useState([]);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
    const [rows, setRows] = useState([]); // variable use for storing table data
    const [openDateModal, setOpenDateModal] = useState(false); // variable use for open & close modal
    const [permissionValue, setPermissionValue] = useState([]); // storing permission ids
    const [selectedValue, setSelectedValue] = useState([]); // storing permission selected values
    const [permissionError, setPermissionError] = useState(''); // varible use for showing permission error
    const [savePermissions, setPermission] = useState([]); // storing permission name
    const [updateRolesValue, setUpdateRolesValue] = useState([]); // storing update roles values
    const [roleCount, setRoleCount] = useState(''); // storing total roles count
    const [updateApiValue, setUpdateApi] = useState('');
    const [roleId, setRoleId] = useState('');
    const dispatch = useDispatch();
    const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage


    useEffect(() => {
        apiGetPermission();
        apiGetRoles();
    }, [dispatch]);
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    // API for get all permission
    const apiGetPermission = async () => {
        try {
            let response = await dispatch(adminActions.getPermission());
            if (response.value.success) {
                setPermission(response.value.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // API for get roles
    const apiGetRoles = async () => {
        try {
            let response = await dispatch(adminActions.getRole());
            if (response.value.success) {
                setRoleCount(response.value.data.length);
                handleTableData(response.value.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Create role & update role Modal 
    const dataModalOpen = (name, row, savePermissions) => {
        // Update role modal 
        if (name === 'update_role') {
            let filterData = row.permission.filter((value) => value.id === savePermissions._id);
            setSelectedValue(filterData);
            let selectedData = [];
            for (let val of filterData) {
                const data = savePermissions.filter((value) => value._id === val);
                selectedData.push(data);
            }
            let data = Array.prototype.concat.apply([], selectedData);
            setPermissionValue(data);
            setUpdateRolesValue(row);
            setOpenDateModal(true);
            setUpdateApi(true);
            setRoleId(row.id);
            // Create role modal
        } else if (name === 'create_role') {
            setPermissionValue('');
            setUpdateRolesValue('');
            setOpenDateModal(true);
            setUpdateApi(false);
        }
    };

    // closing create roles modal
    const dataModalClose = () => {
        setOpenDateModal(false);
    };
    // selecting roles
    const handleselectRoles = (e) => {
        let value = e.map((value) => value._id);
        setPermissionValue(e);
        setSelectedValue(value);
        setPermissionError("");
    }
    // remove roles
    const handleRemovedRole = (e) => {
        let value = e.map((value) => value._id);
        setPermissionValue(e);
        setSelectedValue(value);
    }
    // API for post & update roles
    const handlePostRole = async (val) => {
        if (permissionValue.length > 0) {
            try {
                let data = {
                    ...val,
                    _id: roleId,
                    permission_list: selectedValue,
                };
                if (updateApiValue === false) {
                    delete data._id;
                }
                let response = updateApiValue === true ? await dispatch(adminActions.updateRole(data)) : await dispatch(adminActions.postRole(data));
                if (response.value.success) {
                    apiGetRoles(); // hitting api for get all roles
                    setPermissionValue('');
                    reset();
                    dataModalClose();
                    setPermissionError("");
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setPermissionError("Please select any permission");
        }

    }
    // fill table data
    const handleTableData = async (roleDataList) => {
        let jobseekerTable = [];
        if (roleDataList && roleDataList.length) {
            let tempArray = [...roleDataList]
            for (let val of tempArray) {
                let data = createData(
                    val._id ? val._id : '', // get roles id
                    val.name ? val.name : '', // get roles name
                    val.updated_at ? new Date(val.updated_at).toLocaleDateString() : '', // get created date
                    val.permission_list ? val.permission_list : '' // get permissions
                );
                jobseekerTable.push(data);
            }
        }
        setTable(jobseekerTable);
        setRows(jobseekerTable);
        setTable((state) => {
            return state;
        })
        return jobseekerTable;
    }
    return (
        <>
            <div className="reg_main employer_section">
                <div className="container-fluid">
                    <div className="data_download">
                        {commonService.showHideBaseOnPermission(adminPermission, [appSetting.ROLES.ROLE_CREATE]) ?
                        <div type="button" className="new-role-button" onClick={() => dataModalOpen('create_role')}>
                            <span><IoAddCircleOutline /> Add New Role</span>
                        </div> : ''}
                    </div>
                    <div className="reg_inner">
                        <div className="table_menu">
                            <div className="table_menu_lft">
                                <p>Roles Count : {roleCount}</p>
                            </div>
                        </div>
                        <div className="table_box">
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="enhanced table">
                                    {/* Table Action Toolbar Start From Here */}
                                    <EnhancedTableHead
                                        headCells={headCells}
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={table.length}
                                    />
                                    {/* Table Action Toolbar End */}
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                return (
                                                    <TableRow hover key={row.id}>
                                                        {/* Table columns start from here */}
                                                        <TableCell align="left">{row.roleName}</TableCell>
                                                        <TableCell align="center">{row.createdDate}</TableCell>
                                                        <TableCell align="right">
                                                        {commonService.showHideBaseOnPermission(adminPermission, [appSetting.ROLES.ROLE_UPDATE]) ? 
                                                            <span className="pencil-edit-icon" onClick={() => dataModalOpen('update_role', row, savePermissions)}>
                                                                <RiEdit2Fill className="edit-mail-icon" />
                                                            </span> : ''}
                                                        </TableCell>
                                                        {/* Table columns end */}
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={classes.root}>
                                <div className="pagination">
                                    <button
                                        type="button"
                                    >
                                        Prev
                                    </button>
                                    <span>1</span>
                                    <button
                                        type="button"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for create roles and update roles start from here */}
            <Dialog
                open={openDateModal}
                onClose={dataModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="create-role-modal"
            >
                <DialogTitle id="alert-dialog-title" className="role-title">
                    {updateApiValue === true ? "Update Role" : "Create New Role"}
                </DialogTitle>
                <button className="modal-cross-btn" onClick={dataModalClose}><FaTimes /></button>
                <DialogContent className="reason_modal_content">
                    <DialogContentText id="alert-dialog-description">
                        {/* Create-roles form start from here */}
                        <form onSubmit={handleSubmit(handlePostRole)}>
                            <div className="form-group">
                                <label htmlFor="name">
                                    <span className="datelabel">Role Name</span>
                                </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="enter role name"
                                    defaultValue={updateRolesValue.roleName ? updateRolesValue.roleName : ''}
                                    className={classNames("form-control", {
                                        "is-invalid": errors.name,
                                    })}
                                    ref={register({
                                        required: "Please enter role name",
                                        minLength: {
                                            value: 2,
                                            message: "Please enter minimum 2 character",
                                        },
                                        maxLength: {
                                            value: 30,
                                            message: "Please enter maximum 30 character",
                                        },
                                        pattern: {
                                            value: regexValidation.ROLE_NAME,
                                            message: "Numbers and special characters not allowed",
                                        },
                                    })}
                                />
                                {errors.name && (
                                    <span className="error_font text-danger">
                                        {errors.name.message}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="permissions">
                                    <span className="datelabel">Permission</span>
                                </label>
                                <Multiselect options={savePermissions}
                                    onSelect={(e) => handleselectRoles(e)}
                                    onRemove={(e) => handleRemovedRole(e)}
                                    selectionLimit={20}
                                    isObject={true}
                                    placeholder="select permissions"
                                    displayValue="name"
                                    name="permissions"
                                    selectedValues={permissionValue}
                                />
                                {permissionError && (
                                    <span className="error_font text-danger">
                                        {permissionError}
                                    </span>
                                )}
                            </div>
                            <button type="submit" id="submit" className="submit-button">{updateApiValue === true ? 'UPDATE' : 'SUBMIT'}</button>
                        </form>
                    </DialogContentText>
                    {/* Create-roles form end */}
                </DialogContent>
            </Dialog>
            {/* Modal for create roles and update roles end */}
        </>
    );
};
