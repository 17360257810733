import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as adminActions from "redux/actions/AdminActions";
import * as commonService from "utils/CommonService.js";
import house from "../../../assets/images/admin/house.svg";
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import building from "../../../assets/images/admin/building.svg";
import email from "../../../assets/images/admin/email.svg";
import location_pin from "../../../assets/images/admin/location-pin.svg";
import industry from "../../../assets/images/admin/industry.svg";
import avatar from "../../../assets/images/male_avatar.png";
import video from "../../../assets/images/admin/video.svg";
import Alert from "@material-ui/lab/Alert";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DistrictList } from "../../../utils/DistrictList";
import { regexValidation } from "utils/regexValidation";
import { IndustryData } from "../../../utils/IndustryData";
import { FaCamera, FaPlusCircle, FaRegPlayCircle } from "react-icons/fa";
import { uploadFile } from "../../../utils/fileService";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "../../common/player";

export const AddEmployer = () => {
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [error, setError] = useState({ open: false, message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * @description method to open/close video player modal
   */
  const [modal, setModal] = useState(false);
  const onToggleModal = () => {
      setModal(!modal);
  };
  /**
   * @description method to save company profile picture/logo
   */
  const [picture, setPicture] = useState(avatar);
  const [uploadFiles, setUploadFile] = useState();
  const onChangePicture = async (e) => {
    setPicture(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  /**
   * @description method to save company video
   */
  const [vedioErr, setVedioErr] = useState(null);
  const [companyVideo, setCompanyVideo] = useState();
  const [isError, setisError] = useState({open: false, message: ''});
  const uploadVedioResume = async (e) => {
    let video = e.target.files[0];
    if(!commonService.validateFileContentTypeAndSize(video.type, 'video')){
      setisError({open: true, message : 'Please upload the correct format file.'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    if(!commonService.validateFileSize(video.size, 200)){
      setisError({open: true, message : 'File size should be less than 200 MB'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    let videoUrl = await uploadFile(video,'video');
    setCompanyVideo(videoUrl);
  };
  /**
   * Method to create a Employer
   * @param {Object} val Employer data
   */
  const createEmployer = async (val) => {
    try {
      let imageUrl;
      let data = { ...val };
      if(uploadFiles) {
        imageUrl = await uploadFile(uploadFiles,'profile');
        data.profile_pic = imageUrl;
      }
      if(companyVideo){
        data.video_resume = companyVideo;
      }
      let res = await dispatch(adminActions.createEmployer(data));
      if (res && res.value.success) {
        setSuccess({ open: true, message: "Employer Created Successfully" });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      }
    } catch (e) {
      console.log("error in creating employer", e);
      if(e.data.error_code === "ALREADY_REGISTERED") {
        setError({open:true, message: 'Employer Already Registered'});
      } else {
        setError({open:true, message: 'Error In Creating Employer'});
      }
    }
  };

  return (
    <>
      {error.open && (
        <Alert severity="error" className="alert-toast">
          {error.message}
        </Alert>
      )}
      {success.open && (
        <Alert severity="success" className="alert-toast">
          {success.message}
        </Alert>
      )}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Add Employer</h6>
            <div className="employer_profile_form add_saarthi_form">
              <button type="button" className="backbtn" onClick={() => history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form onSubmit={handleSubmit(createEmployer)}>

                {/* employer image add start */}

                <div className="profile_img emp_profile_img">
                  <Image picture={picture} alt="Profile Picture"></Image>
                  <div className="profile_img_add">
                    <label htmlFor="profile_pic">
                      <input type="file" accept=".png, .jpg, .jpeg" className="d-none" id="profile_pic" name="profile_pic" onChange={onChangePicture}
                      />
                      <FaCamera className="camera_position" />
                    </label>
                  </div>
                </div>

                {/* employer image add end */}


                {/* Company Name start*/}

                <div className="form-group">
                  <label htmlFor="company_name">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Company Name</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.company_name,
                    })}
                    name="company_name"
                    id="company_name"
                    placeholder="Enter Company Name"
                    ref={register({
                      required: "Please enter company name",
                      minLength: {
                        value: 4,
                        message: "Please Enter Minimum 4 Character",
                      },
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 Character limit",
                      },
                      pattern: {
                        value: regexValidation.COMPANY_NAME,
                        message: "Company Name can not start with special characters",
                      },
                    })}
                  />
                  {errors.company_name && (
                    <span className="error_font text-danger">
                      {errors.company_name.message}
                    </span>
                  )}
                </div>

                {/* Company Name end*/}

                {/* Company Category start*/}

                <div className="form-group">
                  <label htmlFor="company_category">
                    <img src={industry} alt="industry" className="img-fluid" />
                    <span>Company Category</span>
                  </label>
                  <select
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.company_category,
                    })}
                    name="company_category"
                    id="company_category"
                    placeholder="Select Company Category"
                    ref={register({
                      required: "Please Select a Company Category",
                    })}
                  >
                    <option value="" disabled selected>Select Category</option>
                    {IndustryData.map((val) => {
                      return (
                        <>
                          <option>{val}</option>
                        </>
                      );
                    })}
                  </select>
                  {errors.company_category && (
                    <span className="error_font text-danger">
                      {errors.company_category.message}
                    </span>
                  )}
                </div>

                {/* Company Category end*/}

                {/* Employer Name start*/}

                <div className="form-group">
                  <label htmlFor="name">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Employer Name</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.name,
                    })}
                    name="name"
                    id="name"
                    placeholder="Enter full name"
                    ref={register({
                      required: "Please enter contact person name",
                      pattern: {
                        value: regexValidation.PERSON_NAME,
                        message: "Numbers and special characters not allowed",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maximum 30 characters can be entered",
                      },
                    })}
                  />
                  {errors.name && (
                    <span className="error_font text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </div>

                {/* Employer Name end*/}

                {/* Email start */}

                <div className="form-group">
                  <label htmlFor="email">
                    <img src={email} alt="industry" className="img-fluid" />
                    <span>Email</span>
                  </label>
                  <input
                    type="email"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.email,
                    })}
                    name="email"
                    id="email"
                    placeholder="Enter Email Id"
                    ref={register({
                      required: "Please enter email id",
                      pattern: {
                        value: regexValidation.EMAIL,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error_font text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </div>

                {/* Email end */}

                {/* Mobile Number start*/}

                <div className="form-group">
                  <label htmlFor="contact">
                    <img src={phone} alt="person" className="img-fluid" />
                    <span>Mobile Number</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.contact,
                    })}
                    name="contact"
                    id="contact"
                    placeholder="Enter Mobile Number"
                    ref={register({
                      required: "Please enter mobile number",
                      pattern: {
                        value: regexValidation.PHONE,
                        message: "Please enter valid mobile number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Maximum 10 digits are allowed",
                      },
                    })}
                  />
                  {errors.contact && (
                    <span className="error_font text-danger">
                      {errors.contact.message}
                    </span>
                  )}
                </div>

                {/* Mobile Number end*/}

                {/* Company Address start*/}

                <div className="form-group">
                  <label htmlFor="tehsil">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Company Address</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.company_address,
                    })}
                    name="company_address"
                    id="company_address"
                    placeholder="Enter Company Address"
                    ref={register({
                      required: "Please enter company address",
                      minLength: {
                        value: 4,
                        message: "Minimum 4 digits allowed",
                      },
                      maxLength: {
                        value: 100,
                        message: "Maximum 100 Character limit",
                      },
                      pattern: {
                        value: regexValidation.COMPANY_ADDRESS,
                        message: "Company Address can contain alphanumeric characters and special character('.', ',', '-'). Company Address can not start with special characters",
                      },
                    })}
                  />
                  {errors.company_address && (
                    <span className="error_font text-danger">
                      {errors.company_address.message}
                    </span>
                  )}
                </div>

                {/* Company Address end*/}

                {/* City/District start */}

                <div className="form-group">
                  <label htmlFor="address_home">
                    <img src={house} alt="house" className="img-fluid" />
                    <span>City / District</span>
                  </label>
                  <Autocomplete
                    id="address_home"
                    name="address_home"
                    ref={register}
                    options={DistrictList.sort()}
                    getOptionLabel={(option) => option}
                    style={{ width: "100%" }}
                    className={classNames("mt-2 p-0 m-0 location_list", {
                      "is-invalid": errors.address_home,
                    })}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select your home district"
                        name="address_home"
                        inputRef={register({
                          required: "Please select a district",
                        })}
                        {...params}
                        variant="outlined"
                        // require
                      />
                    )}
                  />
                  {errors.address_home && (
                    <span className="error_font text-danger">
                      {errors.address_home.message}
                    </span>
                  )}
                </div>

                {/* City/District end */}

                {/* Pincode start */}

                <div className="form-group">
                  <label htmlFor="pincode">
                    <img src={location_pin} alt="location" className="img-fluid width11" />
                    <span>Pincode</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.pincode,
                    })}
                    name="pincode"
                    id="pincode"
                    placeholder="Enter Pincode"
                    ref={register({
                      required: "Please enter pincode",
                      minLength: {
                        value: 6,
                        message: "Please Enter Minimum 6 Character",
                      },
                      maxLength: {
                        value: 6,
                        message: "Maximum 6 Character limit",
                      },
                      pattern: {
                        value: /^\d/,
                        message: "Please enter only numeric values"
                      }
                    })}
                  />
                  {errors.pincode && (
                    <span className="error_font text-danger">
                      {errors.pincode.message}
                    </span>
                  )}
                </div>

                {/* Pincode end */}

                {/* add company video start */}

                <div class="form-group positon_rel">
                  <label htmlFor="">
                    <img src={video} alt="industry" className="img-fluid" />
                    <span>Company Video</span>
                  </label>
                  <div className="upload_emp_video_box"> 
                    <label for="vedio-resume" className="mt-2">
                      <input type="file" className="d-none" id="vedio-resume" accept="video/*" onChange={uploadVedioResume} />
                      <span htmlFor="vedio-resume" className="add_cmpny_video"><FaPlusCircle />Add Video</span>
                    </label>
                  </div>

                  {vedioErr?.length > 0 && (
                    <div className="text-danger">{vedioErr}</div>
                  )}
                  <a className="">
                  {
                    companyVideo && companyVideo.length
                      ? <div>
                          <p className="video_box">
                            Video uploaded successfully
                          </p>
                          <button type="button" className="video_btn" onClick={onToggleModal} disabled={companyVideo && companyVideo.length ? false : true}>
                          <FaRegPlayCircle className="btn_icon" /></button>
                        </div>
                      : ("")
                  }
                  <Player open={modal} toggleModal={onToggleModal} src={companyVideo} />
                  </a>
                  
                </div>

                {/* add company video end */}

                <div className="submit_btn_container">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
