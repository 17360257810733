import React, { useContext, useState, useEffect, useParams } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { Context } from "Languages/Wrapper/Wrapper";
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
// import { Line } from 'react-chartjs-2';
// import { Bar } from 'react-chartjs-2';

export const SaarthiDashboard = ({ history }) => {
  
  const dispatch = useDispatch();
  const { dashboardCount } = useSelector((state) => state.saarthiReducer);
  const [userStats, setUserStats]=useState([]);
  const [resumeCount, setResumeCount] = useState(void 0);
  // const [jobOpportunities, setJobOpportunities] = useState(void 0);
  const context = useContext(Context);
  const [isMenuShow, setMenu] = useState(false);
  const [adminSaarthiLabel, setAdminSaarthiLabel] = useState(['Job Seeker Mapped', 'Job Seeker Unmapped', 'Job Posts']);
  const [SaarthiLabel, setSaarthiLabel] = useState(['Job Seeker Mapped',]);
  const token = storage.get('humlog_authToken');
  const decodedToken = jwt_decode(token);
  useEffect(() => {
    
    try {
      dispatch(saarthiActions.countOnDashboard()).then(response=>{
        // get Dasahboard Counts for ShortListed, Not-shortlisted and Applied Job Seekers
        let userDetails =response.value.data.user_job_stats.reduce(
          (obj, item) => Object.assign(obj, { [item._id]: item.total_users }), {}
        );
        setUserStats(userDetails)
        setResumeCount(response.value.data.total_resume_count) // getting total no. of downloaded Resume counts
        // setJobOpportunities(response.value.data.total_job_opp) // Total sum of the no. of jobs visible to each Jobseeker
      })
    } catch (e) {
      console.log('error', e);
    }
  }, [dispatch]);
  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenu(isMenuShow ? false : true);
  };
  document.addEventListener('click', function () {
    setMenu(false);
  });


  return (
    <>
      <div className="admin_dashboard saarthi_dashboard">
        <div className="container-fluid">
          <div className="ad_inner">
            <fieldset className="sectionHeading mb-0 pb-0">
              <h4 className="sectionTitle">JobSeeker Counts</h4>
              <div className="row">
                {/* Total Job Seekers Counts */}
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox" onClick={() => history.push("/jobseeker-list/alljobseeker")}>
                    <h4>Total Job Seekers</h4>
                    <h2>{dashboardCount && dashboardCount.total_jobseeker ? dashboardCount.total_jobseeker : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox" onClick={() => history.push("/jobseeker-list/withname")}>
                    <h4>Free Job Seeker(<span>With Name</span>)</h4> {/* Free JobSeeker with basic details filled i.e Name counts */}
                    <h2>{dashboardCount && dashboardCount.js_mapped_withName ? dashboardCount.js_mapped_withName : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox" onClick={() => history.push("/jobseeker-list/withoutname")}>
                    <h4>Free Job Seeker(<span>Without Name</span>)</h4> {/* Free JobSeeker without basic details filled i.e Name counts */}
                    <h2>{dashboardCount && dashboardCount.js_mapped_withoutName ? dashboardCount.js_mapped_withoutName : 0}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4>100% Completed Profile</h4> {/* JobSeeker with 100% complete profile counts */}
                    <h2>{dashboardCount && dashboardCount.total_completed_profile ? dashboardCount.total_completed_profile : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox" onClick={() => history.push("/jobseeker-list/videoResume")}>
                    <h4>Video Resume</h4> {/* JobSeeker with Video Resume detail filled counts */}
                    <h2>{dashboardCount && dashboardCount.total_video_resume ? dashboardCount.total_video_resume : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox" onClick={() => history.push("/jobseeker-list/premium")}>
                    <h4>Premium Job Seeker</h4> {/* Premium JobSeeker counts */}
                    <h2>{dashboardCount && dashboardCount.js_mapped_Premium ? dashboardCount.js_mapped_Premium : 0}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4>Interview / Professional conversation</h4> {/* Job seeker and Employer Conversation counts */}
                    <h2>{dashboardCount && dashboardCount.total_convo ? dashboardCount.total_convo : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4>Jobs Applied</h4> {/*  Count of Jobs applied by Job Seekers */}
                    <h2>{userStats && userStats.Applied ? userStats.Applied : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4 className="">Application view</h4> {/* Number of time Employer viewed Jobseeker's profile [Counts] */}
                    <h2>{dashboardCount && dashboardCount.emp_view_counts ? dashboardCount.emp_view_counts : 0}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4 className="">Shortlisted Job Seekers</h4> {/* Shortlisted JobSeeker counts */}
                    <h2>{userStats && userStats.Shortlisted ? userStats.Shortlisted : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4 className=""> Not-Shortlisted Job Seekers</h4> {/* Not-Shortlisted/Rejected JobSeeker counts */}
                    <h2>{userStats && userStats.Rejected ? userStats.Rejected : 0}</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4>Downloaded Resume</h4> {/* Number of time JobSeekers downloaded their Resume */}
                    <h2>{resumeCount ? resumeCount : 0}</h2>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="widgetbox not-clickable">
                    <h4>Job Opportunities</h4>
                    <h2>{jobOpportunities ? jobOpportunities : 0}</h2>
                  </div>
                </div>
              </div> */}
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
};
