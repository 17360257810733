import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { useIntl } from "react-intl";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DistrictList } from "../../../utils/DistrictList";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import Alert from "@material-ui/lab/Alert";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const Userprofileinput = ({
  section,
  handelClose,
  userData,
  userID,
}) => {
  const dispatch = useDispatch();
  const [langInput, setLangInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const nowmin = moment().subtract(55, "years").format("YYYY-MM-DD");
  const nowmax = moment().subtract(18, "years").format("YYYY-MM-DD");
  const [state, setState] = useState({ open: false });
  const [error, setError] = useState({ open: false, message: "" });

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = userData;
    reset(data);
  };

  const DistrictListt = DistrictList.sort();
  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onBlur",
  });

  const openDialog = (val) => {
    setState({ open: true, val: val });
  };

  /**
   * @description method to update Profile
   */
  const update = async (val) => {
    try {
      if (val.hasOwnProperty("other")) {
        delete val.other;
      }
      let data = { ...val, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess("Profile Updated Successfully", "Success");
      }
    } catch (error) {
      forError("Something Went Wrong", "Failure");
      console.log(error);
    }
    reset();
    handelClose();
    setState({ open: false });
  };

  const intl = useIntl();
  if (section === "mobile") {
    return (
      <>
        {state.open && (
          <ConfirmDialog
            open={state.open}
            val={state.val}
            setState={setState}
            message="It can not be edited once saved"
            confirm={update}
          ></ConfirmDialog>
        )}
        {error.open && (
          <Alert severity="error" className="alert-toast">
            {error.message}
          </Alert>
        )}
        <div className="personal-info-form">
          <div className="row no-gutters">
            <div className="col-12 pt-1 pb-2">
              <Form onSubmit={handleSubmit(openDialog)}>
                <div className="px-3">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start lbl_a row_padding_bottom">
                      <h2 className="prnl_head_aa">
                        <FormattedMessage
                          id="mobileNumber"
                          defaultMessage="Mobile Number"
                        />
                      </h2>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder={intl.formatMessage({
                        id: "enterTenDigit",
                      })}
                      name="contact"
                      className={classNames("form-control", {
                        "is-invalid": errors.contact,
                      })}
                      ref={register({
                        required: "Please enter contact number",
                        pattern: {
                          value: regexValidation.PHONE,
                          message: "Please enter 10 digit valid mobile number",
                        },
                      })}
                    />
                    {errors.contact && (
                      <p className="text-danger txt">
                        {errors.contact.message}
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="btn_bd py-2 px-2 bb mb-5">
                  <button className="common_btn">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="personal-info-form">
        <div className="row">
          <div className="col-12">
            <div>
              <Form onSubmit={handleSubmit(update)}>
                <div className="personal_info_form">
                  {section === "dob" ? (
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label className="lbl_a">
                        <h2 className="prnl_head_aa">
                          <FormattedMessage
                            id="dob"
                            defaultMessage="Date of Birth"
                          />
                        </h2>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        className="input_fld"
                        name="dob"
                        min={nowmin}
                        max={nowmax}
                        ref={register({
                          required: "Please select date of birth",
                        })}
                      />
                      {errors.dob && (
                        <p className="text-danger oom p-0 m-0">
                          {errors.dob.message}
                        </p>
                      )}
                    </Form.Group>
                  ) : section === "language" ? (
                    <div>
                      {" "}
                      <div className="form-group mb-2">
                        <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                          <h2 className="prnl_head_aa">
                            <FormattedMessage
                              id="languageIKnow"
                              defaultMessage="Languages I Know"
                            />
                          </h2>
                        </Form.Label>

                        <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="checkbox"
                              name="language_known"
                              id="Hindi"
                              value="Hindi"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Hindi"
                            >
                              Hindi
                            </label>
                          </div>

                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="checkbox"
                              name="language_known"
                              id="English"
                              value="English"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label  lw py-1 text-capitalize"
                              for="English"
                            >
                              English
                            </label>
                          </div>

                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none fw"
                              type="checkbox"
                              name="language_known"
                              id="Punjabi"
                              value="Punjabi"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Punjabi"
                            >
                              Punjabi
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Haryanvi"
                            value=" Haryanvi"
                            ref={register({
                              required: "Please  select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Haryanvi"
                          >
                            Haryanvi
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Rajasthani"
                            value="Rajasthani"
                            ref={register({
                              required: "Please  select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Rajasthani"
                          >
                            Rajasthani
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Other"
                            value={otherInput}
                            ref={register({
                              required: "Please select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Other"
                            onClick={() => setLangInput(!langInput)}
                          >
                            Other
                          </label>
                        </div>
                      </div>
                      {langInput && (
                        <div className="mt-1">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              onChange={(e) => setOtherInput(e.target.value)}
                              placeholder="Enter your language"
                              value={otherInput}
                              autocomplete="off"
                              name="other"
                              ref={register({
                                required: "this field is required",
                              })}
                            />
                          </Form.Group>
                        </div>
                      )}
                      {errors.language_known && (
                        <p className="text-danger oom p-0 m-0">
                          {errors.language_known.message}
                        </p>
                      )}
                      {errors.otherss && (
                        <p className="text-danger oom p-0 m-0">
                          {errors.otherss.message}
                        </p>
                      )}
                    </div>
                  ) : section === "english" ? (
                    <div className="form-group">
                      <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                        <h2 className="prnl_head_aa">
                          <FormattedMessage
                            id="speakEnglish"
                            defaultMessage="Speak English"
                          />
                        </h2>
                      </Form.Label>
                      <div className="d-flex justify-content-between">
                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="No"
                            value="No"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="No"
                          >
                            No
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="Thoda"
                            value="Thoda"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Thoda"
                          >
                            Thoda
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="Good"
                            value="Good"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Good"
                          >
                            Good
                          </label>
                        </div>
                      </div>
                      {errors.speak_english && (
                        <p className="text-danger oom p-0 m-0">
                          {errors.speak_english.message}
                        </p>
                      )}
                    </div>
                  ) : section === "currentAddress" ? (
                    <div>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="lbl_a row_padding_bottom">
                          <h2 className="prnl_head_aa">
                            <FormattedMessage
                              id="currentDist"
                              defaultMessage="Current District"
                            />
                          </h2>
                        </Form.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          name="address_current"
                          ref={register}
                          options={DistrictList}
                          getOptionLabel={(option) => option}
                          defaultValue={
                            userData.address_current
                              ? userData.address_current
                              : null
                          }
                          getOptionLabel={(option) => option}
                          style={{ width: "100%" }}
                          className="mt-1 p-0 m-0 city_dropdown"
                          renderInput={(params) => (
                            <TextField
                              name="address_current"
                              placeholder={intl.formatMessage({
                                id: "currentDist",
                              })}
                              value={DistrictList}
                              inputRef={register({
                                required: "Please enter current district",
                                maxLength: {
                                  value: 30,
                                  message: "Maximum 30 digit can be entered",
                                },
                              })}
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                        {errors.address_current && (
                          <p className="text-danger oom p-0 m-0">
                            {errors.address_current.message}
                          </p>
                        )}
                      </Form.Group>
                    </div>
                  ) : section === "gender" ?  (
                    <div>
                      <div className="form-group">
                        <label
                          for="email"
                          className="cols-sm-2 control-label row_padding_bottom"
                          ref={register}
                        >

                          <b>
                            {" "}
                            <FormattedMessage
                              id="gender"
                              defaultMessage="Gender"
                            />
                          </b>
                        </label>

                        <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_a text-center aaa">
                            <input
                              className="form-check-input d-none aaa"
                              type="radio"
                              name="gender"
                              id="male"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                              })}
                            />
                            <label className="form-check-label lw py-1" for="male">
                              <FormattedMessage
                                id="male"
                                defaultMessage="Male"
                              />
                            </label>
                          </div>

                          <div className=" detail_btn_b text-center aaa">
                            <input
                              className="form-check-input d-none "
                              type="radio"
                              name="gender"
                              value="0"
                              id="female"
                              ref={register({
                                required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                              })}
                            />
                            <label className="form-check-label lw py-1" for="female">
                              <FormattedMessage
                                id="female"
                                defaultMessage="Female"
                              />
                            </label>
                          </div>

                          <div className=" detail_btn_b text-center aaa">
                            <input
                              className="form-check-input d-none "
                              type="radio"
                              name="gender"
                              value="2"
                              id="Others"
                              ref={register({
                                required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                              })}
                            />
                            <label className="form-check-label lw py-1" for="Others">
                              <FormattedMessage
                                id="others"
                                defaultMessage="Others"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.gender && (
                          <p className="text-danger  oom">{errors.gender.message}</p>
                        )}
                      </div>
                    </div>
                  ) : ("")}
                </div>
                <div className="mt-4">
                  <button className="common_btn">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
