export const AuthActionTypes = {
    GET_OTP: "GET_OTP",
    GET_RECRUITER_OTP: "GET_RECRUITER_OTP",
    GET_OTP_PENDING: "GET_OTP_PENDING",
    GET_OTP_FULFILLED: "GET_OTP_FULFILLED",
    VERIFY_RECRUITER_OTP: "VERIFY_RECRUITER_OTP",

    LOGIN: "LOGIN",
    LOGIN_PENDING: "LOGIN_PENDING",
    LOGIN_FULFILLED: "LOGIN_FULFILLED",

    REFRESH_TOKEN: "REFRESH_TOKEN",
    REFRESH_TOKEN_PENDING: "REFRESH_TOKEN_PENDING",
    REFRESH_TOKEN_FULFILLED: "REFRESH_TOKEN_FULFILLED",

    SIGNUP: "SIGNUP",
    SIGNUP_PENDING: "SIGNUP_PENDING",
    SIGNUP_FULFILLED: "SIGNUP_FULFILLED",

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
    FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",

    AUTHORIZE: "AUTHORIZE",
    LOGOUT: "LOGOUT",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",

    GET_IMAGE_ACCESS: "GET_IMAGE_ACCESS",
    GET_IMAGE_ACCESS_PENDING: "GET_IMAGE_ACCESS_PENDING",
    GET_IMAGE_ACCESS_FULFILLED: "GET_IMAGE_ACCESS_FULFILLED",

    GIT_DETAILS: "GIT_DETAILS",
    GIT_DETAILS_PENDING: "GIT_DETAILS_PENDING",
    GIT_DETAILS_FULFILLED: "GIT_DETAILS_FULFILLED",


    SOCIAL_LOGIN: "SOCIAL_LOGIN",
    SOCIAL_LOGIN_PENDING: "SOCIAL_LOGIN_PENDING",
    SOCIAL_LOGIN_FULFILLED: "SOCIAL_LOGIN_FULFILLED",
    VERIFY_RECRUITER_OTP_FULFILLED: "VERIFY_RECRUITER_OTP_FULFILLED",

    ENQUIRY_FORM: "ENQUIRY_FORM",

    ADMIN_LOGIN: "ADMIN_LOGIN",
    ADMIN_LOGIN_FULFILLED: "ADMIN_LOGIN_FULFILLED",
    ADMIN_SAARTHI_LOGIN: "ADMIN_SAARTHI_LOGIN", 
    ADMIN_SAARTHI_LOGIN_FULFILLED: "ADMIN_SAARTHI_LOGIN_FULFILLED", 
};

export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}
