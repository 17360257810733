import axios from 'config';
import {API_VIRSION } from "config"
// Auth api
export const getOTP = (body) => axios.post(`/login/user`, body.data);
export const recruiterLogin = (body) => axios.post(`/login`, body.data);
export const recruiterSignup = (body) => axios.post(`/register`, body.data);
export const sarthiSignup = (body) => axios.post(`/register/saarthi`, body.data);
export const sarthiLogin = (body) => axios.post(`/login/saarthi`, body.data);
export const varifyOTP = (body) => axios.post(`/login/confirm`, body.data);
export const refreshToken = () =>  axios.post(`${API_VIRSION}/auth/refreshtoken`);
export const getRecruiterOTP = (body) => axios.post(`/login/v2`, body);
export const verifyRecruiterOTP = (body) => axios.post(`/login/v2/confirm`, body);

// User api
export const getAllUser = () => axios.get(`/user/all`);
export const getUserCreatedBy = (id) => axios.get(`/user/`, {params: {_id: id }});
export const updateUser = (body) => axios.put(`/user/v2/profile`, body);
export const scheduleInterview = (body) => axios.post(`/job/interview`, body.data);
export const getUsersCount = () => axios.get(`admin/dashboard`); // api path define for employers counts

// Jobs Api
export const createJob = (body) => axios.post(`/job/v2/post`, body);
export const updateJob = (body) => axios.put(`/job/post`, body.formData,{ headers: {'Content-Type': 'multipart/form-data' }});
export const getJobById = (id) => axios.get(`/job/one`, {params: {_id: id }});
export const getJobListDataById = (id) => axios.get(`/job/recruiter/`, {params: {created_by: id }});
export const getAllJobs = (id) => axios.get(`/job/all`,{params: {user_id: id }});
export const getUserListByJobId = (id) => axios.get(`/job/users`,{params: {_id: id}});
export const getInterviewByUserId = (id) => axios.get(`/job/interviews-by-seeker/${id}`);
export const getInterviewByJobId = (id) => axios.get(`/job/interviews-by-job/${id}`);

// JobCategory Api
export const getJobCategoryById = (id) => axios.get(`/job-category/one`, {params: {_id: id }});
export const getAllJobCategories = () => axios.get(`/job-category`);

// Sarthi Api
export const createUserBySarthi = (body) => axios.post(`/user/saarthi`, body.formData,{ headers: {'Content-Type': 'multipart/form-data' }});
export const sendOtp = (body) => axios.post(`user/verify`);
export const confirmUser = (body) => axios.post(`user/confirm`);

// social login api
export const SocialiLogin = (body) => axios.post(`/login/v2/social`,body);

// enquiry form api
export const EnquiryForm = (body) => axios.post(`/contact/us`, body);
// File upload api
export const preUpload = (body, userId) => axios.post(`/file/${userId}/pre/upload`, body);
export const postUpload = (body, userId) => axios.post(`/file/${userId}/post/upload`, body);
export const downloadFile = (id, userId) => axios.get(`/file/${userId}`, {params: {file_id: id }});
// admin login api
export const getUser = (query) => axios.get(`/admin/`, {params: query});
export const adminLogin = (body) => axios.post(`/login/admin`, body);
export const createEmployer = (body) => axios.post(`/admin/user`, body);
export const getEmployers = (body, page) => axios.post(`/admin/get/users`,body, {params: {page: page}})
export const updateUserDetails = (body,userId) => axios.put(`admin/user/${userId}`, body);
export const getJobs = (body, page) => axios.post(`/admin/get/jobs`,body, {params: {page: page}})
export const getJobDetails = (jobId) => axios.get(`admin/job/${jobId}`);
export const updateJobDetails = (body,jobId) => axios.put(`admin/job/${jobId}`, body);
export const purchaseSubscriptionPlan = (body) => axios.post(`admin/subscription`, body);
export const downloadEmployerData = (body) => axios.post(`admin/download/employer`, body);
export const downloadJobData = (body) => axios.post(`admin/download/jobs`, body); // API to download Jobs data
export const getAppliedJobSeekerList = (query) => axios.get(`/admin/get/job/users`, {params: query});
export const getPermission = (body) => axios.get(`/admin/permission`, body); // API to fetch all permission
export const postRole = (body) => axios.post(`/admin/add-role`, body); // API to create role
export const getRole = (body) => axios.get(`/admin/role`, body); // API to fetch role
export const updateRole = (body) => axios.put(`/admin/role`, body); // API to update role
export const postHLJEmployer = (body) => axios.post(`/admin/add-hlj-emp`, body); // API to create hlj-employer
export const getHLJEmployer = (body) => axios.get(`/admin/hlj-emp`, body); // API to fetch hlj-employer
export const updateHLJEmployer = (body) => axios.put(`/admin/hlj-emp`, body); // API to update hlj-employer
export const checkSubscriptionPlan = (body) => axios.post(`/admin/check-subscription`, body); // API to check subscription plan
// admin saarthi api
export const saarthiLogin = (body) => axios.post(`/login/saarthi`, body);
export const resetSaarthiPassword = (body) => axios.post(`/login/reset/password`, body);
export const updateSaarthiPassword = (body) => axios.post(`/login/v/reset/password`, body);
export const changePassword = (body) => axios.post(`/saarthi/update/password`, body);
export const createSaarthi = (body) => axios.post(`saarthi/create`, body);
export const getSaarthilist = (query) => axios.get(`saarthi/list`, {params: query});
export const saarthiDetails =  (userId) => axios.get(`saarthi`, {params: {user_id: userId}});
export const getJobSeekerList = (query) => axios.get(`saarthi/job-seekers`, {params: query});
export const jobSeekerDetails = (userId) => axios.get(`saarthi/job-seekers/one`, {params: {user_id: userId}});
export const saarthiDashboard = () => axios.get(`saarthi/dashboard`);
export const updateJSProfileBySaarthi = (body) => axios.put(`saarthi/job-seeker/one`, body);
export const getSaarthiJobs = (body, page) => axios.post(`/saarthi/get/jobs`,body, {params: {page: page}});
export const getSaarthiJobDetails = (jobId) => axios.get(`saarthi/job/${jobId}`);
export const saarthiUpdateJob = (body, jobId) => axios.put(`saarthi/job/${jobId}`, body); // API to update Job details by saarthi
export const downloadJobSeekerData = (body) => axios.post(`saarthi/download/job-seeker`, body);
export const getSaarthiJobSeekers = (body, page) => axios.post(`/saarthi/get/users`,body, {params: {page: page}})
// add community video
export const addCommunityVideo = (body) => axios.post(`community/addvideo`, body);
// get community video list
export const getCommunityVideoList = (query) => axios.get(`community/video-list`,{params: query});
