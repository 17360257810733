import axios from 'config';
import * as API from 'api';
import storage from 'utils/storage';
import { AdminActionTypes } from './actionType';
export const createEmployer = (body) => dispatch => dispatch({
    type: AdminActionTypes.CREATE_EMPLOYERS,
    payload: API.createEmployer(body).then(response => {
        return response;
    })
});
export const getEmployers = (body, page) => dispatch => dispatch({
    type: AdminActionTypes.GET_EMPLOYERS,
    payload: API.getEmployers(body, page).then(response => {
        return response;
    })
})
// API to get employer counts
export const getUsersCount = () => dispatch => dispatch({
    type: AdminActionTypes.GET_USERS_COUNT,
    payload: API.getUsersCount().then(response => {
        return response;
    })
})
export const updateUser = (body, userId) => dispatch => dispatch({
    type: AdminActionTypes.GET_EMPLOYERS,
    payload: API.updateUserDetails(body, userId).then(response => {
        return response;
    })
})
export const getJobs = (body, page) => dispatch => dispatch({
    type: AdminActionTypes.GET_JOBS,
    payload: API.getJobs(body, page).then(response => {
        return response;
    })
})
export const updateJob = (body, jobId) => dispatch => dispatch({
    type: AdminActionTypes.UPDATE_JOBS,
    payload: API.updateJobDetails(body, jobId).then(response => {
        return response;
    })
})
export const getJobDetails = (jobId) => dispatch => dispatch({
    type: AdminActionTypes.GET_JOB_DETAILS,
    payload: API.getJobDetails(jobId).then(response => {
        return response;
    })
})
export const purchaseSubscriptionPlan = (body) => dispatch => dispatch({
    type: AdminActionTypes.POST_SUBSCRIPTION_PLAN,
    payload: API.purchaseSubscriptionPlan(body).then(response => {
        return response;
    })
})
export const downloadEmployerData = (body) => dispatch => dispatch({
    type: AdminActionTypes.DOWNLOAD_EMPLOYER_DATA,
    payload: API.downloadEmployerData(body).then(response => {
        return response;
    })
})
// Action to Download Jobs data
export const downloadJobData = (body) => dispatch => dispatch({
    type: AdminActionTypes.DOWNLOAD_JOB_DATA,
    payload: API.downloadJobData(body).then(response => {
        return response;
    })
})
// Applied Job seeker List
export const getAppliedJobseekerList = (query) => dispatch => dispatch({
    type: AdminActionTypes.GET_APPLIED_JOBSEEKER_LIST,
    payload: API.getAppliedJobSeekerList(query).then(response => {
        return response;
    })
});
// get permissions
export const getPermission= (body) => dispatch => dispatch({
    type: AdminActionTypes.GET_ADMIN_PERMISSION,
    payload: API.getPermission(body).then(response => {
        return response;
    })
});
// create roles
export const postRole= (body) => dispatch => dispatch({
    type: AdminActionTypes.POST_ADMIN_ROLE,
    payload: API.postRole(body).then(response => {
        return response;
    })
});
// update roles
export const updateRole= (body) => dispatch => dispatch({
    type: AdminActionTypes.UPDATE_ADMIN_ROLE,
    payload: API.updateRole(body).then(response => {
        return response;
    })
});
//get roles
export const getRole= (body) => dispatch => dispatch({
    type: AdminActionTypes.GET_ADMIN_ROLE,
    payload: API.getRole(body).then(response => {
        return response;
    })
});
// post employer
export const postHLJEmployer= (body) => dispatch => dispatch({
    type: AdminActionTypes.POST_HLJ_EMPLOYER,
    payload: API.postHLJEmployer(body).then(response => {
        return response;
    })
});
// get employer
export const getHLJEmployer= (body) => dispatch => dispatch({
    type: AdminActionTypes.GET_HLJ_EMPLOYERS,
    payload: API.getHLJEmployer(body).then(response => {
        return response;
    })
});
// update employer
export const updateHLJEmployer= (body) => dispatch => dispatch({
    type: AdminActionTypes.UPDATE_HLJ_EMPLOYERS,
    payload: API.updateHLJEmployer(body).then(response => {
        return response;
    })
});


// Admin Saarthi Password Reset
export const resetSaarthiPassword = (body) => dispatch => dispatch({
    type: AdminActionTypes.RESET_SAARTHI_PASSWORD,
    payload: API.resetSaarthiPassword(body).then(response => {
        return response;
    })
});
// Admin Saarthi Password Update
export const updateSaarthiPassword = (body) => dispatch => dispatch({
    type: AdminActionTypes.UPDATE_SAARTHI_PASSWORD,
    payload: API.updateSaarthiPassword(body).then(response => {
        return response;
    })
});
// Check Subscription Plan
export const checkSubscriptionPlan= (body) => dispatch => dispatch({
    type: AdminActionTypes.POST_SUBSCRIPTION_PLAN_CHECK,
    payload: API.checkSubscriptionPlan(body).then(response => {
        return response;
    })
});
