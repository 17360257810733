import { React, useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as SaarthiActions from "redux/actions/SaarthiActions";
import house from "../../../assets/images/admin/house.svg";
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import building from "../../../assets/images/admin/building.svg";
import email from "../../../assets/images/admin/email.svg";
import eye from "../../../assets/images/admin/eye.svg";
import briefcase from "../../../assets/images/admin/briefcase.svg";
import Alert from "@material-ui/lab/Alert";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DistrictList } from "../../../utils/DistrictList";
import { regexValidation } from "utils/regexValidation";

export const AddSaarthi = () => {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [success, setSuccess] = useState({open : false, message: ""});
  const [error, setError] = useState({open : false, message: ""});
  const [supervisorList, setSupervisorList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let query = {sub_role: 1};
      dispatch(SaarthiActions.getSaarthiList(query))
      .then((response) => {
        if(response && response.value.success){
          getSupervisorcodeList(response.value.data.data);
        }
      })
      .catch((error)=>{
        console.log("error",error);
      })
    }
    fetchData();
  }, [dispatch]);
  /**
   * form supervisor saarthi_code list
   * @param {Array} supervisorList supervisor saarthi list
   */
  const getSupervisorcodeList = (supervisorList) => {
    let superVisorcodeList = [];
    for(const supervisor of supervisorList) {
      superVisorcodeList.push({
        _id: supervisor._id,
        saarthi_code: supervisor.saarthi_code
      });
    }
    setSupervisorList(superVisorcodeList);
  }

  /**
   * Method to create a saarthi
   * @param {Object} data Saarthi user data
   */
  const createSaarthi = async (data) => {
    try {
      if(!data.supervised_by || data.supervised_by == '') {
        delete data.supervised_by;
      }
      let res = await dispatch(SaarthiActions.createSaarthi(data));
      if( res && res.value.success) {
        setSuccess({open : true, message: res.value.message});
        setTimeout(() => {
            history.goBack();
        },3000);
      }
    }
    catch (e) {
      console.log("error in creating saarthi", e);
    }
  }
 
  return (
    <>
      {error.open && <Alert severity="error" className="alert-toast">{error.message}</Alert>}
      {success.open && <Alert severity="success" className="alert-toast">{success.message}</Alert>}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Add Sarthi</h6>
            <div className="employer_profile_form add_saarthi_form">
              <button type="button" className="backbtn" onClick={()=>history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form onSubmit={handleSubmit(createSaarthi)}>
                <div className="profile_img">
                  <img src="" alt="saarthi-profile" className="img-fluid company_logo"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = person;
                    }} />
                </div>
                {/* Name Input Box */}
                <div className="form-group">
                  <label htmlFor="name">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Name</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.name,
                    })}
                    name="name"
                    id="name"
                    placeholder="Enter full name"
                    ref={register({
                      required: "Please enter your name",
                      pattern: {
                        value: regexValidation.PERSON_NAME,
                        message: " Numeric values are not allowed",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maximum 30 characters can be entered",
                      },
                    })}/>
                    {errors.name && <span className="error_font text-danger">{errors.name.message}</span>}
                </div>

                {/* Home District Input */}
                <div className="form-group">
                  <label htmlFor="district">
                    <img src={house} alt="house" className="img-fluid" />
                    <span>Home District</span>
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    name="district"
                    ref={register}
                    options={DistrictList.sort()}
                    getOptionLabel={(option) => option}
                    style={{ width: "100%" }}
                    className="mt-2 p-0 m-0 location_list"
                    renderInput={(params) => (
                        <TextField
                            placeholder="Select your home district"
                            name="district"
                            inputRef={register({
                                required:"Please select a district",
                            })}
                            {...params}
                            variant="outlined"
                            require
                        />
                        )}
                    />
                    {errors.district && <span className="error_font text-danger">{errors.district.message}</span>}
                </div>
                {/* Working Tehsil */}
                <div className="form-group">
                  <label htmlFor="tehsil">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Working Tehsil</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.tehsil,
                    })}
                    name="tehsil"
                    id="tehsil"
                    placeholder="Enter Working Tehsil"
                    ref={register({
                      required: "Please enter your tehsil",
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: " Numeric values are not allowed",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maximum 30 characters can be entered",
                      },
                    })}/>
                    {errors.tehsil && <span className="error_font text-danger">{errors.tehsil.message}</span>}
                </div>
                {/* Mobile Number*/}
                <div className="form-group">
                  <label htmlFor="contact">
                    <img src={phone} alt="person" className="img-fluid" />
                    <span>Mobile Number</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.contact,
                    })}
                    name="contact"
                    id="mobile"
                    placeholder="Enter Mobile Number"
                    ref={register({
                      required: "Please enter mobile number",
                      pattern: {
                        value: regexValidation.PHONE,
                        message: "Please enter valid mobile number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Maximum 10 digits are allowed",
                      },
                    })}/>
                    {errors.contact && <span className="error_font text-danger">{errors.contact.message}</span>}
                </div>
                {/* Email Input */}
                <div className="form-group">
                  <label htmlFor="email">
                    <img src={email} alt="industry" className="img-fluid" />
                    <span>Email</span>
                  </label>
                  <input
                    type="email"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.email,
                    })}
                    name="email"
                    id="email"
                    placeholder="Enter Email Id"
                    ref={register({
                      required: "Please enter email id",
                      pattern: {
                        value: regexValidation.EMAIL,
                        message: "Please enter a valid email",
                      },
                    })}/>
                    {errors.email && <span className="error_font text-danger">{errors.email.message}</span>}
                </div>
                {/* Saarthi code */}
                <div className="form-group">
                  <label htmlFor="saarthi_code">
                    <img src={eye} alt="saarthiCode" className="img-fluid"/>
                    <span>Sarthi Code</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.saarthi_code,
                    })}
                    name="saarthi_code"
                    id="saarthicode"
                    placeholder="Enter Sarthi Code"
                    ref={register({
                      required: "Please enter Sarthi code",
                      maxLength: {
                        value: 30,
                        message: "Maximum 30 characters can be entered",
                      },
                    })}/>
                    {errors.saarthi_code && <span className="error_font text-danger">{errors.saarthi_code.message}</span>}
                </div>
                {/* Saarthi Role */}
                <div className="form-group">
                  <label htmlFor="sub_role">
                    <img src={briefcase} alt="role" className="img-fluid"/>
                    <span>Sarthi Role</span>
                  </label>
                  <select
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.sub_role,
                    })}
                    name="sub_role"
                    id="saarthirole"
                    placeholder="Select Sarthi Role"
                    ref={register({
                      required: "Please Select a Sarthi role",
                    })}>
                        <option value="">Select Sarthi Role</option>
                        <option value="1">Set A</option>
                        <option value="2">Set B</option>
                        <option value="3">Set C</option>
                    </select>
                    {errors.sub_role && <span className="error_font text-danger">{errors.sub_role.message}</span>}
                </div>
                {/* Supervisor Saarthi Code */}
                <div className="form-group">
                  <label htmlFor="supervised_by">
                    <img src={briefcase} alt="role" className="img-fluid"/>
                    <span>Supervisor Code</span>
                  </label>
                  <select
                    autoComplete="off"
                    className={classNames("form-control", {
                      "is-invalid": errors.supervised_by,
                    })}
                    name="supervised_by"
                    id="supervisorcode"
                    placeholder="Select Supervisor Code"
                    ref={register()}>
                        <option value=''>Select Supervisor Code</option>
                        {supervisorList.map((val) => {
                            return (
                              <>
                                <option value={val._id}>{val.saarthi_code}</option>
                              </>
                            );
                          })}
                    </select>
                    {errors.supervised_by && <span className="error_font text-danger">{errors.supervised_by.message}</span>}
                </div>
                <div className="submit_btn_container">
                <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
