import moment from "moment";
import { FaTimes } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip
import VideoBlueIcon from '../../../assets/images/admin/video-blue-icon.png';
import VideoGrayIcon from '../../../assets/images/admin/video-gray-icon.png';
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { CSVLink, CSVDownload } from "react-csv";
import { forError } from "../../services/commonservice";
import { Experience, UserGender } from 'admin/common/app.constant';

const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(id, jobSeeker, regdate, homeDistrict, contact, premiumMember, videoResume, saarthi, action) {
  return { id, jobSeeker, regdate, homeDistrict, contact, premiumMember, videoResume, saarthi, action};
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'jobSeeker', numeric: false, disablePadding: false, label: 'Job Seeker' },
  { id: 'regdate', numeric: false, disablePadding: false, label: 'Registration Date', align: 'center' },
  { id: 'homeDistrict', numeric: false, disablePadding: false, label: 'Home District', align: 'center' },
  { id: 'contact', numeric: false, disablePadding: false, label: 'Mobile Number', align: 'center' },
  { id: 'premiumMember', numeric: false, disablePadding: false, label: 'Membership', align: 'center' },
  { id: 'videoResume', numeric: false, disablePadding: false, label: 'Video Resume', align: 'center' },
  { id: 'saarthi', numeric: false, disablePadding: false, label: 'Sarthi Name', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

export const JobseekerList = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 );
  const {js_status} = useParams();
  const context = useContext(Context);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });
  const classes = useStyles();
  const [table, setTable] =useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [tableCount, setTableCount] =useState(0);
  const [jobSeekerCount, setJobSeekerCount] = useState(0);
  const [setCount, setTotalCount] = useState(0); // storing total count 
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const sortingIconClicked= useRef(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const minDate = moment(startDate).format("YYYY-MM-DD");
  const [endMaxDate, setEndMaxDate] = useState("");
  const csvLink = useRef();
  const [csvData, setCSVData] = useState([]);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [jobStatus, setStatus] = useState(
    js_status === "alljobseeker" ? "All" :
    js_status === "premium" ? "0" : 
    js_status === "withname" ? "1" : 
    js_status === "withoutname" ? "2" : 
    js_status === "videoResume" ? "3" : "All"
  ); // Checking and Setting status from params Value
  const [prevJobStatus , setPrevJobStatus] =useState("")

  useEffect(() => {
    setTable([]);
    async function fetchData(pageNumber) {
      let query = {};
      if (page >= 0) {
        query.page = pageNumber ? pageNumber : page;
      }
      if (searched && searched.length) {
        query.search = searched;
      }
      if (sort) {
        query.sort = sort;
      }
      if (jobStatus === "All") {
        setPrevJobStatus((prevValue) => {
          if (prevValue === "All") {
            setPage(0);
          }
        });
      }
      else {
        query.filter_value = jobStatus; // sending dropdown options value (0,1,2,3)
        setPrevJobStatus((prevValue) => {
          if (prevValue === "0" || prevValue === "1" || prevValue === "2" || prevValue === "3") {
            setPage(0);
          }
        });
      }
      dispatch(saarthiActions.getJobseekerList(query))
      .then((response) => {
        if(response && response.value.success){
          setTableData(response.value.data.data);
          setJobSeekerCount(response.value.data.count);
          setTableCount(response.value.data.data.length);
          setTotalCount(response.value.data.count); // get and set Total count
        }
      })
      .catch((error)=>{
        console.log("error",error);
      })
    }
    fetchData(pageNumber);
  }, [dispatch, page, sort, searched, jobStatus]);

  // Handling sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Handling Job status change
  const handleJobStatusChange = (e) => {
    setStatus(e.target.value);
    setPrevJobStatus(e.target.value);
  };
  /**
   * @description set Table data method to update table values
   */
  const setTableData = async (jobSeekerList) => {
    let jobseekerTable = [];
    if (jobSeekerList && jobSeekerList.length) {
      let tempArray = [...jobSeekerList]
      for (let val of tempArray) {
        let data = createData(
          val._id ? val._id : '',
          val.name ? val.name.charAt(0).toUpperCase() + val.name.substring(1) : '', // split job seeker name
          val.created_at ? new Date(val.created_at).toLocaleDateString() : '',
          val.address_home ? val.address_home : '',
          val.contact && val.contact ? val.contact : '', // get contact number
          val.is_premium ? val.is_premium : '', // get premium membership value
          val.video_resume && val.video_resume.length ?  'Yes': 'No', // get video resume icon
          val.saarthi && val.saarthi.name ? val.saarthi.name : '',
        );
        jobseekerTable.push(data);
      }
    }
    setTable(jobseekerTable);
    setRows(jobseekerTable); // get table data
    setTable((state) => {
      return state;
    })
    return jobseekerTable;
  }

  // update page count
  const handleChange =(event, value) => {
    setPage(value - 1);
  };

  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/jobseeker-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/jobseeker-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  /**
   * @description method to open/close the menu options
   * @param {param} e event 
   */
  const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('table_dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }

  /* function to close the dropdown when clicked outside */
  window.onclick = function (event) {
    if (!event.target.matches('.table_dropbtn')) {
      var dropdowns = document.getElementsByClassName('table_dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  const jobSeekerProfile = (e, id) => {
    history.push({pathname : '/jobseeker-profile', search: "?user_id=" + id});
  }

  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };

  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };

  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/jobseeker-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/jobseeker-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };

  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  // When Video Resume are uploaded then shown Video blue icon, otherwise shown gray icon
  const _handleVideoIcon = (videoResume) => {
    if (videoResume === 'No') {
      return (
        <img src={VideoGrayIcon} width="22" height="22" />
      )
    } else {
      return (
        <img src={VideoBlueIcon} width="22" height="22" />
      )
    }
  }

  /**
   * @description method to calculate mix and max date allowed to download table data
   * @param {event} e fetch click event 
   */
  const maxDate = (e) => {
    let endDate = moment(e.target.value).add(30, "Days");
    if (endDate > moment(new Date())) {
      setEndMaxDate(moment(new Date()).format("YYYY-MM-DD"));
    } else {
      setEndMaxDate(endDate.format("YYYY-MM-DD"));
    }
  }

  /**
   * @description method to open data download modal
   */
  const dataModalOpen = () => {
    setOpenDateModal(true);
  };
  
  /**
   * @description method to close data download modal
   */
  const dataModalClose = () => {
    setOpenDateModal(false);
  };

  /**
   * @description method to fetch and downlaod 30 days jobseeker data
   */
  const downloadJobSeekerData = async (val) => {
    try {
      let startDate = moment(val.start_date).startOf('days').toString(); // set to 12:00
      let endDate = moment(val.end_date).endOf('days').toString(); // set to 11:59
      let data = {
        time_filter: {
          from: Date.parse(startDate), 
          to: Date.parse(endDate)
        }
      };
      let response = await dispatch(saarthiActions.downloadJobSeekerData(data));
      if (response.value.success) {
        let data = [];
        for (let val of response.value.data) {
          let JobIntrested = '';
          let skills = '';
          if (val.job_intrested && val.job_intrested.length) {
            JobIntrested = val.job_intrested.map((val) => val.job_category).join();
            skills = val.job_intrested.map((val) => val.skills).flat();
          }
          const technical_cert = val.technical_cert && val.technical_cert.length && val.technical_cert.map((val) => val.cert).join();
          const qualification = parseInt(val.qualification) === 0 ? "below 10"
                              : parseInt(val.qualification) === 1 ? "10 th pass"
                              : parseInt(val.qualification) === 2 ? "12 th pass"
                              : parseInt(val.qualification) === 3 ? "B.A"
                              : parseInt(val.qualification) === 4 ? "B.Sc"
                              : parseInt(val.qualification) === 5 ? "B.Com"
                              : parseInt(val.qualification) === 6 ? "Other"
                              : val.qualification;
          data.push({
            'Name' : val.name,
            'Email Address' : val.email,
            'Mobile Number' : val.contact,
            'Current District' : val.address_current,
            'Home District' : val.address_home,
            'Date of Birth' : val.dob,
            'Gender': val.gender ? UserGender[val.gender] : '',
            'Job Interested in': JobIntrested,
            'Skills': skills,
            'Qualification': qualification,
            'Experience': val.experience ? Experience[val.experience] : '',
            'Technical Certification': technical_cert,
            'Created At': (new Date(val.created_at)).toLocaleDateString()
          })
        }
        setCSVData(data);
        csvLink.current.link.click();
      }
      reset();
      dataModalClose();
    } catch (error) {
      forError("Something Went Wrong", "Failure");
      console.log(error);
      
    }
  };
  return (
    <>
      <div className="reg_main employer_section">
        <div className="container-fluid">
          <div className="data_download">
            <button type="button" onClick={dataModalOpen}>Download CSV</button>
          </div>
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Job Seekers Count : {jobSeekerCount}</p>
                {/* Premium Membership Dropdown start from here */}
                <div className="timefilterdropdown">
                  <select 
                    name="" 
                    id="" 
                    className="form-control"
                    defaultValue={jobStatus}
                    onClick={handleJobStatusChange}
                  >
                    <option value="All">All Job Seeker</option>
                    <option value="0">Premium</option>
                    <option value="1">Free(With Name)</option>
                    <option value="2">Free(Without Name)</option>
                    <option value="3">Only Video Resume</option>
                  </select>
                </div>
              </div>
              {/* Premium Membership Dropdown start from here */}
               {/* searching-section start from here */}
                <div className="table_menu_ryt">
                 {/* sort-icon-section */}
                {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              <ReactTooltip />
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                   {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.jobSeeker}
                            </TableCell>
                            <TableCell align="center">{row.regdate}</TableCell>
                            <TableCell align="center">{row.homeDistrict}</TableCell>
                            <TableCell align="center">{row.contact}</TableCell>
                            <TableCell align="center">{row.premiumMember === true ? 'Premium' : 'Free'}</TableCell>
                            <TableCell align="center">{_handleVideoIcon(row.videoResume)}</TableCell>
                            <TableCell align="center">{row.saarthi}</TableCell>
                            <TableCell align="right" style={{position: "relative"}}>
                                <div className="table_dropdownbox">
                                    <button type="button" className="table_moreoptns table_dropbtn" onClick={toggleMenu}>
                                    <FiMoreVertical />
                                    </button>
                                    <div className="table_dropdownmenu table_option_menu table_dropdown-content">
                                        <ul>
                                        <li>
                                            <a href="javascript:void(0)" onClick={(e)=>jobSeekerProfile(e,row.id)}>
                                              Open Profile
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="javascript:void(0)" onClick={() => {}}>
                                              Open Dashboard
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={() => {}}>
                                              Apply Jobs
                                            </a>
                                        </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination"> 
                  <button type="button" onClick={() => prev(page - 1, sort)} disabled={page===0? true : false}>Prev</button>
                  <span>{page + 1}</span>
                  <button type="button" onClick={() => next(page + 1, sort)} disabled={tableCount === 30 ? false : true}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* open table data download modal */}
      <Dialog
        open={openDateModal}
        onClose={dataModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Enter Start Date & End Date"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={dataModalClose}><FaTimes /></button>
        <DialogContent className="reason_modal_content data_modal" style={{ padding: "0px" }}>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(downloadJobSeekerData)}>
              <div className="form-group">
                <label htmlFor="start_date">
                  <span className="datelabel">From</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="start_date"
                  id="start_date"
                  placeholder="Enter Start Date"
                  onChange={(e) => {
                    setStartDate((e.target.value));
                    maxDate(e);
                  }}
                  className={classNames("form-control", {
                    "is-invalid": errors.start_date,
                  })}
                  ref={register({
                    required: "Please enter Start Date",
                  })}
                  max={new Date()}
                />
                {errors.start_date && (
                  <span className="error_font text-danger">
                    {errors.start_date.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="end_date">
                  <span className="datelabel">To</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="end_date"
                  id="end_date"
                  placeholder="Enter End Date"
                  className={classNames("form-control", {
                    "is-invalid": errors.end_date,
                  })}
                  ref={register({
                    required: "Please enter End Date",
                  })}
                  onChange={(e) => setEndDate((e.target.value))}
                  min={startDate}
                  max={endMaxDate}
                />
                {errors.end_date && (
                  <span className="error_font text-danger">
                    {errors.end_date.message}
                  </span>
                )}
              </div>
              <button type="submit" className="reasonbtn">SUBMIT</button>
              <CSVLink 
                data={csvData}
                filename='JobSeekerData.csv'
                className='hidden'
                ref={csvLink} 
                target="_blank" 
              />
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
