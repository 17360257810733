export const SaarthiActionTypes = {
    CREATE_SAARTHI : "CREATE_SAARTHI",
    CREATE_SAARTHI_PENDING : "CREATE_SAARTHI_PENDING",
    CREATE_SAARTHI_FULFILLED : "CREATE_SAARTHI_FULFILLED",
    GET_SAARTHI_LIST: "GET_SAARTHI_LIST",
    GET_SAARTHI_LIST_PENDING: "GET_SAARTHI_LIST_PENDING",
    GET_SAARTHI_LIST_FULFILLED: "GET_SAARTHI_LIST_FULFILLED",
    GET_SAARTHI_DETAILS : "GET_SAARTHI_DETAILS",
    GET_SAARTHI_DETAILS_PENDING : "GET_SAARTHI_DETAILS_PENDING",
    GET_SAARTHI_DETAILS_FULFILLED : "GET_SAARTHI_DETAILS_FULFILLED",
    GET_JOBSEEKER_LIST: "GET_JOBSEEKER_LIST",
    GET_JOBSEEKER_LIST_PENDING: "GET_JOBSEEKER_LIST_PENDING",
    GET_JOBSEEKER_LIST_FULFILLED: "GET_JOBSEEKER_LIST_FULFILLED",
    GET_JOBSEEKER_DETAILS : "GET_JOBSEEKER_DETAILS",
    GET_JOBSEEKER_DETAILS_PENDING : "GET_JOBSEEKER_DETAILS_PENDING",
    GET_JOBSEEKER_DETAILS_FULFILLED : "GET_JOBSEEKER_DETAILS_FULFILLED",
    GET_DASHBOARD_COUNT : "GET_DASHBOARD_COUNT",
    GET_DASHBOARD_COUNT_PENDING : "GET_DASHBOARD_COUNT_PENDING",
    GET_DASHBOARD_COUNT_FULFILLED : "GET_DASHBOARD_COUNT_FULFILLED",
    CHANGE_PASSWORD : "CHANGE_PASSWORD",
    UPDATE_JS_PROFILE : "UPDATE_JS_PROFILE",
    GET_JOBS: "GET_JOBS",
    GET_JOBS_PENDING:"GET_JOBS_PENDING",
    GET_JOBS_FULFILLED: "GET_JOBS_FULFILLED",
    UPDATE_JOBS: "UPDATE_JOBS",
    GET_JOB_DETAILS: "GET_JOB_DETAILS",
    GET_JOB_DETAILS_PENDING: "GET_JOB_DETAILS_PENDING",
    GET_JOB_DETAILS_FULFILLED: "GET_JOB_DETAILS_FULFILLED",
    DOWNLOAD_JOBSEEKER_DATA: "GET_DOWNLOAD_EMPLOYER_DATA",
    DOWNLOAD_JOBSEEKER_DATA_PENDING: "GET_DOWNLOAD_EMPLOYER_DATA",
    DOWNLOAD_JOBSEEKER_DATA_FULFILLED: "GET_DOWNLOAD_EMPLOYER_DATA",
    ADD_COMMUNITY_VIDEO: "ADD_COMMUNITY_VIDEO",
    ADD_COMMUNITY_VIDEO_PENDING: "ADD_COMMUNITY_VIDEO_PENDING",
    ADD_COMMUNITY_VIDEO_FULFILLED: "ADD_COMMUNITY_VIDEO_FULFILLED",
    GET_COMMUNITY_VIDEOS_LIST: "GET_COMMUNITY_VIDEOS_LIST",
    GET_COMMUNITY_VIDEOS_LIST_PENDING: "GET_COMMUNITY_VIDEOS_LIST_PENDING",
    GET_COMMUNITY_VIDEOS_LIST_FULFILLED: "GET_COMMUNITY_VIDEOS_LIST_FULFILLED",
    GET_SAARTHI_JOBSEEKER: "GET_SAARTHI_JOBSEEKER",
    GET_SAARTHI_JOBSEEKER_PENDING: "GET_SAARTHI_JOBSEEKER_PENDING",
    GET_SAARTHI_JOBSEEKER_FULFILLED: "GET_SAARTHI_JOBSEEKER_FULFILLED",
    SAARTHI_UPDATE_JOB: "SAARTHI_UPDATE_JOB",
    SAARTHI_UPDATE_JOB_PENDING: "SAARTHI_UPDATE_JOB_PENDING",
    SAARTHI_UPDATE_JOB_FULFILLED: "SAARTHI_UPDATE_JOB_FULFILLED",
};
