import React, { useState, useContext } from "react";
import admin_logo from "../../assets/images/logos/admin_logo.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "Languages/Wrapper/Wrapper";
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import Alert from "@material-ui/lab/Alert";
import * as adminActions from "redux/actions/AdminActions";
import { forSuccess } from "../services/commonservice";
import { forError } from "../services/commonservice";
import { forWarning } from "../services/commonservice";

export const ResetSaarthiPassword = () => {
  const context = useContext(Context);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState({ open: false, message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  /**
   * @description method for admin login
   * @param {param} data values of email and password 
   */
  const resetSaarthiPassword = async (data) => {
    try {
      const resetDetails = {
        email: data.email,
      };
      let res = await dispatch(adminActions.resetSaarthiPassword(resetDetails))
      if (res.value.success) {
        setIsSubmitting(true);
        forSuccess('OTP sent on your registered email','Success');
        setTimeout(() => {
          history.push({pathname: "/update-password", state: {email:data.email}});
        }, 3000);
      }
    } catch (err) {
      setError({ open: true, message: err.data.message });
      setIsSubmitting(false);
      setTimeout(() => { setError({ open: false, message: "" }) }, 3000);
    }
  }

  return (
    <>
      {error.open && <Alert severity="error">{error.message}</Alert>}
      <div className="admin_login">
        <div className="container">
          <div className="admin_inner">
            <div className="admin_logo">
              <img src={admin_logo} alt="HumlogJobs" className="img-fluid d-block" />
            </div>
            <div className="flip-card">
              <div className="dashboard_login_box">
                <div className="admin_login_box flip-card-front">
                  <div className="admin_login_form">
                    <h1>Reset Password</h1>
                    <form onSubmit={handleSubmit(resetSaarthiPassword)}>
                      <div class="form-group">
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="email" 
                          name="email" 
                          placeholder="Enter your Email" 
                          ref={register({
                            required: "This field is required.",
                            validate: {
                              validation: (value) =>
                                regexValidation.EMAIL.test(value) ||
                                "Please enter valid email address",
                            },
                          })} 
                        />
                        {errors.email && (
                          <p className="help is-danger">{errors.email.message}</p>
                        )}
                      </div>
                      <div className="">
                        <button className="submitbtn" type="submit">Next</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
