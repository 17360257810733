import { useDispatch } from "react-redux";
import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "Languages/Wrapper/Wrapper";
import storage from "utils/storage";
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import Alert from "@material-ui/lab/Alert";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../services/commonservice";
import { forError } from "../services/commonservice";
import { forWarning } from "../services/commonservice";

export const ChangePassword = () => {
  const context = useContext(Context);
  const { 
    register, 
    handleSubmit, 
    formState: { errors },
    watch,
    reset
  } = useForm({
    mode: "onBlur",
  });
  const [error, setError] = useState({ open: false, message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const new_password = useRef({});
  new_password.current = watch("new_password", "");
  /**
   * @description method for change Password
   * @param {param} data values of old password, new password and confirm Password 
   */
  const changePassword = async (data) => {
    try {
      const upadteDetails = {
        password : data.old_password,
        new_password: data.new_password,
      };
      let res = await dispatch(saarthiActions.changePassword(upadteDetails))
      if (res.value.success) {
        setIsSubmitting(true);
        forSuccess('Your Password is successfully changed. Please login using your new password', 'Success');
        setTimeout(() => {
          storage.clear();
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      setError({ open: true, message: err.data.message });
      setIsSubmitting(false);
      setTimeout(() => { setError({ open: false, message: "" }) }, 3000);
    }
  }

  return (
    <>
      {error.open && <Alert severity="error">{error.message}</Alert>}
      <div className="admin_login change_password_box">
        <div className="container">
          <div className="admin_inner">
            <div className="flip-card">
              <div className="dashboard_login_box">
                <div className="admin_login_box">
                  <div className="admin_login_form update_password_form">
                    <h1>Change Password</h1>
                    <form onSubmit={handleSubmit(changePassword)}>
                      <div class="form-group">
                        <label>Old Password <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="password" 
                          name="old_password" 
                          placeholder="Enter Old Password" 
                          ref={register({
                            required: "You must enter your Old Password",
                          })} />
                        {errors.old_password && (
                          <p className="help is-danger">{errors.old_password.message}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <label>New Password <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="password" 
                          name="new_password" 
                          placeholder="Enter New Password"
                          ref={register({
                            required: "This field is required.",
                            validate: {
                              validation: (value) =>
                                regexValidation.PASSWORD.test(value) ||
                                "Please enter at least 8 characters",
                            },
                          })} 
                        />
                        {errors.new_password && (
                          <p className="help is-danger">{errors.new_password.message}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <label>Confirm Password <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="password" 
                          name="c_password" 
                          placeholder="Confirm Password"
                          ref={register({
                            required: "This field is required.",
                            validate: {
                              validation: (value) =>
                                value === new_password.current || "The passwords do not match"
                            },
                          })}
                        />
                        {errors.c_password && (
                          <p className="help is-danger">{errors.c_password.message}</p>
                        )}
                      </div>
                      <div className="">
                        <button className="submitbtn" type="submit">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
