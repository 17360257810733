import { React, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import person from "../../../assets/images/admin/person.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import { BsTools, BsPencil, BsPerson } from "react-icons/bs";
import { BiBriefcase, BiBell } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import { MdDescription, MdOutlineHome } from "react-icons/md";
import { FaRegFileVideo } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { RiGroup2Line } from "react-icons/ri"; // Icon for job opening
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { uploadFile } from "../../../utils/fileService";
import Alert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";
import { Experience, JobType, Gender } from 'admin/common/app.constant';

export const ViewAudioJD = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  let jobId = query.get("id");
  const {jobDetails} = useSelector((state) => state.adminReducer);
  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState({open : false, message: ""});
  const [error, setError] = useState({open : false, message: ""});
  const [jobEditButton, setJobEditButton] = useState(false);
  const [disableReadOnly, setReadOnlyProperty] = useState(true); // storing readonly value
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(adminActions.getJobDetails(jobId))
      .then((response)=> {
        if(response && response.value.success){
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
    }
  }, [loaded, dispatch, jobId]);

  // upload audio description method
  const uploadaudioJd = async(e) => {
    if(e.target.files[0]) {
      const url = await uploadFile(e.target.files[0], "audio");
      const body = {
        audio_description : url
      }
      dispatch(adminActions.updateJob(body,jobId))
      .then( (res) => {
        if(res && res.value.success){
          dispatch(adminActions.getJobDetails(jobId));
          setSuccess({open : true, message : "Audio JD uploaded successfully"});
        }
      })
      .catch( (err) => {
        setError({open : true, message : "Error in uploading audio JD"});
      })
      setTimeout( ()=>{
        setSuccess({open : false, message : ""});
        setError({open : false, message : ""});
      }, 5000);
    }
  }

  // uplaod animated description method
  const uploadVedioJd = async(e) => {
    if(e.target.files[0]) {
      const url = await uploadFile(e.target.files[0], "video");
      const body = {
        animated_description : url
    }
    dispatch(adminActions.updateJob(body,jobId))
      .then( (res) => {
        if(res && res.value.success){
          dispatch(adminActions.getJobDetails(jobId));
          setSuccess({open : true, message : "video JD uploaded successfully"});
        }
      })
      .catch( (err) => {
        setError({open : true, message : "Error in uploading video JD"});
      });
      setTimeout( ()=>{
        setSuccess({open : false, message : ""});
        setError({open : false, message : ""});
      }, 5000);
    }
  }

  /**
   * @description Method to edit job description
   */
   const editDescription = (val) => {
    try {
      let data = {...val};
      console.log('data',val);
      dispatch(adminActions.updateJob(data,jobId))
        .then((res) => {
          if (res && res.value.success) {
            setSuccess({ open: true, message: "Job Description Updated Successfully" });
            dispatch(adminActions.getJobDetails(jobId))
          }
        }) 
        .catch((error) => console.log(error));
      } catch (error) {
      setError({open:true, message: 'Job Description Updation Failed'})
      console.log("error in updating Job Description", error);
    }
    setTimeout( ()=>{
      setSuccess({open : false, message : ""});
      setError({open : false, message : ""});
    }, 5000);
  };
  // Handling show hide job button
  const handleEditJobButton = () => {
    setJobEditButton(true);
    setReadOnlyProperty(false);
  };
  // Handling cancel button
  const _handleCancelButton = () => {
    reset();
    setJobEditButton(false);
    setReadOnlyProperty(true);
  }
  // API for update job data
  const _handleUpdateJob = async (val) => {
    try {
      let data = { ...val };
      dispatch(adminActions.updateJob(data, jobId))
        .then((res) => {
          if (res && res.value.success) {
            setSuccess({ open: true, message: "Jobs Data Updated Successfully" });
            setTimeout(() => {
              setReadOnlyProperty(true);
              setJobEditButton(false);
              // API for get job data fetch by Id
              dispatch(adminActions.getJobDetails(jobId));
            }, 1000);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      setError({ open: true, message: 'Job Description Updation Failed' })
      console.log("error in updating Job Description", error);
    }
    setTimeout(() => {
      setSuccess({ open: false, message: "" });
      setError({ open: false, message: "" });
    }, 5000);
  };
  return (
    <>
      {error.open && <Alert severity="error" className="alert-toast">{error.message}</Alert>}
      {success.open && <Alert severity="success" className="alert-toast">{success.message}</Alert>}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Job Post Details</h6>
            <div className="employer_profile_form audio_profile_form">
              <button type="button" className="backbtn" onClick={()=>history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form onSubmit={handleSubmit(_handleUpdateJob)}>
                {/* edit-jobs button section start from here */}
                {
                  jobEditButton === false && commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) ?
                    <div className="employer_profile_btns employer-edit-button">
                      <button
                        type="button"
                        className="approvebtn"
                        onClick={handleEditJobButton}
                      >
                        Edit Job
                      </button>
                    </div> : ''
                }
                {/* edit-jobs button section end */}
                <div className="profile_img">
                  <img src={jobDetails && jobDetails.company_logo} alt="company-logo" className="img-fluid company_logo"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = person;
                    }} />
                </div>
                <div className="profile_name job_post_company_name">
                  <h4>{jobDetails && jobDetails.company_name && jobDetails.company_name.length ?  jobDetails.company_name : ""}</h4>
                </div>

                <div className="job_profile_details">
                  <h2>{jobDetails && jobDetails.job_title && jobDetails.job_title.length ? jobDetails.job_title : ""}</h2>
                  <p>{jobDetails && jobDetails.district && jobDetails.district.length ? jobDetails.district : ""} </p>
                </div>
                {/* Job-title section start from here */}
                <div class="form-group">
                  <label htmlFor="job_title">
                    <BiBriefcase />
                    <span>Job Title</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="job_title"
                    name="job_title"
                    autoComplete="off"
                    defaultValue={jobDetails&& jobDetails.job_title ? jobDetails.job_title: ''}
                    readOnly={disableReadOnly}
                    ref={register({
                      required: 'Please enter job title',
                      maxLength: {
                        value: 40,
                        message: 'Maximum 40 digit can be entered'
                      },
                      pattern: {
                        value: /^[A-Za-z0-9\d\s._,-:;'"()*]*$/,
                        message: 'Special characters not allowed',
                      },
                    })}
                  />
                  {errors.job_title && (
                    <span className="text-danger txt">
                      {errors.job_title.message}
                    </span>
                  )}
                </div>
                {/* Job-title section end */}
                {/* Job-location section start from here */}
                <div class="form-group">
                  <label htmlFor="job_location">
                    <IoLocationOutline />
                    <span>Job Location</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="job_location"
                    name="job_location"
                    autoComplete="off"
                    defaultValue={jobDetails && jobDetails.job_location ? jobDetails.job_location : ''}
                    readOnly={disableReadOnly}
                    className={classNames("form-control textarea-height", {
                      "is-invalid": errors.job_location,
                    })}
                    ref={register({
                      maxLength: {
                        value: 100,
                        message: 'Maximum 100 Character limit'
                      },
                    })}
                  />
                  {errors.job_location && (
                    <span className="text-danger txt">
                      {errors.job_location.message}
                    </span>
                  )}
                </div>
                {/* Job-location section end */}
                {/* Job-description section start from here */}
                <div className="form-group">
                  <label htmlFor="description" className="description-textarea">
                    <MdDescription />
                    <span>Job Description</span>
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows="3"
                    defaultValue={jobDetails && jobDetails.description ? jobDetails.description: ''}
                    placeholder="Please enter job description"
                    readOnly={disableReadOnly}
                    className={classNames("form-control textarea", {
                      "is-invalid": errors.description,
                    })}
                    ref={register({
                      required: "Please enter job description",
                      maxLength: {
                        value: 500,
                        message: "Description should be less than 500 characters",
                      },
                    })}
                  ></textarea>
                  {errors.description && (
                    <span className="text-danger txt">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              {/* Job-description section end */}
              {/* experience section start from here */}
              {jobEditButton === false ? 
                <div className="form-group">
                  <label htmlFor="experience">
                    <BiBriefcase />
                    <span>Experience Required</span>
                  </label>
                  <select
                    className="select"
                    ref={register}
                    className="form-control"
                    name="experience"
                    value={Experience[jobDetails.experience]}
                    defaultValue={Experience[jobDetails.experience]}
                    id="experience"
                    autoComplete="off"
                    readOnly={disableReadOnly}
                    disabled={disableReadOnly}
                  >
                    {Object.keys(Experience).map((val) => {
                      return (
                        <>
                          <option>{Experience[val]}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                : 
                <div className="form-group">
                  <label htmlFor="">
                    <BiBriefcase />
                    <span>Experience Required</span>
                  </label>
                  <select
                className="select"
                ref={register}
                className="form-control"
                defaultValue={Experience[jobDetails.experience]}
                id="experience"
                name="experience"
                autoComplete="off"
                readOnly={disableReadOnly}
                disabled={disableReadOnly}
              >
                {Object.keys(Experience).map((val) => {
                  return (
                    <>
                      <option value={Object.keys(Experience)[val]}>{Experience[val]}</option>
                    </>
                  );
                })}
              </select>
              </div>}
              {/* experience section end */}
              {/* Job-type section start from here */}
              {jobEditButton == false ?
              <div className="form-group">
                  <label htmlFor="job_type">
                    <BiBriefcase />
                    <span>Job Type</span>
                  </label>
                  <select
                    className="select"
                    ref={register}
                    className="form-control"
                    name="job_type"
                    value={JobType[jobDetails.job_type]}
                    defaultValue={JobType[jobDetails.job_type]}
                    id="job_type"
                    autoComplete="off"
                    readOnly={disableReadOnly}
                    disabled={disableReadOnly}
                  >
                    {Object.keys(JobType).map((val) => {
                      return (
                        <>
                          <option>{JobType[val]}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                : 
                <div className="form-group">
                  <label htmlFor="">
                    <BiBriefcase />
                    <span>Job Type</span>
                  </label>
                  <select
                className="select"
                ref={register}
                className="form-control"
                defaultValue={JobType[jobDetails.experience]}
                id="job_type"
                name="job_type"
                autoComplete="off"
                readOnly={disableReadOnly}
                disabled={disableReadOnly}
              >
                {Object.keys(JobType).map((val) => {
                  return (
                    <>
                      <option value={Object.keys(JobType)[val]}>{JobType[val]}</option>
                    </>
                  );
                })}
              </select>
              </div>}
                {/* Job-type section end */}
                {/* Gender-section start from here */}
                {jobEditButton == false ?
              <div className="form-group">
                  <label htmlFor="gender">
                    <BsPerson />
                    <span>Gender</span>
                  </label>
                  <select
                    className="select"
                    ref={register}
                    className="form-control"
                    name="gender"
                    value={Gender[jobDetails.gender]}
                    defaultValue={Gender[jobDetails.gender]}
                    id="gender"
                    autoComplete="off"
                    readOnly={disableReadOnly}
                    disabled={disableReadOnly}
                  >
                    {Object.keys(Gender).map((val) => {
                      return (
                        <>
                          <option>{Gender[val]}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                : 
                <div className="form-group">
                  <label htmlFor="gender">
                    <BsPerson />
                    <span>Gender</span>
                  </label>
                  <select
                className="select"
                ref={register}
                className="form-control"
                defaultValue={Gender[jobDetails.gender]}
                id="gender"
                name="gender"
                autoComplete="off"
                readOnly={disableReadOnly}
                disabled={disableReadOnly}
              >
                {Object.keys(Gender).map((val) => {
                  return (
                    <>
                      <option value={Object.keys(Gender)[val]}>{Gender[val]}</option>
                    </>
                  );
                })}
              </select>
              </div>}
                {/* Gender-section end */}
                <div className="form-group">
                  <label htmlFor="">
                    <GiReceiveMoney />
                    <span>Salary</span>
                  </label>
                  <p className="input-disabled"><span>{jobDetails && jobDetails.salary_from ? jobDetails.salary_from : ""}</span> - <span>{jobDetails && jobDetails.salary_to? jobDetails.salary_to : ""}</span> / month</p>
                </div>
                {/* District-section start from here */} 
                <div className="form-group">
                  <label htmlFor="">
                    <MdOutlineHome />
                    <span>District</span>
                  </label>
                  <p className="input-disabled"><span>{jobDetails && jobDetails.district ? jobDetails.district : ""}</span></p>
                </div>
                {/* District-section end */}
                {/* job-opening-section start from here */}
                <div className="form-group">
                  <label htmlFor="">
                    <RiGroup2Line />
                    <span>Job Opening</span>
                  </label>
                  <p className="input-disabled">{jobDetails.openings}</p>
                </div>
                {/* job-opening-section end */}
                <div className="form-group">
                  <label htmlFor="">
                    <BiBell />
                    <span>Job Timing</span>
                  </label>
                  <p className="input-disabled">
                    {jobDetails.job_timings === 0
                      ? "General"
                      : jobDetails.job_timings === 1
                        ? "Rotation"
                        : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <BsTools />
                    <span>Skills Required</span>
                  </label>
                  <p className="input-disabled">{jobDetails && jobDetails.skills && jobDetails.skills.length ? jobDetails.skills : ""}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <FaRegFileVideo/>
                    <span>Animated Jd Required</span>
                  </label>
                  <p className="input-disabled">{jobDetails && jobDetails.hasOwnProperty('animated_jd_required') && jobDetails.animated_jd_required ? "Yes" : "No" }</p>
                </div>
                {jobEditButton === true ? (
                  <div className="employer_profile_btns mb-4">
                    <button type="submit" className="approvebtn">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="declinebtn"
                      onClick={_handleCancelButton}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="employer_profile_btns audio_profile_btns">
                  <input type="file" 
                    className="d-none" id="audio"
                    accept="audio/*"
                    onChange={uploadaudioJd}/>
                    {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) && jobEditButton == false ?
                  <label htmlFor="audio" className="approvebtn">Upload Audio JD</label> : ''}
                  <input type="file" 
                    className="d-none" id="video" 
                    accept="video/*"
                    onChange={uploadVedioJd}
                    disabled={jobDetails && jobDetails.hasOwnProperty('animated_jd_required') && jobDetails.animated_jd_required ? false : true }
                    />
                  {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) && jobEditButton == false ?
                  <label htmlFor="video" className="declinebtn">Upload Animated JD</label> : ''}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* edit description modal start */}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal job_des_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">{"Edit Description"}</DialogTitle>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(editDescription)}>
              <div className="form-group">
                <textarea 
                  id="description"
                  name="description"
                  rows="3"
                  defaultValue={jobDetails.description}
                  placeholder="Please enter job description"
                  className={classNames("form-control", {
                    "is-invalid": errors.description,
                  })}
                  ref={register({
                    required: "Please enter job description",
                    maxLength: {
                      value: 500,
                      message: "Description should be less than 500 characters",
                    },
                  })}
                  onChange={(e) =>
                    setCount(500 - e.target.value.length)
                  }
                  ></textarea>
                  <div className="text-right">
                    <p className="p-0 m-0">
                      {!errors.description && `${count}`}
                    </p>
                  </div>
                  {errors.description && (
                    <p className="text-danger">
                      {errors.description.message}
                    </p>
                  )}
              </div>
              <button type="submit" className="reasonbtn" onClick={handleClose}>SUBMIT</button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
      {/* edit description modal end */}
    </>
  );
};
