import axios from 'config';
import * as API from 'api';
import storage from 'utils/storage';
import { AuthActionTypes } from './actionType';
export const varifyOTP = (body) => dispatch =>  dispatch({
    type: AuthActionTypes.LOGIN,
    payload: API.varifyOTP(body).then(res => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        return res   
    })
})
export const getOTP = (body) => dispatch =>  dispatch({
        type: AuthActionTypes.GET_OTP,
        payload: API.getOTP(body)
})
// Recruiter Login using OTP start
export const getRecruiterOTP = (body) => dispatch =>  dispatch({
    type: AuthActionTypes.GET_RECRUITER_OTP,
    payload: API.getRecruiterOTP(body)
})

export const verifyRecruiterOTP = (body) => dispatch =>  dispatch({
    type: AuthActionTypes.VERIFY_RECRUITER_OTP,
    payload: API.verifyRecruiterOTP(body).then(res => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        return res;
    })
})
// Recruiter Login using OTP Ends

export const recruiterLogin = ({body}) => dispatch => {
    return dispatch({
        type: AuthActionTypes.LOGIN,
        payload: API.recruiterLogin(body).then(res => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            return res
        })
    })
};
//    SOCIAL LOGIN
export const recruitersociallogin = (data) => dispatch => {
    return dispatch({
        type: AuthActionTypes.SOCIAL_LOGIN,
        payload: API.SocialiLogin(data).then(res => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            return res
        })
    })
};

// END SOCIAL LOGIN

export const recruiterSignup = ({body}) => dispatch => {
    return dispatch({
        type: AuthActionTypes.SIGNUP,
        payload: API.recruiterSignup(body).then(res => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            return res
        })
    })
};

export const sarthiLogin = (body) => dispatch =>  dispatch({
    type: AuthActionTypes.LOGIN,
    payload: API.sarthiLogin(body).then(res => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        return res
    })
})
export const sarthiSignup = (body) => dispatch => {
    return dispatch({
        type: AuthActionTypes.SIGNUP,
        payload: API.sarthiSignup(body)
    })
};
export const refreshToken = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.REFRESH_TOKEN,
    payload: API.refreshToken(credentials)
        .then(response => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
            return response
        })
});

export const authorizeUser = (user_profile) => {
    console.log("authorize:" + user_profile.access_token);
    console.log("user:" + user_profile);
    storage.set('token', user_profile.access_token);
    storage.set('refresh_token', user_profile.refresh_token);
    storage.set('user', user_profile);
    const token = user_profile.access_token;
    const refresh_token = user_profile.refresh_token;
    return {
        type: AuthActionTypes.AUTHORIZE,
        payload: {
            token,
            user_profile,
            refresh_token
        }
    }
};

export const logout = () => {
    storage.remove('humlog_authToken');
    storage.remove('humlog_user');
    storage.remove('humlog_refresh_token');
    storage.remove('step');
    storage.remove('humlog_sub_role');
    storage.remove("humlog_emp_permission");
    //window.location.replace("/");
    return {
        type: AuthActionTypes.LOGOUT,
    }
};
//export const updateUserProfile = ({body, id}) => dispatch => dispatch({
//    type: AuthActionTypes.UPDATE_USER,
//    payload: API.updateUser(body, id)
//});
//export const getImageAccess = (params) => dispatch => dispatch({
//    type: AuthActionTypes.GET_IMAGE_ACCESS,
//    payload: API.getImageAccess(params)
//});
//
//export const gitDetailsAccess = ({body}) => dispatch => dispatch({
//    type: AuthActionTypes.GIT_DETAILS,
//    payload: API.gitDetailsAccess(body)
//});

// Enquiry Form 
export const enquiryForm = (body) => dispatch => dispatch({
    type: AuthActionTypes.ENQUIRY_FORM,
    payload: API.EnquiryForm(body).then(res => {
        return res;
    })
});
// Admin Login
export const adminLogin = (body) => dispatch => dispatch({
    type: AuthActionTypes.ADMIN_LOGIN,
    payload: API.adminLogin(body).then(res => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        return res;
    })
});

// Admin Saarthi Login
export const adminSaarthiLogin = (body) => dispatch => dispatch({
    type: AuthActionTypes.ADMIN_SAARTHI_LOGIN,
    payload: API.saarthiLogin(body).then(res => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        return res;
    })
});