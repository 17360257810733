import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { useIntl } from "react-intl";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const Educatiofrm = ({ handelClose, qualification, userID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, reset, errors } = useForm({});
  const [graduate, setGraduate] = useState({
    open: qualification && qualification > 2 ? true : false,
  });
  const [otherText, setOtherText] = useState(false);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = {
      qualification: qualification,
    };
    reset(data);
  };
  /**
   * @description method to open graduation and above tab
   * @param {param} event catch the mouse event
   */
  const openGraduation = (event) => {
    setGraduate({ open: !graduate.open });
    event.target.classList.toggle("graduation");
  };
  /**
   * @description method to close graduation and above tab
   * @param {param} event catch the mouse event
   */
  const closeGraduation = (event) => {
    setGraduate({ open: false });
    document.getElementById("Graduate & above").classList.remove("graduation");
  };
  /**
   * @description method to open/close other input field when click on other button
   * @param {param} event catch the mouse event
   */
  const openOtherText = (event) => {
    setOtherText(!otherText);
  };
  const closeOtherTextbox = () => {
    setOtherText(false);
  }
  /**
   * @description method to update qualification
   */
  const update = async (val) => {
    try {
      const dataval = {
        qualification: val.qualification,
      };
      let data = { ...dataval, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess("Qualification Updated Successfully", "Success");
      }
    } catch (error) {
      forError("Something Went Wrong", "Failure");
      console.log(error);
    }
    reset();
    handelClose();
  };
  const intl = useIntl();
  return (
    <>
      <div className="sup_pp">
        <div className="row">
          <div className="col-12">
            <h2 className="tttle prnl_head_aa">
              <FormattedMessage
                id="educationQualification"
                defaultMessage="Education & Qualification"
              />
            </h2>
            <div className="mt-2">
              <Form onSubmit={handleSubmit(update)}>
                <div className="d-flex justify-content-between">
                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="qualification"
                      value="0"
                      onClick={(event) => {
                        closeGraduation(event);
                        closeOtherTextbox();
                      }}
                      id="below 10th"
                      ref={register({
                        required: intl.formatMessage({
                          id: "pleaseSelectQualification",
                        }),
                      })}
                    />
                    <label
                      className="form-check-label text-capitalize lw"
                      for="below 10th"
                    >
                      below 10th
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="qualification"
                      id="10th pass"
                      onClick={(event) => {
                        closeGraduation(event);
                        closeOtherTextbox();
                      }}
                      value="1"
                      ref={register({
                        required: intl.formatMessage({
                          id: "pleaseSelectQualification",
                        }),
                      })}
                    />
                    <label
                      className="form-check-label text-capitalize lw"
                      for="10th pass"
                    >
                      10th Pass
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="qualification"
                      id="12th Pass"
                      onClick={(event) => {
                        closeGraduation(event);
                        closeOtherTextbox();
                      }}
                      ref={register}
                      value="2"
                      ref={register({
                        required: intl.formatMessage({
                          id: "pleaseSelectQualification",
                        }),
                      })}
                    />
                    <label
                      className="form-check-label text-capitalize lw "
                      for="12th Pass"
                    >
                      12th Pass
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      id="Graduate & above"
                      name="qualification"
                      value="Other"
                      onClick={(event) => {
                        openGraduation(event);
                        closeOtherTextbox();
                      }}
                      ref={register({
                        required: intl.formatMessage({
                          id: "pleaseSelectQualification",
                        }),
                      })}
                    />
                    <label
                      className="form-check-label text-capitalize lw"
                      htmlFor="Graduate & above"
                    >
                      <p className="grad"> Graduate & above </p>
                    </label>
                  </div>
                </div>
                {graduate.open && (
                  <div className="d-flex justify-content-between mt-2">
                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        value="3"
                        id="B.A"
                        onClick={(event) => {
                          closeOtherTextbox();
                        }}
                        ref={register({
                          required: intl.formatMessage({
                            id: "pleaseSelectQualification",
                          }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize lw"
                        for="B.A"
                      >
                        B.A
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="B.Sc"
                        value="4"
                        onClick={(event) => {
                          closeOtherTextbox();
                        }}
                        ref={register({
                          required: intl.formatMessage({
                            id: "pleaseSelectQualification",
                          }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize lw"
                        for="B.Sc"
                      >
                        B.Sc
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="B.Com"
                        onClick={(event) => {
                          closeOtherTextbox();
                        }}
                        ref={register}
                        value="5"
                        ref={register({
                          required: intl.formatMessage({
                            id: "pleaseSelectQualification",
                          }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize lw"
                        for="B.Com"
                      >
                        B.Com
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        id="otherGrad"
                        name="qualification"
                        value="otherQualification"
                        readOnly
                        onClick={(event) => {
                          openOtherText(event);
                        }}
                        ref={register({
                          required: intl.formatMessage({
                            id: "pleaseSelectQualification",
                          }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize lw"
                        for="otherGrad"
                      >
                        <p className="grad"> Other </p>
                      </label>
                    </div>
                  </div>
                )}

                {otherText && (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mb-2 mt-3">
                      <FormattedMessage
                        id="otherEducation"
                        defaultMessage="Other Qualification"
                      />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Add your Qualification"
                      name="qualification"
                      defaultValue="qualification"
                      className={classNames("form-control")}
                      ref={register({
                        required: "Please Enter Qualification",
                        maxLength: {
                          value: 30,
                          message: "maximum 30 characters allowed",
                        },
                        pattern: {
                          value: /^[a-zA-z.\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    {errors.qualification && (
                      <p className="text-danger oom p-0 m-0">
                        {errors.qualification.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                <div className="mt-4">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
