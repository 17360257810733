import axios from "axios";
import { useLocation } from "react-router-dom";
import toastr from "toastr";

export const download = (fileUrl) => {
    axios({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         let ext = get_url_extension(fileUrl); //get extension
         link.setAttribute('download', `file.${ext}`);
         document.body.appendChild(link);
         link.click();
      });
}
// get file extension
export const get_url_extension = ( url ) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}
/**
 * @description method to show toast notifications
 * @param {string} message messge
 * @param {string} title title
 */
toastr.options = {
    positionClass: "toast-top-right",
    showDuration: "300",
    timeOut: 3000,
    closeButton: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
export const forSuccess = (message, title) => toastr.success(message, title);

export const forError = (message, title) => toastr.error(message, title);

export const forWarning = (message, title) => toastr.warning(message, title);

/**
 * Common method getting search parameters from url
 * @returns object containinn search parameters
 */
 export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }