// import "../../css/commonmodal.scss";
import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import { Form, Button } from "react-bootstrap";
import GrAddCircle from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux"
import { UserActionTypes } from "redux/actions/UserActions/actionType";
import * as userActions from "redux/actions/UserActions"
import { serialize } from 'object-to-formdata';
import storage from "utils/storage"
import { useHistory } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";

import * as saarthiActions from "redux/actions/SaarthiActions";
import * as jobcategory from "redux/actions/jobcategory";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: '#ffffff',
    background: '#0096fb',
    height: '26px',
    borderRadius: '11px',
    marginTop: '0px'
  },
  other_Skills_Input: {
    padding: '4px 2px',
    marginBottom: '10px'
  }
}));

export const SkillsForm = ({ handelClose, skill, userID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [skills, setSkills] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [jobIntrested, setJobIntrested] = useState([]);
  const [input, setInput] = useState();
  const [otherSkills, setOtherSkills] = useState([]);
  const [JobCategoryList, setJobCategoryList] = useState();

  const fetchJobCategoryList = async () => {
    try {
      let res = await dispatch(jobcategory.getJobCategoryListData());
      let jobCategoryList = [];
      if (res) {
        jobCategoryList = res.value.data;
      }
      const job_category = getJobCategoryList();
      const skillsList = getSkillsList(job_category, jobCategoryList);
      setSkills(skillsList);
      if (skill && skill.length) {
        setJobIntrested(skill);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchJobCategoryList();
  }, [dispatch]);


  //get job_category List of the user
  const getJobCategoryList = () => {
    let job_category = [];
    if (skill && skill.length) {
      for (let val of skill) {
        job_category.unshift(val.job_category);
      }
    }
    return job_category;
  }

  // get all skills list for job_categories of the user
  const getSkillsList = (job_category, JobCategoryList) => {
    let skillsList = [];
    let jobCategoryIndex = 0;
    for (let val of job_category) {

      for (let category of JobCategoryList) {
        jobCategoryIndex = jobCategoryIndex + 1;
        if (val == category.name) {
          skillsList.unshift(category.skills);
          break;
        }
        if (jobCategoryIndex === JobCategoryList.length) {
          skillsList.unshift([]);
        }
      }
    }
    return skillsList;
  }

  /**
   * Method to handle skills entered for other job category
   * @param {String} value skill input entered by user
   * @param {String} job_category job category of user
   * @param {String} element_id
   */
  const handleInput = (value, job_category, element_id) => {
    if (value === '') {
      return;
    }
    let InputSkills;
    let job_interested = jobIntrested;
    for (let val of job_interested) {
      if (val.job_category === job_category) {
        InputSkills = val.skills;
      }
    }
    InputSkills.push(value);
    setInput('');
    document.getElementById(element_id).value = '';
    onSkillChange(InputSkills, job_category);
  }

  // update job_intrested array onSelect
  const onSkillChange = (e, job_category) => {
    let job_interested = jobIntrested;
    for (let val of job_interested) {
      if (val.job_category === job_category) {
        val.skills = e;
      }
    }
    setJobIntrested(job_interested);
  }



  /**
   * @description method to update skills
   */
  const update = async () => {
    try {
      let data = { job_intrested: jobIntrested, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess('Skills Updated Successfully', 'Success');
      }
    } catch (error) {
      forError('Something Went Wrong', 'Failure');
      console.log(error)
    }
    reset();
    handelClose();
  };

  return (
    <>
      <div className="conatiner">
        {" "}
        <div className="row">
          <div className="col-12">
            <h2 className="prnl_head_aa"><FormattedMessage id="skills" defaultMessage="Skills" /></h2>
            <div className="">
              {skill && skill.length > 0 &&
                skill.map((val, index) => {
                  return (
                    <>
                      <div className=" mb-2">{val.job_category}</div>
                      {skills && skills[index] && skills[index].length > 0 ? (
                        <Multiselect options={skills[index]}
                          style={{
                            multiselectContainer: {
                              "marginBottom": "10px"
                            },
                            optionContainer: { "maxHeight": "140px" },
                            chips: {
                              "whiteSpace": "break-spaces"
                            },
                          }}
                          isObject={false}
                          onSelect={(e) => onSkillChange(e, val.job_category)}
                          onRemove={(e) => onSkillChange(e, val.job_category)}
                          selectedValues={val.skills}
                          selectionLimit={3}
                        />
                      ) : (
                        <div>
                          {val.skills.map(skill => {
                            return (
                              <Chip
                                key={skill}
                                label={skill}
                                className={classes.chip}
                              />
                            )
                          })}
                          <div>
                            <input type='text' className={classes.other_Skills_Input} name={index}
                              id={index} placeholder='Enter skills'
                              onInput={e => setInput(e.target.value)} disabled={val.skills.length < 3 ? false : true}
                            />
                            <GrAddCircle className="ml-1" onClick={(e) => handleInput(input, val.job_category, index)}
                              style={{ color: "#0678C1", fontSize: "30px", marginLeft: "15px" }} />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>{" "}
          </div>
        </div>
        <button onClick={update} className="common_btn"><FormattedMessage id="save" defaultMessage="Save" /></button>
      </div>
    </>
  );
};
