import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { jobReducer } from './jobReducer';
import { userReducer } from './userReducer';
import { jobCategoryReducer } from './jobCategoryReducer';
import { adminReducer } from './adminReducer';
import { saarthiReducer } from './saarthiReducer';
const reducers = combineReducers({
        authReducer,userReducer,jobReducer,jobCategoryReducer,adminReducer,saarthiReducer
});

export default reducers;
