import admin_logo from "../../assets/images/logos/admin_logo.png";
import { useDispatch } from "react-redux";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "Languages/Wrapper/Wrapper";
import storage from "utils/storage";
import classNames from "classnames";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import Alert from "@material-ui/lab/Alert";
import * as userAuthAction from "redux/actions/AuthActions";

export const AdminLogin = () => {
  const context = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaarthiSubmitting, setIsSaarthiSubmitting] = useState(false);
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm({
    mode: "onBlur",
  });
  const { 
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 }, 
  } = useForm({
    mode: "onChange",
  });
  const [error, setError] = useState({ open: false, message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(true); // variable use for checkbox is checked or not

  /**
   * @description method for admin login
   * @param {param} data values of email and password 
   */
  const adminsubmit = async (data) => {
    try {
      const loginDetails = {
        email: data.email,
        password: data.password,
      };
      let res = await dispatch(userAuthAction.adminLogin(loginDetails))
      if (res.value.success) {
        setIsSubmitting(true);
        history.push("/admin-dashboard");
      }
    } catch (err) {
      setError({ open: true, message: err.data.message });
      setIsSubmitting(false);
      setTimeout(() => { setError({ open: false, message: "" }) }, 3000);
    }
  }
  /**
   * @description method for admin saarthi login
   * @param {param} data values of email and password 
   */
  const saarthisubmit = async (data) => {
    try {
      const saarthiLoginDetails = {
        email: data.email,
        password: data.password,
      };
      let res = await dispatch(userAuthAction.adminSaarthiLogin(saarthiLoginDetails))
      if (res.value.success) {
        setIsSaarthiSubmitting(true);
        history.push("/saarthi-dashboard");
      }
    } catch (error) {
      setError({ open: true, message: error.data.message });
      setIsSaarthiSubmitting(false);
      setTimeout(() => { setError({ open: false, message: "" }) }, 3000);
    }
  }

  const [state, setState] = React.useState({
    saarthi: false,
  });
  const switchDashboard = (event) => {
    setState({ saarthi: !state.saarthi });
  };

  const resetPassword = () => {
    history.push("/reset-password");
  }

  // Handing checkbox is checked or not
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {error.open && <Alert severity="error">{error.message}</Alert>}
      <div className="admin_login">
        <div className="container">
          <div className="admin_inner">
            <div className="admin_logo">
              <img src={admin_logo} alt="HumlogJobs" className="img-fluid d-block" />
            </div>
            <div className="flip-card">
              <div className={"dashboard_login_box flip-card-inner " + (state.saarthi ? "flip-card-active" : "")}>
                {!state.saarthi ?
                  (
                    <div className="admin_login_box flip-card-front">
                      <div className="admin_login_form">
                        <h1>Admin Login</h1>
                        <form onSubmit={handleSubmit(adminsubmit)} key={1}>
                          <div className="form-group">
                            <input 
                              autoComplete="off" 
                              className="form-control" 
                              type="email" 
                              name="email" 
                              placeholder="Email"
                              ref={register({
                                required: "This field is required.",
                                validate: {
                                  validation: (value) =>
                                    regexValidation.EMAIL.test(value) ||
                                    "Please enter valid email address",
                                },
                              })} 
                            />
                            {errors.email && (
                              <p className="help is-danger">{errors.email.message}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <input 
                              autoComplete="off" 
                              className="form-control" 
                              type="password" 
                              name="password" 
                              placeholder="Password"
                              ref={register({
                                required: "This field is required.",
                                // validate: {
                                //   validation: (value) =>
                                //     regexValidation.PASSWORD.test(value) ||
                                //     "Please enter at least 8 characters",
                                // },
                              })} 
                              />
                            {errors.password && (
                              <p className="help is-danger">{errors.password.message}</p>
                            )}
                          </div>
                          <div className="">
                            <button className="submitbtn" type="submit">Login</button>
                          </div>
                        </form>
                      </div>
                      <div className="saarthi_login_link">
                        <button className="saarthi_login_btn" type="button" onClick={switchDashboard}>Switch to Sarthi Dashboard</button>
                      </div>
                    </div>
                  )
                  :
                  (
                    <div className="admin_login_box saarthi_login_box flip-card-back">
                      <div className="admin_login_form">
                        <h1>Sarthi Login</h1>
                        <form onSubmit={handleSubmit2(saarthisubmit)} key={2}>
                          <div className="form-group">
                            <input 
                              autoComplete="off" 
                              className="form-control" 
                              type="email" 
                              name="email" 
                              placeholder="Email"
                              ref={register2({
                                required: "This field is required.",
                                validate: {
                                  validation: (value) =>
                                    regexValidation.EMAIL.test(value) ||
                                    "Please enter valid email address",
                                },
                              })}
                            />
                            {errors2.email && (
                              <p className="help is-danger">{errors2.email.message}</p>
                            )}
                          </div>
                          <div className="form-group mb-0">
                            <input 
                              autoComplete="off" 
                              className="form-control" 
                              type="password" 
                              name="password" 
                              placeholder="Password"
                              ref={register2({
                                required: "This field is required.",
                                // minLength: {
                                //   value: 8,
                                //   message: "Please enter minimum 8 characters",
                                // },
                              })}
                            />
                            {errors2.password && (
                              <p className="help is-danger">{errors2.password.message}</p>
                            )}
                          </div>

                          <div className="tnc_main">
                            <div className="form-check">
                              <input
                                className="form-check-input mtt"
                                autoComplete="off"
                                type="checkbox"
                                id="gridCheck"
                                name="tnc"
                                checked={isChecked}
                                onChange={handleOnChange}
                                ref={register2({
                                  required:
                                    "Please tick the checkbox of T&C and Privacy Policy",
                                })}
                              />
                              <label className="form-check-label" htmlFor="gridCheck">
                                <p>
                                  <FormattedMessage 
                                    id="privacyPolicy"
                                    defaultMessage="I hereby agree to the T&C and the Privacy Policy"
                                    values=
                                    {{
                                      tnc:
                                        <a className="text-decoration-none text-underline bbg"
                                          onClick={() => history.push("/terms&conditions")}>
                                          {" "}
                                          T&C
                                          {" "}
                                        </a>,
                                      privacy:
                                        <a className="text-decoration-none text-underline bbg"
                                          onClick={() => history.push("/privacypolicy")}>
                                          {" "}
                                          Privacy Policy
                                          {" "}
                                        </a>
                                    }}
                                  />
                                </p>
                              </label>
                              {errors2.tnc && (
                                <p className="text-danger">
                                  {errors2.tnc.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="mb-2">
                            <button className="saarthi_login_btn" type="button" onClick={resetPassword}>Reset Password</button>
                          </div>
                          <div className="">
                            <button className="submitbtn" type="submit">Login</button>
                          </div>
                        </form>
                      </div>
                      <div className="saarthi_login_link">
                        <button className="saarthi_login_btn" type="button" onClick={switchDashboard}>Switch to Admin Dashboard</button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
