import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as adminActions from "redux/actions/AdminActions";
import { FaTimes } from "react-icons/fa";
import industry from "../../../assets/images/admin/industry.svg";
import house from "../../../assets/images/admin/house.svg";
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import building from "../../../assets/images/admin/building.svg";
import location_pin from "../../../assets/images/admin/location-pin.svg";
import email from "../../../assets/images/admin/email.svg";
import video from "../../../assets/images/admin/video.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { downloadFile } from "../../../utils/fileService";
import { download, get_url_extension } from "../../services/commonservice";
import Pdf from "../../common/pdf";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { Player } from "../../common/player";
import { DistrictList } from "../../../utils/DistrictList";
import { IndustryData } from "../../../utils/IndustryData";
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { FaCamera } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { uploadFile } from "../../../utils/fileService";
import avatar from "../../../assets/images/male_avatar.png";
import * as commonService from "utils/CommonService.js";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const EmployerProfile = (props) => {
  const [userDetailsById, setuserDetailsById] = useState({});
  const query = new URLSearchParams(useLocation().search);
  const [open, setOpen] = useState(false);
  const [showOrganizationModal, setOrganizationModal] = useState(false); // variable use for showing & hiding selecting organization type modal
  const [organizationTypeValue, setOrganizationTypeValue] = useState(""); // storing organization type value
  const [disabled, setDisabled] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [fileId, setFileId] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [openDoc, setOpenDoc] = useState(false);
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [error, setError] = useState({ open: false, message: "" });
  const [editProfile, setEditProfile] = useState(false); // storing edit profile value
  const [disableReadOnly, setReadOnlyProperty] = useState(true); // storing readonly value
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage
  const [document, setUploadDocument] = useState({}); // storing upload document
  const [verificationType, setVerficationType] = useState(""); // storing verification type
  const [emailValue, setEmailValue] = useState(""); // storing email id
  const [isDropdownSelect, selectVerificationType] = useState(0); // storing select verification type
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });
  let userId = query.get("emp_id"); // get employer Id
  const dispatch = useDispatch();
  const history = useHistory();
  const [picture, setPicture] = useState(
    userDetailsById &&
      userDetailsById.profile_pic &&
      userDetailsById.profile_pic.length > 0 ?
      userDetailsById.profile_pic :
      avatar
  ); // storing profile image value
  const [uploadFiles, setUploadFile] = useState(); // storing new image url
  const [companyVideo, setCompanyVideo] = useState(
    userDetailsById && userDetailsById.video_resume
  );
  const [vedioErr, setVedioErr] = useState(null);
  const [isError, setisError] = useState({ open: false, message: "" });
  // company verification type list
  const companyDocumentList = [
    { id: "gst", name: "Company GST Certificate" },
    { id: "pan", name: "Company Pan Card" },
    { id: "crn", name: "Company Registration Document" },
    { id: "aadhar_card", name: "Company Udyog Aadhaar Card" },
    { id: "shop_est_act", name: "Shop and Establishment Act" },
    { id: "food_license", name: "Food License ( Restaurant/Cafe)" }
  ];

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      // API for get user data fetch by Id
      let query = {
        emp_id: userId
      }
      dispatch(userActions.getUserDataBySarthi(query))
        .then((response) => {
          if (response && response.value.success) {
            setuserDetailsById(response.value.data); // storing employer data
            setFileId(response.value.data.documents);
            setPicture(`${response.value.data.profile_pic}?${Date.now()}`); // get and set user profile
            setCompanyVideo(response.value.data.video_resume); // get and set video resume
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    isEmpty();
  }, [loaded, dispatch, userId]);
  const translate = useIntl();
  // function for upload employer profile
  const onChangePicture = async (e) => {
    setPicture(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  // function for upload video resume
  const uploadVedioResume = async (e) => {
    let video = e.target.files[0];
    if (!commonService.validateFileContentTypeAndSize(video.type, "video")) {
      setisError({
        open: true,
        message: "Please upload the correct format file.",
      });
      setTimeout(() => {
        setisError(false);
      }, 5000);
      return;
    }
    if (!commonService.validateFileSize(video.size, 200)) {
      setisError({
        open: true,
        message: "File size should be less than 200 MB",
      });
      setTimeout(() => {
        setisError(false);
      }, 5000);
      return;
    }
    let videoUrl = await uploadFile(video, "video", '', userId);
    setCompanyVideo(videoUrl);
  };
  // open reason dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  // close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenDoc(false);
  };
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    if (event.target.value === "other") {
      setDisabled(false);
      setValue(event.target.value);
      return;
    }
    setDisabled(true);
    setValue(event.target.value);
  };
  const handleOthersChange = (event) => {
    setValue(event.target.value);
  };
  // get file url
  // need optiomization in coming sprint
  const getFileUrl = async (documents) => {
    let url = "";
    if (
      documents &&
      documents.pan &&
      documents.pan.files &&
      documents.pan.files.length
    ) {
      url = await downloadFile(documents.pan.files[0]);
    } else if (
      documents &&
      documents.crn &&
      documents.crn.files &&
      documents.crn.files.length
    ) {
      url = await downloadFile(documents.crn.files[0]);
    } else if (
      documents &&
      documents.cin &&
      documents.cin.files &&
      documents.cin.files.length
    ) {
      url = await downloadFile(documents.cin.files[0]);
    } else if (
      documents &&
      documents.gst &&
      documents.gst.files &&
      documents.gst.files.length
    ) {
      url = await downloadFile(documents.gst.files[0]);
    } else if (
      documents &&
      documents.aadhar_card &&
      documents.aadhar_card.files &&
      documents.aadhar_card.files.length
    ) {
      url = await downloadFile(documents.aadhar_card.files[0]);
    } else if (
      documents &&
      documents.food_license &&
      documents.food_license.files &&
      documents.food_license.files.length
    ) {
      url = await downloadFile(documents.food_license.files[0]);
    } else if (
      documents &&
      documents.shop_est_act &&
      documents.shop_est_act.files &&
      documents.shop_est_act.files.length
    ) {
      url = await downloadFile(documents.shop_est_act.files[0]);
    }
    return url;
  };
  const getFile = async (documents) => {
    const url = await getFileUrl(documents);
    await download(url); //download document
  };
  const handleChangeValue = (val) => {
    if (val == "email") {
      selectVerificationType(1); // 1 value represent if drodown value is email
    } else {
      selectVerificationType(2); // 2 value represent if drodown value is otherthan email
    }
  }
  // email validation
  const emailValidation = (val) => {
    return !(val.match(regexValidation.EMAIL_FILTERS));
  }
  const documentUpload = (file) => {
    setUploadDocument(file);
    setUploadDocument((state) => {
      return state;
    })
  }
  const getData = (fileId) => {
    return {
      files: [fileId],
    }
  }

  // If organization_type is not found when showing modal for select organization type
  const _handleApproveButton = (status, userDetailsById, feedback, verificationType, emailError) => {
    if (userDetailsById && userDetailsById.organization_type) {
      updateStatus(status, feedback, verificationType);
    } else if (!emailError) {
      setOrganizationModal(true);
    }
  }
  // update status to approved or declined
  const updateStatus = async (status, feedback, verificationType) => {
    if (userDetailsById && userDetailsById.verification_status) {
      switch (userDetailsById.verification_status) {
        case "Approved":
          setError({ open: true, message: "Status is already Approved" });
          break;
          {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE*/ }
        // case "Not Initiated":
        //   setError({ open: true, message: "Document is not uploaded" });
        //   break;
        default:
          let documents;
          if (verificationType && verificationType.length > 0) {
            if (!commonService.validateFileContentTypeAndSize(document.type, 'documents')) {
              setisError({ open: true, message: "Please upload the correct format file." });
              setTimeout(() => { setisError(false) }, 5000);
              return;
            }
            if (!commonService.validateFileSize(document.size, 5)) {
              setisError({ open: true, message: "File size should be less than 5 MB" });
              setTimeout(() => { setisError(false) }, 5000);
              return;
            }
            // upload document
            const fileId = await uploadFile(document, "documents", "private");
            switch (verificationType) {
              case "gst":
                documents = {
                  gst: getData(fileId)
                }
                break;
              case "cin":
                documents = {
                  cin: getData(fileId)
                }
                break;
              case "crn":
                documents = {
                  crn: getData(fileId)
                }
                break;
              case "pan":
                documents = {
                  pan: getData(fileId)
                }
                break;
              case "individualPan":
                documents = {
                  pan: getData(fileId)
                }
                break;
              case "aadhar_card":
                documents = {
                  aadhar_card: getData(fileId)
                }
                break;
              case "shop_est_act":
                documents = {
                  shop_est_act: getData(fileId)
                }
                break;
              case "food_license":
                documents = {
                  food_license: getData(fileId)
                }
                break;
              default:
                documents = {}
            }
          }
          const body = {
            verification_status: status,
            verification_feedback: feedback ? feedback : "Approved",
            organization_type: userDetailsById.organization_type ? userDetailsById.organization_type : organizationTypeValue,
            documents: documents
          };
          if (status === 'Declined') {
            delete body.organization_type
          }
          let userId = "";
          if (userDetailsById && userDetailsById._id) {
            userId = userDetailsById._id;
          }
        dispatch(adminActions.updateUser(body, userId))
          .then((response) => {
            if (response && response.value.success) {
              setSuccess({
                open: true,
                message: `Status changed to ${status}`,
              });
              setLoaded(false);
            }
          })
          .catch((error) => {
            console.log("error while updating status", error);
            setError({ open: true, message: "error while updating status" });
          });
      }
      setTimeout(() => {
        setSuccess({ open: false, message: "" });
        setError({ open: false, message: "" });
      }, 5000);
    }
    handleClose();
    setOrganizationModal(false); // Hide Organization type Modal
  };
  // API to update employer profile
  const updateEmployerProfile = async (val) => {
    let imageUrl;
    try {
      let data = {
        ...val,
      };
      if (uploadFiles) {
        imageUrl = await uploadFile(uploadFiles, "profile", '', userId);
        data.profile_pic = imageUrl;
      }
      if (companyVideo) {
        data.video_resume = companyVideo;
      }
      dispatch(adminActions.updateUser(data, userId))
        .then((res) => {
          if (res.value.success) {
            // API for get user data fetch by Id
            let query = {
              emp_id: userId
            }
            dispatch(userActions.getUserDataBySarthi(query));
            setSuccess({
              open: true,
              message: "Employer Profile Updated Successfully!",
            });
            setTimeout(() => {
              setReadOnlyProperty(true);
              setEditProfile(false);
              // API for get user data fetch by Id
              let query = {
                emp_id: userId
              }
              dispatch(userActions.getUserDataBySarthi(query));
            }, 1000);
          }
          setTimeout(() => {
            setSuccess({ open: false, message: "" });
          }, 3000);
          setLoaded(false);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };
  const [modal, setModal] = useState({ open: false, type: "" });
  const [deactive, setDeactive] = useState({ isOpen: false, id: "" });
  // method for handling modal
  const onToggleModal = (type) => {
    setModal({ open: !modal.open, type: type });
  };
  // open deactivate dialog
  const openDeactive = (id) => {
    setDeactive({ isOpen: true, id: id });
  };
  // Close deactivate dialog
  const closeDeactive = () => {
    setDeactive({ isOpen: false });
  };

  // method to check document in userdetails object values filled or not
  const isEmpty = () => {
    let result = false;
    if (userDetailsById && userDetailsById.documents) {
      for (var document of Object.keys(userDetailsById.documents)) {
        if (userDetailsById.documents[document].hasOwnProperty('files') && userDetailsById.documents[document].files.length) {
          result = true;
        }
      }
      return result
    }
  }

  // Show Hide Approve/Decline Button According to Screens
  const _showHideApproveButton = () => {
    if (editProfile === false && props.location.state === "registration")
      return (
        <div>
          {userDetailsById.documents?.email_id && userDetailsById.documents?.email_id.doc_no.length ?
            ("")
            : !isEmpty() ?
              ("") : (<div className="download_doc">
                <button
                  type="button"
                  onClick={() => {
                    getFile(fileId);
                  }}
                >
                  Download Document
                </button>
                <span>|</span>
                <button
                  type="button"
                  onClick={async () => {
                    const url = await getFileUrl(fileId);
                    setFileUrl(url);
                    setOpenDoc(true);
                  }}
                >
                  View Document
                </button>
              </div>)
          }
          <div className="employer_profile_btns">
            <button
              type="button"
              className="approvebtn"
              onClick={() => {
                _handleApproveButton("Approved", userDetailsById, '', verificationType, errors.email);
              }}
            >
              Approve
            </button>
            <button
              type="button"
              className="declinebtn"
              onClick={handleClickOpen}
            >
              Decline
            </button>
          </div>
        </div>
      );
  };
  // Handling Show-Hide Edit Button
  const handleEditButton = () => {
    setEditProfile(true);
    setReadOnlyProperty(false);
  };
  // Handling Show-hide Cancel Button
  const handleCancelButton = () => {
    reset();
    setEditProfile(false);
    setReadOnlyProperty(true);
    setPicture(userDetailsById.profile_pic); // reset user profile
    setCompanyVideo(userDetailsById.video_resume); // reset video resume
  };
  // showing Industry select dropdown
  const _handleIndustryDropdown = (industryValue) => {
    if (disableReadOnly === true) {
      return (
        <select
          className="select"
          ref={register}
          value={industryValue}
          className="form-control"
          id="company_category"
          name="company_category"
          autoComplete="off"
          disabled={disableReadOnly}
          readOnly={disableReadOnly}
        >
          {IndustryData.map((val) => {
            return (
              <>
                <option> {val}</option>
              </>
            );
          })}
        </select>
      )
    } else if (disableReadOnly === false) {
      return (
        <select
          className="select"
          ref={register}
          defaultValue={industryValue && industryValue.length ? industryValue : ''}
          className="form-control"
          id="company_category"
          name="company_category"
          autoComplete="off"
          disabled={disableReadOnly}
          readOnly={disableReadOnly}
        >
          {IndustryData.map((val) => {
            return (
              <>
                <option> {val}</option>
              </>
            );
          })}
        </select>
      )
    }
  }
  // showing District select dropdown
  const _handleDistrictDropdown = (districtValue) => {
    if (disableReadOnly === true) {
      return (
        <select
          className="select"
          value={districtValue && districtValue.length ? districtValue : ''}
          className="form-control"
          id="address_home"
          name="address_home"
          autoComplete="off"
          ref={register}
          disabled={disableReadOnly}
          readOnly={disableReadOnly}
        >
          {DistrictList.map((val) => {
            return (
              <>
                <option> {val}</option>
              </>
            );
          })}
        </select>
      )
    } else if (disableReadOnly === false) {
      return (
        <select
          className="select"
          defaultValue={districtValue}
          className="form-control"
          id="address_home"
          name="address_home"
          autoComplete="off"
          ref={register}
          disabled={disableReadOnly}
          readOnly={disableReadOnly}
        >
          {DistrictList.map((val) => {
            return (
              <>
                <option> {val}</option>
              </>
            );
          })}
        </select>
      )
    }
  }
  return (
    <>
      {error.open && (
        <Alert severity="error" className="alert-toast">
          {error.message}
        </Alert>
      )}
      {success.open && (
        <Alert severity="success" className="alert-toast">
          {success.message}
        </Alert>
      )}
      {/* Employer profile section start from here */}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Employer Details</h6>
            <div className="employer_profile_form">
              <button
                type="button"
                className="backbtn"
                onClick={() => history.goBack()}
              >
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form
                onSubmit={handleSubmit(updateEmployerProfile)}
                id="form-cancel"
              >
                {/* Edit-button-section start from here */}
                {editProfile === false &&
                  props.location.state === "registration" && commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_UPDATE]) ? (
                  <div className="employer_profile_btns employer-edit-button">
                    <button
                      type="button"
                      className="approvebtn"
                      onClick={handleEditButton}
                    >
                      Edit Profile
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {/* employer-profile-section start from here */}
                <div className="profile_img emp_profile_img">
                  <img
                    src={picture}
                    alt="company-logo"
                    className="img-fluid company_logo"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = avatar;
                    }}
                  />
                  <div className="profile_img_add">
                    <label htmlFor="profile_pic">
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="d-none"
                        id="profile_pic"
                        name="profile_pic"
                        onChange={onChangePicture}
                      />
                      {editProfile === true ? (
                        <FaCamera className="camera_position" />
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                {/* employer-profile-section start from here */}
                {/* company-name-section start from here */}
                <div className="profile_name">
                  <h4>
                    {userDetailsById &&
                      userDetailsById.company_name &&
                      userDetailsById.company_name.length > 0
                      ? userDetailsById.company_name
                      : ""}
                  </h4>
                </div>
                {/* company-name-section end */}
                {/* Industry-section start from here */}
                {/* <div class="form-group">
                  <label htmlFor="company_category">
                    <img src={industry} alt="industry" className="img-fluid" />
                    <span>Industry</span>
                  </label>
                  {_handleIndustryDropdown(userDetailsById.company_category)}
                </div> */}
                {/* Industry-section end */}
                {/* Company-name-section start from here */}
                <div class="form-group">
                  <label htmlFor="company_name">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Company Name</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    autoComplete="off"
                    defaultValue={userDetailsById.company_name}
                    readOnly={disableReadOnly}
                    ref={register({
                      required: translate.formatMessage({
                        id: "enterCompanyName",
                      }),
                      minLength: {
                        value: 4,
                        message: translate.formatMessage({
                          id: "minimumFourDigit",
                        }),
                      },
                      maxLength: {
                        value: 50,
                        message: translate.formatMessage({
                          id: "maximumFiftyDigit",
                        }),
                      },

                      pattern: {
                        value: regexValidation.COMPANY_NAME,
                        message: translate.formatMessage({
                          id: "allowSpecialCharacter",
                        }),
                      },
                    })}
                  />
                  {errors.company_name && (
                    <span className="text-danger txt">
                      {errors.company_name.message}
                    </span>
                  )}
                </div>
                {/* Company-name-section end */}
                {/* Company-address-section start from here */}
                {/* <div class="form-group">
                  <label htmlFor="company_address">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Company Address</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    autoComplete="off"
                    defaultValue={userDetailsById.company_address}
                    readOnly={disableReadOnly}
                    ref={register({
                      required: translate.formatMessage({
                        id: "enterCompanyAddress",
                      }),
                      minLength: {
                        value: 4,
                        message: translate.formatMessage({
                          id: "minimumFourDigit",
                        }),
                      },
                      maxLength: {
                        value: 100,
                        message: translate.formatMessage({
                          id: "maximumHundredCharacter",
                        }),
                      },
                      pattern: {
                        value: regexValidation.COMPANY_ADDRESS,
                        message:
                          "Company Address can contain alphanumeric characters and special character ('.', ',', '-'). Company Address can not start with special characters",
                      },
                    })}
                  />
                  {errors.company_address && (
                    <span className="text-danger  txt">
                      {errors.company_address.message}
                    </span>
                  )}
                </div> */}
                {/* Company-address-section end */}
                {/* District-dropdown-section start from here */}
                {/* <div class="form-group">
                  <label htmlFor="">
                    <img src={house} alt="house" className="img-fluid" />
                    <span>District</span>
                  </label>
                  {_handleDistrictDropdown(userDetailsById.address_home)}
                </div> */}
                {/* District-dropdown-section end */}
                {/* Pincode-section start from here */}
                {/* <div class="form-group">
                  <label htmlFor="pincode">
                    <img
                      src={location_pin}
                      alt="house"
                      className="img-fluid width11"
                    />
                    <span>Pincode</span>
                  </label>
                  <input
                    type="number"
                    className={classNames("form-control", {
                      "is-invalid": errors.pincode,
                    })}
                    id="pincode"
                    name="pincode"
                    autoComplete="off"
                    defaultValue={userDetailsById.pincode}
                    readOnly={disableReadOnly}
                    ref={register({
                      required: translate.formatMessage({ id: "enterPincode" }),
                      minLength: {
                        value: 6,
                        message: translate.formatMessage({ id: "minimumSixDigit" }),
                      },
                      maxLength: {
                        value: 6,
                        message: translate.formatMessage({ id: "maximumSixDigit" }),
                      },
                      pattern: {
                        value: /^\d/,
                        message: translate.formatMessage({
                          id: "enterNumericValue",
                        }),
                      },
                    })}
                  />
                  {errors.pincode && (
                    <span className="text-danger txt">
                      {errors.pincode.message}
                    </span>
                  )}
                </div> */}
                {/* Pincode-section end */}
                {/* Contact-person-name-section start from here */}
                <div class="form-group">
                  <label htmlFor="name">
                    <img src={phone} alt="phone" className="img-fluid" />
                    <span>Contact Person Name</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    autoComplete="off"
                    defaultValue={userDetailsById.name}
                    readOnly={disableReadOnly}
                    ref={register({
                      required: translate.formatMessage({
                        id: "pleaseEnterPersonName",
                      }),

                      pattern: {
                        value: regexValidation.PERSON_NAME,
                        message: translate.formatMessage({
                          id: "specialCharacterNotAllowed",
                        }),
                      },

                      maxLength: {
                        value: 25,
                        message: translate.formatMessage({
                          id: "maximumSeventyFiveDigit",
                        }),
                      },
                    })}
                  />
                  {errors.name && (
                    <span className="text-danger  txt">
                      {errors.name.message}
                    </span>
                  )}
                </div>
                {/* Contact-person-name-section end */}
                {/* Contact-person-number-section start from here */}
                <div class="form-group">
                  <label htmlFor="contact">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Contact Person Number</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact"
                    name="contact"
                    autoComplete="off"
                    defaultValue={userDetailsById.contact}
                    readOnly
                  />
                </div>
                {/* Contact-person-number-section end */}
                {/* Email-address-section start from here */}

                <div class="form-group">
                  <label htmlFor="">
                    <img src={email} alt="industry" className="img-fluid" />
                    <span>Email Address</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    autoComplete="off"
                    defaultValue={userDetailsById.email}
                    readOnly
                  />
                </div>
                {/* Email-address-section end */}
                {/* Company-video-section start from here */}
                <div className="row">
                  <div className="col-md-8">
                    <div class="form-group">
                      <label htmlFor="">
                        <img src={video} alt="industry" className="img-fluid" />
                        <span>Company Video</span>
                      </label>
                      {userDetailsById &&
                        userDetailsById.video_resume &&
                        userDetailsById.video_resume.length ? (
                        <a
                          type="button"
                          onClick={() => {
                            onToggleModal(userDetailsById.video_resume);
                          }}
                          className="videofile"
                        >
                          Company Video
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    {editProfile === true ? (
                      <div className="">
                        <label for="video_resume">
                          <input
                            type="file"
                            className="d-none"
                            id="video_resume"
                            accept="video/*"
                            onChange={uploadVedioResume}
                          />
                          <span
                            htmlFor="video_resume"
                            className="add_cmpny_video"
                          >
                            <BiEdit />
                            Edit Video
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {vedioErr?.length > 0 && (
                    <div className="text-danger">{vedioErr}</div>
                  )}
                  <Player
                    open={modal.open}
                    toggleModal={onToggleModal}
                    src={companyVideo}
                  />
                </div>
                {/* Company-video-section end */}
                {editProfile === false ? (
                  <div className="document_details">
                    {/* Document-name-section start from here */}
                    {userDetailsById.verification_status == 'Not Initiated' ?
                      <Form.Group controlId="verification_selectbox">
                        <Form.Label className="">
                          <FormattedMessage id="verificationtype" defaultMessage="Verification Type" />
                        </Form.Label>
                        <Form.Control
                          as="select"
                          autoComplete="off"
                          name="category"
                          value={verificationType}
                          onChange={(e) => {
                            setVerficationType(e.target.value)
                            handleChangeValue(e.target.value)
                          }}
                          ref={register()}
                        >
                          <option value="" disabled="disabled">Select Options</option>
                          {companyDocumentList.map((val) => {
                            return (
                              <>
                                <option value={val.id}> {val.name}</option>
                              </>
                            );
                          })}
                        </Form.Control>
                        {errors.category && (
                          <p className="text-danger oom p-0 m-0">
                            {errors.category.message}
                          </p>
                        )}
                      </Form.Group> :
                      <p>
                        <span>
                          {
                            userDetailsById.documents?.email_id && userDetailsById.documents?.email_id.doc_no.length
                              ? "Verification Type: " : "Document Name: "
                          }
                        </span>
                        {
                          userDetailsById && userDetailsById.documents
                            ? userDetailsById.documents.crn.files &&
                              userDetailsById.documents.crn.files.length
                              ? "CRN"
                              : userDetailsById.documents.pan.files &&
                                userDetailsById.documents.pan.files.length
                                ? "PAN"
                                : userDetailsById.documents.cin.files &&
                                  userDetailsById.documents.cin.files.length
                                  ? "CIN"
                                  : userDetailsById.documents.gst.files &&
                                    userDetailsById.documents.gst.files.length
                                    ? "GST"
                                    : userDetailsById.documents.aadhar_card.files &&
                                      userDetailsById.documents.aadhar_card.files.length
                                      ? "AADHAR CARD"
                                      : userDetailsById.documents.food_license.files &&
                                        userDetailsById.documents.food_license.files.length
                                        ? "FOOD LICENSE"
                                        : userDetailsById.documents.shop_est_act.files &&
                                          userDetailsById.documents.shop_est_act.files.length
                                          ? "SHOP ESTABLISHMENT ACT"
                                          : userDetailsById.documents.email_id.doc_no &&
                                            userDetailsById.documents.email_id.doc_no.length
                                            ? "Email"
                                            : "No Document Present"
                            : ""
                        }
                      </p>}
                    {
                      isDropdownSelect && isDropdownSelect == 1 && userDetailsById.verification_status == 'Not Initiated' ?
                        (
                          <Form.Group controlId="email">
                            <Form.Label className="d-flex justify-content-start llbl">
                              <FormattedMessage id="email" defaultMessage="Email" />
                            </Form.Label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              placeholder="Please enter email"
                              name="email"
                              onInput={(e) => setEmailValue(e.target.value)}
                              ref={register({
                                pattern: {
                                  value: regexValidation.EMAIL,
                                  message: "Please enter correct email address",
                                },
                                validate: {
                                  validation: (value) =>
                                    emailValidation(value) ||
                                    "Please enter email of consultancy / company only"
                                },
                              })}
                            >
                            </Form.Control>
                            {errors.email && (
                              <span className="text-danger oom p-0 m-1">
                                {errors.email.message}
                              </span>
                            )}
                          </Form.Group>
                        ) : ("")
                    }
                    {
                      isDropdownSelect && isDropdownSelect == 2 && userDetailsById.verification_status == 'Not Initiated' ? (
                        <>
                          <Form.Group controlId="upload_document">
                            <Form.Label className="d-flex justify-content-start llbl">
                              <FormattedMessage id="uploadDocument" defaultMessage="Upload Your Document" />
                            </Form.Label>
                            <input
                              name="documents"
                              type="file"
                              className=""
                              id="upload-document"
                              accept="image/*,.pdf"
                              onChange={(e) => documentUpload(e.target.files[0])}
                              style={{ width: "100%" }}
                              ref={register({
                                required: 'Please upload your document',
                              })}
                            />
                            {errors.documents && (
                              <p className="text-danger oom p-0 m-0">
                                {errors.documents.message}
                              </p>
                            )}
                          </Form.Group>
                        </>
                      ) : ("")
                    }
                    {/* Document-name-section end */}
                    {/* Document-number-section start from here */}

                    {userDetailsById.verification_status == 'Not Initiated' ? '' :
                      <p>
                        <span>
                          {
                            userDetailsById.documents?.email_id && userDetailsById.documents?.email_id.doc_no.length
                              ? "Verification Email: " : "Document Number: "
                          }
                          {/* Document Number: */}
                        </span>
                        {
                          userDetailsById && userDetailsById.documents
                            ? userDetailsById.documents.crn.doc_no &&
                              userDetailsById.documents.crn.doc_no.length
                              ? userDetailsById.documents.crn.doc_no
                              : userDetailsById.documents.pan.doc_no &&
                                userDetailsById.documents.pan.doc_no.length
                                ? userDetailsById.documents.pan.doc_no
                                : userDetailsById.documents.cin.doc_no &&
                                  userDetailsById.documents.cin.doc_no.length
                                  ? userDetailsById.documents.cin.doc_no
                                  : userDetailsById.documents.gst.doc_no &&
                                    userDetailsById.documents.gst.doc_no.length
                                    ? userDetailsById.documents.gst.doc_no
                                    : userDetailsById.documents.aadhar_card.doc_no &&
                                      userDetailsById.documents.aadhar_card.doc_no.length
                                      ? userDetailsById.documents.aadhar_card.doc_no
                                      : userDetailsById.documents.food_license.doc_no &&
                                        userDetailsById.documents.food_license.doc_no.length
                                        ? userDetailsById.documents.food_license.doc_no
                                        : userDetailsById.documents.shop_est_act.doc_no &&
                                          userDetailsById.documents.shop_est_act.doc_no.length
                                          ? userDetailsById.documents.shop_est_act.doc_no
                                          : userDetailsById.documents.email_id.doc_no &&
                                            userDetailsById.documents.email_id.doc_no.length
                                            ? userDetailsById.documents.email_id.doc_no
                                            : "No Document Number Present"
                            : ""
                        }
                      </p>
                    }
                    {/* Document-number-section end */}
                  </div>
                ) : (
                  ""
                )}
                {/* Submit/Cancel Section start from here */}
                {editProfile === true ? (
                  <div className="employer_profile_btns mb-4">
                    <button type="submit" className="approvebtn">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="declinebtn"
                      onClick={handleCancelButton}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {/* Submit/Cancel Section end */}
                {/* approve/decline button section */}
                {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_UPDATE]) ?
                  _showHideApproveButton() : ''}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Employer profile section end */}
      {/* modal start */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Reason"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <form>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="decline"
                  name="decline"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Details Mismatch"
                    control={<Radio />}
                    label="Details Mismatch"
                  />
                  <FormControlLabel
                    value="Incorrect Document"
                    control={<Radio />}
                    label="Incorrect Document"
                  />
                  <FormControlLabel
                    value="Unclear Document"
                    control={<Radio />}
                    label="Unclear Document"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              <div class="form-group">
                <textarea
                  class="form-control"
                  id="otherreasontext"
                  onChange={handleOthersChange}
                  rows="3"
                  maxlength="100"
                  disabled={disabled ? true : false}
                ></textarea>
              </div>
              <button
                type="button"
                className="reasonbtn"
                onClick={() => {
                  updateStatus("Declined", value);
                }}
              >
                Done
              </button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal end */}
      {/* document modal */}
      <Dialog
        open={openDoc}
        onClose={handleClose}
        maxWidth="lg"
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent style={{ padding: "0px" }}>
          {get_url_extension(fileUrl) === "pdf" ? (
            <Pdf pdf={fileUrl} />
          ) : (
            <img src={fileUrl} alt="document" />
          )}
        </DialogContent>
      </Dialog>
      {/* Selecting-Organization-type-modal-section start from here*/}
      <Dialog
        open={showOrganizationModal}
        onClose={() => setOrganizationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"I am hiring staff for ?"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={() => setOrganizationModal(false)}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <form>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="decline"
                  name="company"
                  value={organizationTypeValue}
                  onChange={(event) => setOrganizationTypeValue(event.target.value)}
                >
                  <FormControlLabel
                    value="company"
                    control={<Radio />}
                    label="My Company"
                  />
                  <FormControlLabel
                    value="consultancy"
                    control={<Radio />}
                    label="My Client"
                  />
                </RadioGroup>
              </FormControl>
              <button
                type="button"
                className="reasonbtn mt-4"
                onClick={() => {
                  updateStatus("Approved", '', verificationType);
                }}
              >
                Submit
              </button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Selecting-Organization-type-modal-section end*/}
    </>
  );
};
