import person from "../../../assets/images/admin/person.svg";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action Toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { RiEdit2Fill } from "react-icons/ri";
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(
  companyname, // variable for company name
  regdate, // variable for registration date
  action, // variable use for action
  id, // variable use for employer id
  profile_pic, // variable use for employer profile
  email, // variable for email addess
  update_email_request // variable for email request
) {
  return {
    companyname,
    regdate,
    action,
    id,
    profile_pic,
    email, // variable for email addess
    update_email_request, // variable for email request
  };
}

// Employee Table Columns
const headCells = [
  // header for company name
  {
    id: "companyname",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  // header for registration date
  {
    id: "regdate",
    numeric: false,
    disablePadding: false,
    label: "Registration Date",
    align: "center",
  },
  // header for email address
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    align: "center",
  },
  // header for action
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Custom sort when click on individual column
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const RequestList = (props) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const classes = useStyles();
  const { userList } = useSelector((state) => state.adminReducer); // get user data
  const [verificationStatus, setStatus] = useState("All");
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 ); // storing page value
  const [table, setTable] = useState([]); // storing table data
  const [employerCount, setEmployerCount] = useState(0); // storing total employer count
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("companyname"); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const sortingIconClicked= useRef(false); // check sorting icon is clicked or unclicked
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setRows([]);
    async function fetchData(pageNumber) {
      const body = {
        update_email_request: "Open",
      };
      if (searched && searched.length) {
        body.search = searched;
      }
      if (sort) {
        body.sort = sort;
      }
      dispatch(adminActions.getEmployers(body, pageNumber ? pageNumber : page))
        .then((response) => {
          if (response && response.value.success) {
            setEmployerCount(response.value.data.count[0]?.count); //get and set total count
            setTableData(response.value.data.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchData(pageNumber); // api to fetching table data
  }, [dispatch, verificationStatus, page, sort, searched]);

  // set Table data method to update table values
  const setTableData = async (userList) => {
    let employerTable = [];
    if (userList && userList.length) {
      let tempArray = [...userList];
      for (let val of tempArray) {
        let doc_no = "";
        if (val.documents) {
          Object.entries(val.documents).forEach((item) => {
            // iterate over documents
            if (item[1].doc_no && item[1].doc_no.length) {
              doc_no = item[1].doc_no;
            }
          });
        }
        // form table data
        let data = createData(
          val.company_name ? val.company_name : "",
          new Date(val.created_at).toLocaleDateString(),
          val.verification_status,
          val._id,
          val.profile_pic,
          val.email, // storing email address value
          val.update_email_request // storing email request value
        );
        employerTable.push(data);
      }
    }
    setTable(employerTable);
    setRows(employerTable); // get table data
    setTable((state) => {
      return state;
    });
    return employerTable;
  };
  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/request-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/request-list', search: `?page=${pageNumber}&sort=${sort}` });
  };
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/request-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/request-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  return (
    <>
      {/* request-list-section start from here */}
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              {/* employer-total-count-section start from here */}
              <div className="table_menu_lft">
                <p>Employers Count : {employerCount}</p>
              </div>
              {/* employer-total-count-section end */}
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
                {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              <ReactTooltip />
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            {/* Company-name-section start from here */}
                            <TableCell component="th" id={labelId} scope="row">
                              <img
                                src={row.profile_pic}
                                alt="company-logo"
                                className="img-fluid company_logo"
                                id={labelId}
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = person;
                                }}
                              />
                              {row.companyname}
                            </TableCell>
                            {/* Company-name-section start from here */}
                            {/* Registration data */}
                            <TableCell align="center">{row.regdate}</TableCell>
                            {/* email-address-section start from here */}
                            <TableCell align="center">{row.email}</TableCell>
                            {/* email-address-section end */}
                            {/* edit-icon-section start from here */}
                            <TableCell align="right">
                              <Link to={`/email-request?id=${row.id}`}>
                                <span className="pencil-edit-icon">
                                  <RiEdit2Fill className="edit-mail-icon" />
                                </span>
                              </Link>
                            </TableCell>
                            {/* edit-icon-section end */}
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* pagination-section start from here */}
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button
                    type="button"
                    onClick={() => next(page + 1, sort)}
                    disabled={
                      userList && userList.data && userList.data.length === 30
                        ? false
                        : true
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
              {/* pagination-section end */}
            </div>
          </div>
        </div>
      </div>
      {/* request-list-section end */}
    </>
  );
};
