import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: "#999999",
  },
}));
export const Aboutself = ({ handelClose, aboutme, userID }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [aboutInput, setAboutInput] = useState(aboutme ? aboutme : []);

  const data = [
    { key: 0, label: "quick learner" },
    { key: 1, label: "hard working" },
    { key: 2, label: "focused" },
    { key: 3, label: "honest" },
    { key: 4, label: "team worker" },
    { key: 5, label: "disciplined" },
  ].filter(
    ({ key: id1 }) =>
      !aboutInput.some(({ key: id2 }) => id2 === id1)
  );
  const [chipData, setChipData] = useState(data);
  useEffect(async () => {
    let data = [
      { key: 0, label: "quick learner" },
      { key: 1, label: "hard working" },
      { key: 2, label: "focused" },
      { key: 3, label: "honest" },
      { key: 4, label: "team worker" },
      { key: 5, label: "disciplined" },
    ];
    let newChipData = await chipData.filter((val) => !aboutInput.includes(val));
    setChipData(newChipData);
  }, [aboutInput]);

  const handleClick = (e, data) => {
    setAboutInput([...aboutInput, data]);
  };

  const handleDelete = (chipToDelete) => () => {
    setAboutInput((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    setChipData([...chipData, chipToDelete]);
  };

  const update = async () => {
    try {
      let data = { about_me: aboutInput, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess('About Me Updated Successfully', 'Success');
      }
    } catch (error) {
      forError('Something Went Wrong','Failure');
      console.log(error)
    }
    handelClose();
  };

  return (
    <>
      <div className="conatiner">
        <div className="row">
          <div className="col-12">
            <div className="">
              <h3 className="about_oa"> I am </h3>
              {aboutInput ? (
                aboutInput.map((data) => {
                  return (
                    <Chip
                      key={data.key}
                      label={data.label}
                      onDelete={handleDelete(data)}
                      className={classes.chip}
                      variant="outlined"
                    />
                  );
                })
              ) : (
                <div />
              )}
              <div>
                <div className="">
                  {chipData.map((data) => {
                    return (
                      <Chip
                        key={data.key}
                        label={data.label}
                        onClick={(e) => handleClick(e, data)}
                        className={classes.chip}
                        variant="outlined"
                      />
                    );
                  })}
                </div>
                <div className="mt-4">
                  <button className="common_btn mt-4" onClick={update}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
