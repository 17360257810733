import search from "../../../assets/images/admin/search.svg";
import { FaUserPlus } from "react-icons/fa";
import { BiVideoPlus } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import ReactTooltip from "react-tooltip"; // React Tooltip
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { FaTimes, FaCheckCircle, FaTimesCircle, FaPlayCircle } from "react-icons/fa";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { regexValidation } from "utils/regexValidation";
import { Watch } from "@material-ui/icons";
import { VscHorizontalRule } from "react-icons/vsc";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(id, title, description, url, uploadedat, status) {
  return { id, title, description, url, uploadedat, status };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description', align: 'center' },
  { id: 'url', numeric: false, disablePadding: false, label: 'Video URL', align: 'center' },
  { id: 'uploadedat', numeric: false, disablePadding: false, label: 'Uploaded At', align: 'center' },
  { id: 'isactive', numeric: false, disablePadding: false, label: 'Status', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Play', align: 'right' },
];

export const CommunityList = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 ); 
  const context = useContext(Context);
  const history = useHistory();
  const classes = useStyles();
  const translate = useIntl();
  const { userList } = useSelector((state) => state.adminReducer);
  const [videoCount, setVideoCount] = useState(0);
  const [table, setTable] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const token = storage.get('humlog_authToken');
  const decodedToken = jwt_decode(token);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the saarthi Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [setCount, setTotalCount] = useState(0); // storing total count
  const sortingIconClicked = useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [error, setError] = useState({ open: false, message: "" });

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });
  const dispatch = useDispatch();

  async function fetchData(pageNumber) {
    let query = {};
    if (page >= 0) {
      query.page = pageNumber ? pageNumber : page;
    }
    if (searched && searched.length) {
      query.search = searched;
    }
    if (sort) {
      query.sort = sort;
    }
    dispatch(saarthiActions.getCommunityVideoList(query))
      .then((response) => {
        if (response && response.value.success) {
          setTableData(response.value.data);
          setVideoCount(response.value.data.length);
          // setTableCount(response.value.data.length);
          // setTotalCount(response.value.data.count); // get and set Total count
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    setTable([]);
    fetchData(pageNumber);
    ReactTooltip.rebuild();
  }, [dispatch, page, sort, searched]);

  const createSortHandler = (property) => (event) => {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  };

  /**
   * @description method Set Video list data in table 
   * @param {Array} videoList list of community
   * @returns mapped table data
   */
  const setTableData = async (videoList) => {
    let communityTable = [];
    if (videoList && videoList.length) {
      let tempArray = [...videoList]
      for (let val of tempArray) {
        let data = createData(
          val._id,
          val.title ? val.title : '',
          val.description ? val.description : '',
          val.video_url ? val.video_url : '',
          val.created_at ? val.created_at : '',
          val.is_active ? val.is_active : '',
        );
        communityTable.push(data);
      }
    }
    setTable(communityTable);
    setRows(communityTable); // get table data
    setTable((state) => {
      return state;
    })
    return communityTable;
  }

  // update page count
  const handleChange = (event, value) => {
    setPage(value - 1);
  };
  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/community-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/community-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // function for handling filter sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if (sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/community-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/community-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by uploaded at date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by uploaded at date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };

  /**
   * @description method to upload video
   * @param {Object} val values
   */
  const uploadVideo = (value) => {
    try {
      let data = {
        title: value.title,
        description: value.description,
        video_url: value.url,
      };
      dispatch(saarthiActions.addCommunityVideo(data))
        .then((res) => {
          if (res.value.success) {
            setSuccess({ open: true, message: "Video Added Successfully!" });
            closeAddVideoModal();
            fetchData();
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      setError({ open: true, message: "Error occured while adding video" })
      console.log(error);
    }
  };

  const [addVideo, setAddVideo] = useState(false); // variable use for open modal of add Video 
  // method to open add video modal
  const openAddVideoModal = () => {
    setAddVideo(true);
  };
  // method to close add video modal
  const closeAddVideoModal = () => {
    setAddVideo(false);
  };

  const [videoModal, setVideoModal] = useState({ open: false, type: "" }); // variable use for open modal of view Video
  // method to open view video modal
  const openVideoModal = (type) => {
    const VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    let url = type.match(VID_REGEX)[1];
    setVideoModal({ open: true, type: url });
  };
  // method to close view video modal
  const closeVideoModal = () => {
    setVideoModal({ open: false, type: "" });
  };
  return (
    <>
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Total Videos: {videoCount}</p>
              </div>
              <div className="table_menu_ryt">
                {decodedToken.sub_role === 0 ? (
                  <div className="add_saarthi" onClick={openAddVideoModal}>
                    <BiVideoPlus />
                    <div>
                      <p>Upload New Video</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* sort-icon-section */}
                {_handleSortIcon()}
                <div className="searchbox">
                  {/* searching-section start from here */}
                  <div className="table_menu_ryt">
                    <div className="searchbar_main">
                      <SearchBar
                        value={searched}
                        onRequestSearch={(searchVal) => handleSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                        className="searchbar"
                      />
                    </div>
                  </div>
                  {/* searching-section end */}
                </div>
                {/* Showing tooltip for ascending and descending icon */}
                <ReactTooltip effect="solid" />
              </div>
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.title}
                            </TableCell>
                            <TableCell align="center">
                              <span className="text_overflow" data-tip={row.description}>{row.description}</span>
                            </TableCell>
                            <TableCell align="center"><span className="text_overflow" data-tip={row.url}>{row.url}</span></TableCell>
                            <TableCell align="center">{new Date(row.uploadedat).toLocaleDateString()}</TableCell>
                            <TableCell align="center">
                              <span className="status_check">
                                {row.status == true ? (<FaCheckCircle className="active" />) : (<FaTimesCircle className="inactive" />)}
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              {/* <span className="editbtn">Edit</span> */}
                              <button type="button" className="playbtn" disabled={row.status == false ? true : false} onClick={() => {
                                openVideoModal(row.url);
                              }}>
                                <FaPlayCircle className={row.status == true ? "active" : "inactive"} />
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <ReactTooltip effect="solid" />
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button type="button" onClick={() => next(page + 1, sort)} disabled={tableCount === 30 ? false : true}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={addVideo}
        onClose={closeAddVideoModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          Add Video Details
        </DialogTitle>
        <button className="modal-cross-btn" onClick={closeAddVideoModal}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <div className="add_video_box">
            <form onSubmit={handleSubmit(uploadVideo)}>
              <div className="form-group">
                <label htmlFor="title">Title <font className="text-danger">*</font></label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  autoComplete="off"
                  ref={register({
                    required: "Please enter Title of Video",
                    minLength: {
                      value: 4,
                      message: "Please Enter Minimum 4 Character"
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 Character limit",
                    },
                  })}
                />
                {errors.title && (
                  <span className="text-danger txt">
                    {errors.title.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="url">URL <font className="text-danger">*</font></label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  name="url"
                  autoComplete="off"
                  ref={register({
                    required: "Please enter the URl of video",
                    pattern: {
                      value: regexValidation.YOUTUBE,
                      message: "Please Enter valid youtube URl",
                    },
                  })}
                />
                {errors.url && (
                  <span className="text-danger txt">
                    {errors.url.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  autoComplete="off"
                  ref={register({
                    maxLength: {
                      value: 200,
                      message: "maximum 200 character allowed sdda",
                    },
                  })}
                ></textarea>
                {errors.description && (
                  <span className="text-danger txt">
                    {errors.description.message}
                  </span>
                )}
              </div>
              <button type="submit" className="reasonbtn">Submit</button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={videoModal.open}
        onClose={closeVideoModal}
        maxWidth="sm"
        fullWidth={true}
      >
        <button className="close_btn" onClick={closeVideoModal}><FaTimes /></button>
        <DialogContent style={{ padding: "0px" }}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube-nocookie.com/embed/${videoModal.type}?autoplay=0&modestbranding=1&cc_load_policy=1&iv_load_policy=3&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
};
