import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { useIntl } from "react-intl";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const Certificationform = ({ handelClose, certification, userID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, reset, errors } = useForm({});
  // set branch
  const [branch, setBranch] = useState(
    certification && certification[0] && certification[0].branch
      ? certification[0].branch
      : ""
  );
  // set certificate
  const [cert, setCert] = useState(
    certification &&
      certification[0] &&
      certification[0].cert &&
      !(certification[0].cert === "other")
      ? certification[0].cert
      : ""
  );
  // set other certificate if available
  const [otherCert, setOtherCert] = useState(
    certification &&
      certification[0] &&
      ["ITI", "Diploma", "B.tech/B.E"].includes(certification[0].cert)
      ? false
      : true
  );
  const [other, setOther] = useState(false);
  const iti_branchList = ["Plumber", "Electrician", "Fitter", "Other"];
  const branchList = ["Electrical", "Civil", "Mechanical", "Other"];

  /**
   * @description call loadProfile function first
   */
  useEffect(() => {
    loadProfile();
  }, [cert]);
  /**
   * @description method to loadProfile
   */
  const loadProfile = () => {
    let data = {
      technical_cert: certification[0].cert
    };
    reset(data);
  };
  const setCertValue = (val) => {
    if (val === "other") {
      setOtherCert(true);
      setCert("");
      return;
    }
    setCert(val);
    setBranch("");
    setOtherCert(false);
  };

  const setBranchValue = (val) => {
    if (val === "Other") {
      setOther(!other);
      setBranch(val);
      return;
    }
    setBranch(val);
    setOther(false);
  };

  /**
   * @description method to update technical certificate
   */
  const update = async (val) => {
    try {
      const technical_cert = [{ cert: cert, branch: branch }];
      const dataval = {
        technical_cert: technical_cert,
      };
      let data = { ...dataval, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess("Technical Certificatie Updated Successfully", "Success");
      }
    } catch (error) {
      forError("Something Went Wrong", "Failure");
      console.log(error);
    }
    reset();
    handelClose();
  };
  const intl = useIntl();
  return (
    <>
      <div className="sup_pp">
        <div className="row">
          <div className="col-12">
            <h2 className="tttle prnl_head_aa">
              <FormattedMessage
                id="technicalCertification"
                defaultMessage="Technical Certification"
              />
            </h2>
            <div className="mt-2">
              <Form onSubmit={handleSubmit(update)}>
                <div className="d-flex justify-content-between mt-2">
                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      value="ITI"
                      id="ITI"
                      checked={"ITI" === cert}
                      ref={register}
                      readOnly
                      onChange={(e) => setCertValue(e.target.value)}
                    />
                    <label
                      className="form-check-label text-capitalize lw cert"
                      htmlFor="ITI"
                    >
                      ITI
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      id="Diploma"
                      value="Diploma"
                      checked={"Diploma" === cert}
                      ref={register}
                      readOnly
                      onChange={(e) => setCertValue(e.target.value)}
                    />
                    <label
                      className="form-check-label text-capitalize lw cert"
                      htmlFor="Diploma"
                    >
                      Diploma
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      id="B.tech/B.E"
                      ref={register}
                      value="B.tech/B.E"
                      checked={cert === "B.tech/B.E"}
                      readOnly
                      onChange={(e) => setCertValue(e.target.value)}
                    />
                    <label
                      className="form-check-label text-capitalize lw cert"
                      htmlFor="B.tech/B.E"
                    >
                      B.tech/B.E
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      id="other"
                      name="technical_cert"
                      value="other"
                      checked={!["ITI", "Diploma", "B.tech/B.E"].includes(cert)}
                      ref={register}
                      readOnly
                      onChange={(e) => setCertValue(e.target.value)}
                    />
                    <label
                      className="form-check-label text-capitalize w cert"
                      htmlFor="other"
                    >
                      <p className="grad"> Other </p>
                    </label>
                  </div>
                </div>

                {otherCert ? (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mt-3 mb-2">
                      <FormattedMessage
                        id="OtherCertification"
                        defaultMessage="Other Certification"
                      />
                      <font className="text-danger ml-1"></font>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Not available / Add Technical Certification"
                      name="othercertification"
                      value={cert}
                      onChange={(e) => {
                        setCert(e.target.value);
                        setBranch("");
                      }}
                      className={classNames(
                        "form-control other_certificate_form"
                      )}
                      ref={register({
                        required: "Please enter Certification",
                        maxLength: {
                          value: 25,
                          message: "maximum 25 characters allowed",
                        },
                        pattern: {
                          value: /^[a-zA-z\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    <p className="text-danger oom p-0 mt-2">
                      <FormattedMessage
                        id="certificationhint"
                        defaultMessage="*Please add your certification or mention 'Not available' if no certification available"
                      />
                    </p>
                    {errors.othercertification && (
                      <p className="text-danger oom p-0 m-0">
                        {errors.othercertification.message}
                      </p>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mt-3 mb-2">
                      <FormattedMessage
                        id="CertificateDiscipline"
                        defaultMessage="Discipline"
                      />{" "}
                      <font className="text-danger ml-1"></font>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      autoComplete="off"
                      name="discipline"
                      ref={register}
                      value={branch}
                      onChange={(e) => setBranchValue(e.target.value)}
                      className="other_certificate_form"
                    >
                      <option value="">Select Branch</option>
                      {cert === "ITI"
                        ? iti_branchList.map((val) => {
                            return (
                              <>
                                <option value={val}> {val}</option>
                              </>
                            );
                          })
                        : branchList.map((val) => {
                            return (
                              <>
                                <option value={val}> {val}</option>
                              </>
                            );
                          })}
                    </Form.Control>

                    {errors.discipline && (
                      <p className="text-danger  oom p-0 m-0">
                        {errors.discipline.message}
                      </p>
                    )}
                  </Form.Group>
                )}
                {other && (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mb-2">
                      <FormattedMessage
                        id="OtherDiscipline"
                        defaultMessage="Other Branch"
                      />{" "}
                      <font className="text-danger ml-1"></font>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Add your Branch"
                      name="otherdiscipline"
                      onChange={(e) => setBranch(e.target.value)}
                      className={classNames("form-control")}
                      ref={register({
                        maxLength: {
                          value: 25,
                          message: "maximum 25 characters allowed",
                        },
                        pattern: {
                          value: /^[a-zA-z\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    {errors.otherdiscipline && (
                      <p className="text-danger  oom p-0 m-0">
                        {errors.otherdiscipline.message}
                      </p>
                    )}
                  </Form.Group>
                )}

                <div className="mt-4">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
