import person from "../../../assets/images/admin/person.svg";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Player } from '../../common/player';
import { FaTimes } from "react-icons/fa";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaRegFileAudio } from "react-icons/fa";
import { FaRegFileVideo } from "react-icons/fa";
import Alert from "@material-ui/lab/Alert";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action Toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip
import * as saarthiActions from "redux/actions/SaarthiActions";
import { FaShareAlt, FaEnvelope } from "react-icons/fa";
import { FacebookShareButton, TwitterShareButton, EmailShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { CONFIG_URL } from '../../common/app.constant';

// these code will be change after API integration
const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(employername, jobpostingdate, jobposition, district, salary, audiojd, videojd, jobstatus, id,jobId, companylogo, jobShare, action) {
  return { employername, jobpostingdate, jobposition, district, salary, audiojd, videojd, jobstatus, id, jobId, companylogo, jobShare, action};
}

const rows = [
  createData('Humlogjobs', '', 'Data Entry Operator', 'Delhi', '25000', '', 'Approved'),
  createData('Humlogjobs2', '', 'Data Entry Operator', 'Delhi', '25000', '', 'InProgress'),
  createData('Humlogjobs3', '', 'Data Entry Operator', 'Delhi', '25000', '', 'Approved'),
  createData('Humlogjobs4', '', 'Data Entry Operator', 'Delhi', '25000', '', 'InProgress'),
  createData('Humlogjobs5', '', 'Data Entry Operator', 'Delhi', '25000', '', 'Approved'),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Job-description Table Columns
const headCells = [
  { id: 'employername', numeric: false, disablePadding: false, label: 'Company' },
  { id: 'jobpostingdate', numeric: false, disablePadding: false, label: 'Job Posting Date', align: 'center' },
  { id: 'jobposition', numeric: false, disablePadding: false, label: 'Job Post Details', align: 'center' },
  { id: 'audiovideojd', numeric: false, disablePadding: false, label: 'Audio / Video JD', align: 'center' },
  { id: 'jobstatus', numeric: false, disablePadding: false, label: 'Job Status', align: 'center' },
  { id: 'jobShare', numeric: false, disablePadding: false, label: 'Job Share', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

export const JobsList = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 );
  const history = useHistory();
  const classes = useStyles();
  const now = moment().format("YYYY-MM-DD");
  const { jobList } = useSelector((state) => state.saarthiReducer);
  const [jobStatus, setStatus] = useState("All");
  const [table, setTable] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [modal, setModal] = useState({ open: false, type: "" });
  const [deactive, setDeactive] = useState({ isOpen: false, id: "" });
  const [updateTable, setUpdateTable] = useState(false);
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [jobCount, setJobCount] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [setCount, setTotalCount] = useState(0); // storing total count
  const sortingIconClicked= useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const [tableCount, setTableCount] =useState(0); // storing table count
  const [jobShareId, setJobShareValues] = useState({ isOpen: false, id: "" }); // storing job share Id
  const [copyUrl, setCopyUrl] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(pageNumber);
  }, [dispatch, jobStatus, from, to, page, updateTable, sort, searched]);

  async function fetchData(pageNumber) {
    setRows([]);
    // if To date is smaller than From date
    if (to <= from) {
      window.alert("to date cannot be smaller than from date");
      return;
    }
    let body;
    if (from && to) {
      if (jobStatus === "All") {
        body = {
          time_filter: {
            from: from,
            to: to,
          },
        };
      } else {
        body = {
          status: parseInt(jobStatus),
          time_filter: {
            from: from,
            to: to,
          },
        };
      }
    } else {
      if (jobStatus === "All") {
        body = {};
      } else {
        body = {
          status: parseInt(jobStatus),
        };
      }
    }
    if (searched && searched.length) {
      body.search = searched;
    }
    if (sort) {
      body.sort = sort;
    }
    dispatch(saarthiActions.getSaarthiJobs(body, pageNumber ? pageNumber : page))
      .then((response) => {
        if (response && response.value.success) {
          setJobCount((prev) => response.value.data.count);
          setTotalCount((prev) => response.value.data.count); // get total count
          setTableData(response.value.data.data);
          setTableCount(response.value.data.data.length); // get and set table count
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // set table values method
  const setTableData = async (jobList) => {
    let jobTable = [];
    if (jobList && jobList.length) {
      let tempArray = [...jobList];
      for (let val of tempArray) {
        // form table data
        let data = createData(
          val.company_name,
          new Date(val.created_at).toLocaleDateString(),
          val.job_title,
          val.district, //Storing Job district
          val.salary_to,
          val.audio_description,
          val.animated_description,
          val.status,
          val._id,
          val.job_id,
          val.company_logo,
        );
        jobTable.push(data);
      }
    }
    setTable(jobTable);
    setRows(jobTable); // get table data
    setTable((state) => {
      return state;
    });
    return jobTable;
  };

  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/jobs-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/jobs-list', search: `?page=${pageNumber}&sort=${sort}` });
  };
  // method for handling modal
  const onToggleModal = (type) => {
    setModal({ open: !modal.open, type: type });
  };
  // open deactivate dialog
  const openDeactive = (id) => {
    setDeactive({ isOpen: true, id: id });
  };
  // Close deactivate dialog
  const closeDeactive = () => {
    setDeactive({ isOpen: false });
  };
  // Open Job Share Modal
  const jobShareModal = (id) => {
    setJobShareValues({ isOpen: true, id: id });
  };
  // Close Job Share Modal
  const shareCloseDeactive = () => {
    setJobShareValues({ isOpen: false });
    setCopyUrl(false);
  };
  /*
   * deactive job API calling
   * deactive job function
   */
  const deactivateJob = (id) => {
    let body = {
      status: 4,
    };
    dispatch(saarthiActions.saarthiUpdateJob(body, id))
      .then((response) => {
        if (response && response.value.success) {
          setSuccess({ open: true, message: `Job Deactivated Successfully` });
          setUpdateTable(!updateTable);
        }
        setTimeout(() => {
          setSuccess({ open: false, message: "" });
        }, 3000);
      })
      .catch((error) => {
        console.log("error", error);
      });
    setDeactive({ isOpen: false });
  };
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/jobs-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/jobs-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by job posting date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by job posting date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  // Handling job copy url
  const _handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url);
    setCopyUrl(true);
  }
  return (
    <>
      {success.open && (
        <Alert severity="success" className="alert-toast">
          {success.message}
        </Alert>
      )}
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Jobs Count : {jobCount}</p>
                <div className="timefilterdropdown">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    onClick={(e) => setStatus(e.target.value)}
                  >
                    <option value="All">All Jobs</option>
                    <option value="0">Active</option>
                    <option value="3">Pending</option>
                    <option value="4">Inactive</option>
                  </select>
                </div>
                <p> From </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="from"
                    placeholder="From"
                    onChange={(e) => setFrom(Date.parse(e.target.value))}
                  />
                </div>
                <p> To </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="to"
                    placeholder="To"
                    onChange={(e) => {
                      let date = new Date(e.target.value);
                      date.setDate(date.getDate() + 1);
                      setTo(Date.parse(date));
                    }}
                  />
                </div>
              </div>
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
              {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              <ReactTooltip />
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                              <img
                                src={row.companylogo}
                                alt="company-logo"
                                className="img-fluid company_logo"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = person;
                                }}
                              />
                              {row.employername}
                            </TableCell>
                            <TableCell align="center">
                              {row.jobpostingdate}
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.jobposition}</p>
                              <p>
                                {row.district}, Rs.{row.salary}
                              </p>
                              <Link to={`/jobs-list-specific?id=${row.id}`}>
                                View More
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              <button
                                type="button"
                                className="audio_video_icon"
                                onClick={() => {
                                  onToggleModal(row.audiojd);
                                }}
                                disabled={row.audiojd ? false : true}
                              >
                                <FaRegFileAudio />
                              </button>
                              <button
                                type="button"
                                className="audio_video_icon"
                                onClick={() => {
                                  onToggleModal(row.videojd);
                                }}
                                disabled={row.videojd ? false : true}
                              >
                                <FaRegFileVideo />
                              </button>
                            </TableCell>
                            <Player
                              open={modal.open}
                              toggleModal={onToggleModal}
                              src={modal.type}
                            />
                            <TableCell align="center">
                              <span
                                className={
                                  row.jobstatus === 0
                                    ? "active"
                                    : row.jobstatus === 1
                                    ? "complete"
                                    : row.jobstatus === 2
                                    ? "closed"
                                    : row.jobstatus === 4
                                    ? "deactive"
                                    : "pending"
                                }
                              >
                                {row.jobstatus === 0
                                  ? "Active"
                                  : row.jobstatus === 1
                                  ? "Complete"
                                  : row.jobstatus === 2
                                  ? "Closed"
                                  : row.jobstatus === 4
                                  ? "Inactive"
                                  : "Pending"}
                              </span>
                            </TableCell>
                            {/* Job-sharing-Icon-section start from here */}
                            <TableCell align="center">
                              <FaShareAlt size="22" className="share-icon" onClick={() => {
                                jobShareModal(row.jobId);
                              }} />
                            </TableCell>
                            {/* Job-sharing-Icon-section end */}
                            <TableCell align="right">
                              <button
                                type="button"
                                onClick={() => {
                                  openDeactive(row.id);
                                }}
                                disabled={row.jobstatus !== 0 ? true : false}
                              >
                                Deactivate
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button type="button" onClick={() => next(page + 1, sort)} disabled={tableCount === 30 ? false : true}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* document dialog box */}
      <Dialog
        open={deactive.isOpen}
        onClose={closeDeactive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal deactivate_modal"
      >
        <button className="modal-cross-btn" onClick={closeDeactive}>
          <FaTimes />
        </button>
        <DialogContent style={{ padding: "0px" }}>
          <p>Are you sure you want to deactivate the job post ?</p>
          <div className="deactivate_profile_btns">
            <button type="button" className="no" onClick={closeDeactive}>
              NO
            </button>
            <button
              type="button"
              className="yes"
              onClick={() => {
                deactivateJob(deactive.id);
              }}
            >
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    {/* Job-sharing modals box start from here */}
    <Dialog
        open={jobShareId.isOpen}
        onClose={shareCloseDeactive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="sharing-modal deactivate_modal"
      >
        {/* Close-button-section*/}
        <button className="modal-cross-btn" onClick={shareCloseDeactive}>
          <FaTimes />
        </button>
        <DialogContent>
          <p className="text-center"> Share Your Details via</p>
          {/* copy-url-section start from here */}
          <div class="copy-link-inner">
            <input type="text" value={`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`} data-click-select-all className="copy-link-input" />
            <input type="submit" value={copyUrl ? "Copied" : "Copy"} className="copy-link-button" onClick={() => _handleCopyUrl(`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`)}/>
          </div>
          {/* copy-url-section end */}
          <div className="job-sharing-btns">
            <div>
              <ul>
                <li className="share-bottom">
                  <ul id="shareList" className="container-sharing-icons">
                    <li>
                      <FacebookShareButton
                        className='fb_button'
                        url={`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`}
                        quote={'Apply for Jobs & Get Interview Calls. All the best.'}>
                        <FontAwesomeIcon className='icon fb_icon' icon={faFacebookF} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton
                        className='twitter_button'
                        url={`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`}
                        title={'Apply for Jobs & Get Interview Calls. All the best.'}>
                        <FontAwesomeIcon className='icon twitter_icon' icon={faTwitter} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton
                        className='linkedin_button'
                        url={`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`}
                      >
                        <FontAwesomeIcon className='icon linkedin_icon' icon={faLinkedinIn} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <EmailShareButton
                        className='email_button'
                        subject={`Check out this fantastic job`}
                        body={`${'Apply for Jobs & Get Interview Calls. All the best.'}: ${`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`}`}>
                        <FaEnvelope className='icon email_icon' />
                      </EmailShareButton>
                    </li>
                    <li>
                      <WhatsappShareButton
                        className='whatsapp_button'
                        url={`${CONFIG_URL()}/shared/job?id=${jobShareId.id}`}
                        title={'Apply for Jobs & Get Interview Calls. All the best.'}
                        separator=" ">
                        <FontAwesomeIcon className='icon whatsapp_icon' icon={faWhatsapp} />
                      </WhatsappShareButton>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Job-sharing modals box end */}
    </>
  );
};
