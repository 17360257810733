module.exports = {
    'SUBSCRIPTION': {
        employer_plan: {
            'Gold': {
                name: 'Gold',
                plan_code: 'HLJ-EMP-101',
            },
            'Diamond': {
                name: 'Diamond',
                plan_code: 'HLJ-EMP-102'
            },
            'Platinum': {
                name: 'Platinum',
                plan_code: 'HLJ-EMP-103'
            }
        }
    },
    'PLANS': {
        get_employer_plan: {
            'HLJ-EMP-101': {
                name: 'Gold',
            },
            'HLJ-EMP-102': {
                name: 'Diamond'
            },
            'HLJ-EMP-103': {
                name: 'Platinum'
            }
        }
    }
}