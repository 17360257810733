import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    // ** Table header section start from here ** //
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => (
          <TableCell
            align={headCell.align}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
              {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    // ** Table header section end ** //
  );
}
export default EnhancedTableHead;
