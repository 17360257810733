import moment from "moment";
import person from "../../../assets/images/admin/person.svg";
import { FaTimes } from "react-icons/fa";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import config from "../../../config/index";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip
import * as commonService from "utils/CommonService.js";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import { BiRefresh } from "react-icons/bi"; // Refresh Icon

const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

// table header and values variables
function createData(employerid, companyname, regdate, authorized_at, total_jobs, active_jobs, empdetails, subscription, action, profile_pic, planstatus, jobPostUsage) {
  return {
    employerid, companyname, regdate, authorized_at, total_jobs, active_jobs, empdetails, subscription, action, profile_pic, planstatus, jobPostUsage
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Plans Table Columns
const headCells = [
  { id: 'companyname', numeric: false, disablePadding: false, label: 'Company', align: 'left' },
  { id: 'regdate', numeric: false, disablePadding: false, label: 'Registration Date', align: 'center' },
  { id: 'authorized_at', numeric: false, disablePadding: false, label: 'Authentication Date', align: 'center' },
  { id: 'total_jobs', numeric: true, disablePadding: false, label: 'Total Jobs', align: 'center' },
  { id: 'active_jobs', numeric: true, disablePadding: false, label: 'Active Jobs', align: 'center' },
  { id: 'empdetails', numeric: false, disablePadding: false, label: 'Employer Details', align: 'center' },
  { id: 'subscription', numeric: false, disablePadding: false, label: 'Subscription Plan', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'center' },
  { id: 'planstatus', numeric: false, disablePadding: false, label: 'Status', align: 'right' },
];

export const Plans = (props) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const context = useContext(Context);
  const now = moment().format("YYYY-MM-DD");
  const classes = useStyles();
  const { userList } = useSelector((state) => state.adminReducer);
  const [paid, setplan] = useState("paid");
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 );
  const [table, setTable] = useState([]);
  const [openDoc, setOpenDoc] = useState(false);
  const [employerID, setemployerID] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [jobCount, setJobCount] = useState(0); // storing job total count
  const sortingIconClicked= useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage
  const [checkPlanModal, setCheckPlanModal] = useState(false);
  const [checkPlanErrorMessage, setCheckPlanErrorMessage] = useState(''); // storing check plan availability error messages
  const [choosePlanErrorMessage, setChoosePlanErrorMessage] = useState(''); // storing choose plan error messages

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Open dialog
  const handleOpen = (empID) => {
    setOpenDoc(true);
    setemployerID(empID);
  };

  // Close dialog
  const handleClose = () => {
    setOpenDoc(false);
    setChoosePlanErrorMessage('');
  };
  // Employer API Call
  const dispatch = useDispatch();
  useEffect(() => {
    setRows([]);
    async function fetchData(pageNumber) {
      let body = {
        verification_status: 'Approved'
      };
      if (searched && searched.length) {
        body.search = searched;
      }
      if (sort) {
        body.sort = sort;
      }
      dispatch(adminActions.getEmployers(body, pageNumber ? pageNumber : 0))
        .then((response) => {
          if (response && response.value.success) {
            setTableData(response.value.data.data);
            setJobCount(response.value.data.count[0]?.count); // get plans count
          }
        })
        .catch((error) => {
          forError("Something went wrong!", "Failure!");
          console.log("error", error);
        });
    }
    fetchData(pageNumber); // api to fetching table data
  }, [dispatch, page, updateTable, sort, searched]);

  /**
   * @description method to set Table data
   * @param {object} userList userlist details
   * @returns
   */
  const setTableData = async (userList) => {
    let employerTable = [];
    if (userList && userList.length) {
      let tempArray = [...userList];
      for (let val of tempArray) {
        let data = createData(
          val._id,
          val.company_name ? val.company_name : "",
          new Date(val.created_at).toLocaleDateString(),
          val.authorized_at ? new Date(val.authorized_at).toLocaleDateString() : '',
          val.total_jobs, // total job counts of employer
          val.active_jobs, // active job counts of employer
          "",
          val.hasOwnProperty("subscription_data")
            ? val.subscription_data.plan_code
            : "",
          '',
          val.profile_pic,
          val.hasOwnProperty("subscription_data")
            ? val.subscription_data.payment_status
            : "",
          val.hasOwnProperty("subscription_data")
          ? val.subscription_data.job_post_usage
          : "",
        );
        employerTable.push(data);
      }
    }
    setTable(employerTable);
    setRows(employerTable); // get table data
    setTable((state) => {
      return state;
    });
    return employerTable;
  };
  // go to previous page
   const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/plans', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/plans', search: `?page=${pageNumber}&sort=${sort}` });
  };

  /**
   * @description method to fetch the value of plan from dialogbox
   */
  const [planValue, setPlanValue] = React.useState("Gold");
  const changePlan = (event) => {
    setPlanValue(event.target.value);
  };
  /**
   * @description method to update plans for employers
   * @param {string} planName planname (ex. gold, diamond, platinum)
   */
  const purchasePlan = async (planName) => {
    try {
      let body = {
        user_id: employerID,
        plan_code: config["SUBSCRIPTION"].employer_plan[planName].plan_code,
        payment_status: 'free'
      };
      let res = await dispatch(adminActions.purchaseSubscriptionPlan(body));
      if (res.value.success) {
        setUpdateTable(!updateTable);
        setOpenDoc(false);
        forSuccess("Plan updated successfully!", "Success!");
      } else {
        forError("Plan update failed!", "Failure!");
      }
    } catch (error) {
      forError("Something went wrong!", "Failure!");
      setChoosePlanErrorMessage(error.data.message);
    }
  };

  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/plans', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/plans', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  // API to check plan subscription
  const _handleCheckPlanAvailability = async (userId) => {
    try {
      let data = {
        user_id: userId
      };
      let response = await dispatch(adminActions.checkSubscriptionPlan(data));
      if (response.value.success) {
        setCheckPlanErrorMessage(response.value.message);
        setCheckPlanModal(true);
      }
    } catch (error) {
      setCheckPlanErrorMessage(error.data.message);
      setCheckPlanModal(true);
    }
  }
  return (
    <>
      <div
        className={
          props.isActiveClass ? "reg_main slideright" : "reg_main slidenormal"
        }
      >
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
              <p>Jobs Count : {jobCount}</p>
              </div>
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
              {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              <ReactTooltip />
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (rows, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={rows.employerid}>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="rows"
                              align="left"
                            >
                              <img
                                src={rows.profile_pic}
                                alt="company-logo"
                                className="img-fluid company_logo"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = person;
                                }}
                              />
                              {rows.companyname}
                            </TableCell>
                            <TableCell align="center">{rows.regdate}</TableCell>
                            <TableCell align="center">{rows.authorized_at}</TableCell>
                            <TableCell align="center">{rows.total_jobs}</TableCell>
                            <TableCell align="center">{rows.active_jobs}</TableCell>
                            <TableCell align="center">
                            <Link to={{pathname: `/employer-profile`, search:`?emp_id=${rows.employerid}`, state: 'plans'}}>View Details</Link>
                            </TableCell>
                            <TableCell align="center">
                              <span className="gold">
                                {!rows.subscription ? (
                                  <button
                                    type="button"
                                    className={commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? '' : 'not-clickable'}
                                    onClick={() => commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? handleOpen(rows.employerid) : ''}
                                  >
                                    Choose Plan
                                  </button>
                                ) : config["PLANS"].get_employer_plan[
                                    rows.subscription
                                  ] ? (
                                      rows.jobPostUsage === 0 ? ( <button
                                      type="button"
                                      className={commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? '' : 'not-clickable'}
                                      onClick={() => commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? handleOpen(rows.employerid) : ''}
                                    ><p className="consumed-text">{`${config["PLANS"].get_employer_plan[
                                      rows.subscription
                                      ].name} Consumed`}</p>
                                      Choose Plan
                                    </button>)
                                    : (config["PLANS"].get_employer_plan[
                                        rows.subscription
                                        ].name
                                      )
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </TableCell>
                            {/* Action-section start from here */}
                            <TableCell align="center">
                              <span>
                                <BiRefresh size={30} className={commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? 'refresh-icon' : 'refresh-icon not-clickable'} data-tip="Check Plan Availability" onClick={() => commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLANS_UPDATE]) ? _handleCheckPlanAvailability(rows.employerid) : ''} />
                              </span>
                              <ReactTooltip />
                            </TableCell>
                            {/* Action-section end */}
                            <TableCell align="right">
                              <span
                                className={
                                  rows.planstatus === "paid"
                                    ? "paid plan_status"
                                    : rows.planstatus === "free"
                                    ? "free plan_status"
                                    : "default"
                                }
                              >
                                {rows.planstatus ? rows.planstatus : "N/A"}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button
                    type="button"
                    onClick={() => next(page + 1, sort)}
                    disabled={
                      userList && userList.data && userList.data.length === 30
                        ? false
                        : true
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* plan dialog box */}
      <Dialog
        open={openDoc}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal plan_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Choose Plan"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <form>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="plan"
                  name="plan"
                  value={planValue}
                  onChange={changePlan}
                >
                  <FormControlLabel
                    value="Gold"
                    control={<Radio />}
                    label="Gold"
                  />
                  <FormControlLabel
                    value="Diamond"
                    control={<Radio />}
                    label="Diamond"
                  />
                  <FormControlLabel
                    value="Platinum"
                    control={<Radio />}
                    label="Platinum"
                  />
                </RadioGroup>
              </FormControl>
              <button
                type="button"
                className="reasonbtn"
                onClick={() => purchasePlan(planValue)}
              >
                Activate
              </button>
              {choosePlanErrorMessage ? (<p className="purchase-error-text">Note: {choosePlanErrorMessage}</p>) : ''} 
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* check plan availability dialog start from here */}
      <Dialog
        open={checkPlanModal}
        onClose={() => setCheckPlanModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal plan_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {""}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <p>{checkPlanErrorMessage}</p>
            <button
                type="button"
                className="reasonbtn mt-4"
                onClick={() => setCheckPlanModal(false)}
              >
                OK
              </button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* check plan availability dialog end */}
    </>
  );
};
