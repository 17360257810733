export const AdminActionTypes = {
    CREATE_EMPLOYERS : "CREATE_EMPLOYERS",
    CREATE_EMPLOYERS_PENDING : "CREATE_EMPLOYERS_PENDING",
    CREATE_EMPLOYERS_FULFILLED : "CREATE_EMPLOYERS_FULFILLED",
    GET_EMPLOYERS: "GET_EMPLOYERS",
    GET_EMPLOYERS_PENDING: "GET_EMPLOYERS_PENDING",
    GET_EMPLOYERS_FULFILLED: "GET_EMPLOYERS_FULFILLED",
    UPDATE_USERS: "UPDATE_USERS",
    GET_JOBS: "GET_JOBS",
    GET_JOBS_PENDING:"GET_JOBS_PENDING",
    GET_JOBS_FULFILLED: "GET_JOBS_FULFILLED",
    UPDATE_JOBS: "UPDATE_JOBS",
    GET_JOB_DETAILS: "GET_JOB_DETAILS",
    GET_JOB_DETAILS_PENDING: "GET_JOB_DETAILS_PENDING",
    GET_JOB_DETAILS_FULFILLED: "GET_JOB_DETAILS_FULFILLED",
    POST_SUBSCRIPTION_PLAN: "POST_SUBSCRIPTION_PLAN",
    RESET_SAARTHI_PASSWORD: "RESET_SAARTHI_PASSWORD",
    UPDATE_SAARTHI_PASSWORD: "UPDATE_SAARTHI_PASSWORD",
    GET_USERS_COUNT: "GET_USERS_COUNT",
    DOWNLOAD_EMPLOYER_DATA: "GET_DOWNLOAD_EMPLOYER_DATA",
    DOWNLOAD_EMPLOYER_DATA_PENDING: "GET_DOWNLOAD_EMPLOYER_DATA",
    DOWNLOAD_EMPLOYER_DATA_FULFILLED: "GET_DOWNLOAD_EMPLOYER_DATA",
    GET_APPLIED_JOBSEEKER_LIST: "GET_APPLIED_JOBSEEKER_LIST",
    GET_APPLIED_JOBSEEKER_LIST_PENDING: "GET_APPLIED_JOBSEEKER_LIST_PENDING",
    GET_APPLIED_JOBSEEKER_LIST_FULFILLED: "GET_APPLIED_JOBSEEKER_LIST_FULFILLED",
    DOWNLOAD_JOB_DATA:"GET_DOWNLOAD_JOB_DATA",
    DOWNLOAD_JOB_DATA_PENDING:"GET_DOWNLOAD_JOB_DATA",
    DOWNLOAD_JOB_DATA_FULFILLED:"GET_DOWNLOAD_JOB_DATA",
    GET_ADMIN_PERMISSION: "GET_ADMIN_PERMISSION_LIST",
    GET_ADMIN_PERMISSION_PENDING: "GET_ADMIN_PERMISSION_PENDING",
    GET_ADMIN_PERMISSION_FULFILLED: "GET_ADMIN_PERMISSION_FULFILLED",
    POST_ADMIN_ROLE: "POST_ADMIN_ROLE_LIST",
    POST_ADMIN_ROLE_PENDING: "POST_ADMIN_ROLE_PENDING",
    POST_ADMIN_ROLE_FULFILLED: "POST_ADMIN_ROLE_FULFILLED",
    GET_ADMIN_ROLE: "GET_ADMIN_ROLE_LIST",
    GET_ADMIN_ROLE_PENDING: "GET_ADMIN_ROLE_PENDING",
    GET_ADMIN_ROLE_FULFILLED: "GET_ADMIN_ROLE_FULFILLED",
    POST_HLJ_EMPLOYER: "POST_HLJ_EMPLOYER",
    POST_HLJ_EMPLOYER_PENDING: "POST_HLJ_EMPLOYER_PENDING",
    POST_HLJ_EMPLOYER_FULFILLED: "POST_HLJ_EMPLOYER_FULFILLED",
    UPDATE_ADMIN_ROLE: "UPDATE_ADMIN_ROLE_LIST",
    UPDATE_ADMIN_ROLE_PENDING: "UPDATE_ADMIN_ROLE_PENDING",
    UPDATE_ADMIN_ROLE_FULFILLED: "UPDATE_ADMIN_ROLE_FULFILLED",
    GET_HLJ_EMPLOYERS: "GET_HLJ_EMPLOYERS",
    GET_HLJ_EMPLOYERS_PENDING: "GET_HLJ_EMPLOYERS_PENDING",
    GET_HLJ_EMPLOYERS_FULFILLED: "GET_HLJ_EMPLOYERS_FULFILLED",
    UPDATE_HLJ_EMPLOYERS: "UPDATE_HLJ_EMPLOYERS",
    UPDATE_HLJ_EMPLOYERS_PENDING: "UPDATE_HLJ_EMPLOYERS_PENDING",
    UPDATE_HLJ_EMPLOYERS_FULFILLED: "UPDATE_HLJ_EMPLOYERS_FULFILLED",
    POST_SUBSCRIPTION_PLAN_CHECK: "POST_SUBSCRIPTION_PLAN_CHECK",
    POST_SUBSCRIPTION_PLAN_CHECK: "POST_SUBSCRIPTION_PLAN_CHECK_PENDING",
    POST_SUBSCRIPTION_PLAN_CHECK: "POST_SUBSCRIPTION_PLAN_CHECK_FULFILLED",
};
