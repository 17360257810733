import React, { useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const Experience = ({ handelClose, userData, userID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = userData;
    data.experience = String(userData.experience);
    reset(data);
  };
  /**
   * @description method to update experience
   */
  const update = (val) => {
    let data = { ...val, _id: userID };
    dispatch(saarthiActions.updateJSProfile(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(saarthiActions.jobSeekerDetails(userID));
          forSuccess("Experience updated Successfully", "Success");
          console.log(res.value.message);
        }
      })
      .catch((err) => console.log(err));
    console.log(val);
    reset();
    handelClose();
  };

  return (
    <>
      <div className="">
        <div className="row">
          <div className=" col-12">
            <h2 className="tttle prnl_head_aa">
              <FormattedMessage
                id="yearsOfExp"
                defaultMessage="Years of experience"
              />
            </h2>
            <div className="mt-2">
              <Form onSubmit={handleSubmit(update)}>
                <div className="d-flex justify-content-between">
                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="Fresher"
                      value="0"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="Fresher">
                      Fresher
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="Less than 1"
                      value="1"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="Less than 1">
                      Less than 1
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="1-2"
                      value="2"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="1-2">
                      1-2
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="2-4"
                      value="3"
                      ref={register}
                    />
                    <label className="form-check-label lw" for="2-4">
                      2-4
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2">
                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="4-6"
                      value="4"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="4-6">
                      4-6
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="6-8"
                      value="5"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="6-8">
                      6-8
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="8-10"
                      value="6"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="8-10">
                      8-10
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="experience"
                      id="10+"
                      value="7"
                      ref={register}
                    />
                    <label className="form-check-label text-capitalize lw" for="10+">
                      10+
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
