import React, { useContext, useState, useEffect } from "react";
import { Context } from "Languages/Wrapper/Wrapper";
import * as adminActions from "redux/actions/AdminActions";
import { useDispatch } from "react-redux";
import appSetting from '../../common/permissionValue';
import * as commonService from "utils/CommonService.js";
import storage from "utils/storage";

export const AdminDashboard = ({ history }) => {
  const context = useContext(Context);
  const dispatch = useDispatch();
  const [totalEmployerCount, setTotalEmployerCount] = useState(0); // storing all registered counts
  const [inProgressCount, setInProgressCount] = useState(0); // storing in progress counts
  const [approvedCount, setApprovedCount] = useState(0); // storing approved counts
  const [declinedCount, setDeclinedCount] = useState(0); // storing declined counts
  const [isMenuShow, setMenu] = useState(false);
  const [activeJobs, setActiveJobs] = useState(0); //Storing active Job counts
  const [empViewCount, setEmpViewCount] = useState(0); //Storing employer view jobseeker counts
  const [appliedJobseeker, setAppliedJobseeker] = useState(0); //Storing applied jobseeker counts
  const [shortlisted, setShortlisted] = useState(0); //Storing shortlisted Jobseeker counts
  const [notShortlisted, setNotShortlisted] = useState(0); //Storing not Shortlisted/rejected Jobseeker counts
  const [empWithJobs, setEmpWithJobs] = useState(void 0); //Storing Counts of Employeers who has posted job 
  const [professionalCounts, setProfessionalCounts] = useState(void 0); //Storing Counts of Professional Conversation
  const [notInitiatedEmpWithJobs, setNotInitiatedEmpWithJobs] = useState(void 0); //Storing Counts of Employeers whose verification status is not initiated and has posted a job 
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage
  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenu(isMenuShow ? false : true);
  };
  document.addEventListener('click', function () {
    setMenu(false);
  });
  /*  Function to get the array of object from object of objects
      resposne : data to be converted
  */
  const getStats = (response) => {
    let stats = response.reduce(
      (obj, item) => Object.assign(obj, { [item._id]: item.count }), {}
    );
    return stats // returns array of object
  }
  useEffect(() => {
    async function fetchData() {
      // GET API FOR USERS COUNTS
      await dispatch(adminActions.getUsersCount())
        .then((response) => {
          if (response && response.value.success) {
            let empDetails = getStats(response.value.data.emp_status) // Employers stats
            let jobseekerDetails = getStats(response.value.data.js_jobs_status) // Jobseeker Stats
            setTotalEmployerCount(response.value.data.total_employer); // get and set All Registered Employer Count
            setInProgressCount(empDetails['In Progress']); // get and set in progress Count
            setApprovedCount(empDetails['Approved']); // get and set counts of Employers having status approved 
            setDeclinedCount(empDetails['Declined']); // get and set counts of Employers having status declined 
            setActiveJobs(response.value.data.active_jobs) // Get and set total active Jobs count
            setEmpViewCount(response.value.data.emp_view_counts) //Employer View number of jobseekers (Counts)
            setAppliedJobseeker(jobseekerDetails['Applied']) // Applied jobseekers Counts
            setNotShortlisted(jobseekerDetails['Rejected']) // Not-Shortlisted jobseekers Counts
            setShortlisted(jobseekerDetails['Shortlisted']) // Shortlisted jobseekers Counts
            setEmpWithJobs(response.value.data.emp_job_posted) // Counts of Employeers who has posted job
            setNotInitiatedEmpWithJobs(response.value.data.job_posted_verification_not_init) // Counts of Employeers who has posted job
            setProfessionalCounts(response.value.data.professional_count) // Storing Counts of Professional Conversation
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchData();
  }, [dispatch]);
  return (
    <>
      <div className="admin_dashboard">
        <div className="container-fluid">
          <div className="ad_inner">
            <div className="ad_top_box">
              {/* Employer Counts Section Starts */}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_READ]) ?
                <>
                  <fieldset className="sectionHeading">
                    <h4 className="sectionTitle">Employer Counts</h4>
                    <div className="ad_widgets">
                      <div className="widgetbox" onClick={() => history.push('/emp-registration')}>
                        <h4>Total Registration</h4>
                        <h2>{totalEmployerCount ? totalEmployerCount : 0}</h2>
                      </div>
                      <div className="widgetbox not-clickable">
                        <h4>Registration With Job Post</h4>
                        <h2>{empWithJobs ? empWithJobs : 'N/A'}</h2>
                      </div>
                      <div className="widgetbox not-clickable">
                        <h4>Registration Without Job Post</h4>
                        <h2>{totalEmployerCount && empWithJobs ? (totalEmployerCount - empWithJobs) : 'N/A'}</h2>
                      </div>
                    </div>
                  </fieldset>
                  {/* Employer Counts Section Starts */}
                  {/* Authentication Progress Section Starts */}
                  <fieldset className="sectionHeading">
                    <h4 className="sectionTitle authentication_title"> Authentication Progress </h4>
                    <div className="ad_widgets">
                      <div className="widgetbox not-clickable">
                        <h4>All</h4>
                        {/* Getting counts of notInitiatedEmpWithJobs, inProgressCount, approvedCount and declinedCount*/}
                        <h2>{notInitiatedEmpWithJobs && inProgressCount && approvedCount && declinedCount ? (notInitiatedEmpWithJobs + inProgressCount + approvedCount + declinedCount) : 'N/A'}</h2>
                      </div>
                      <div className="widgetbox not-clickable">
                        <h4>Not Initiated With Job Post</h4>
                        <h2>{notInitiatedEmpWithJobs ? notInitiatedEmpWithJobs : 'N/A'}</h2>
                      </div>
                      <div className="widgetbox" onClick={() => history.push('/authentication-list/in-progress')}>
                        <h4>In Progress</h4>
                        <h2>{inProgressCount ? inProgressCount : 0}</h2>
                      </div>
                      <div className="widgetbox" onClick={() => history.push('/authentication-list/approved')}>
                        <h4>Approved</h4>
                        <h2>{approvedCount ? approvedCount : 0}</h2>
                      </div>
                      <div className="widgetbox" onClick={() => history.push('/authentication-list/not-approved')}>
                        <h4>Not Approved</h4>
                        <h2>{declinedCount ? declinedCount : 0}</h2>
                      </div>
                    </div>
                  </fieldset>
                </>
                : ''}
              {/* Authentication Progress Section Ends */}
              {/* Application Counts Section Starts */}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_READ]) ?
                <fieldset className="sectionHeading">
                  <h4 className="sectionTitle">Application Counts</h4>
                  <div className="ad_widgets">
                    <div className="widgetbox not-clickable">
                      <h4> Job Applications</h4>
                      <h2>{appliedJobseeker ? appliedJobseeker : 0}</h2>
                    </div>
                    <div className="widgetbox not-clickable">
                      <h4>Application View</h4>
                      <h2>{empViewCount ? empViewCount : 0}</h2>
                    </div>
                    <div className="widgetbox not-clickable">
                      <h4>Not Shortlisted Applications</h4>
                      <h2>{notShortlisted ? notShortlisted : 0}</h2>
                    </div>
                    <div className="widgetbox not-clickable">
                      <h4>Shortlisted Applications</h4>
                      <h2>{shortlisted ? shortlisted : 0}</h2>
                    </div>
                  </div>
                </fieldset> : ''}
              {/* Application Counts Section Ends */}
              {/* Job Counts Section Starts */}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_READ]) ?
                <fieldset className="sectionHeading">
                  <h4 className="sectionTitle sectionTitle_2">Job Counts</h4>
                  <div className="ad_widgets">
                    <div className="widgetbox" onClick={() => history.push('/job-description/active-jobs')} >
                      <h4>Active Jobs</h4>
                      <h2>{activeJobs ? activeJobs : 0}</h2>
                    </div>
                    <div className="widgetbox not-clickable">
                      <h4>Professional counts</h4>
                      <h2>{professionalCounts ? professionalCounts : 'N/A'}</h2>
                    </div>
                  </div>
                </fieldset> : ''}
              {/* Job Counts Section Ends */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
