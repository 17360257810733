
module.exports = {
    // Employer component permission
    EMPLOYERS: {
        EMPLOYER_READ: 'READ:EMPLOYER',
        EMPLOYER_CREATE: 'CREATE:EMPLOYER',
        EMPLOYER_UPDATE: 'UPDATE:EMPLOYER'
    },
    // Job component permission
    JOBS: {
        JOB_READ: 'READ:JOB',
        JOB_CREATE: 'CREATE:JOB',
        JOB_UPDATE: 'UPDATE:JOB'
    },
    // Plan component permission
    PLANS: {
        PLAN_READ: 'READ:PLAN',
        PLAN_CREATE: 'CREATE:PLAN',
        PLANS_UPDATE: 'UPDATE:PLAN'
    },
    // Email change request component permission
    EMAILS: {
        EMAIL_READ: 'READ:EMAIL',
        EMAIL_CREATE: 'CREATE:EMAIL',
        EMPLOYER_UPDATE: 'UPDATE:EMPLOYER'
    },
    // Role component permission
    ROLES: {
        ROLE_READ: 'READ:ROLE',
        ROLE_CREATE: 'CREATE:ROLE',
        ROLE_UPDATE: 'UPDATE:ROLE'
    },
    // HLJ-Employer component permission
    USERS: {
        USER_READ: 'READ:USER',
        USER_CREATE: 'CREATE:USER',
        USER_UPDATE: 'UPDATE:USER'
    }
};