import { CompareArrowsOutlined } from "@material-ui/icons";
import React from "react";
import {useSelector} from "react-redux";
import { Route, Redirect } from "react-router-dom";
import storage from "utils/storage";
// import page layout and container for private and public page;
import { PublicRouterLayout, AdminRouterLayout } from "./routerLayout";

export const PublicRoute = props => {
  const { token } = useSelector(state => state.authReducer);
  const { component: Component, ...restProps } = props;

  if (!Component) return null;
  return (
    <Route
      {...restProps}
      render={routeRenderProps =>
        (!token) ? (
          <PublicRouterLayout>
            <Component {...routeRenderProps} />
          </PublicRouterLayout>) :
        (
          <Redirect
            to={{
              pathname: "/admin-dashboard",
              state: { from: routeRenderProps.location }
            }}
          />
        )
      }
    />
  );
};
export const AdminRoute = props => {
  const { token } = useSelector(state => state.authReducer);
  const { role } = useSelector(state => state.authReducer);
  const isSuperAdmin = storage.get("humlog_super_admin");
  const { component: Component, ...restProps } = props;
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  }
  if (!Component) return null;
  return (
    <Route
      {...restProps}
      render={routeRenderProps => {
        if(token) {
          if(isSuperAdmin === true || isSuperAdmin === false ) {
            return (
            <AdminRouterLayout>
              <Component {...routeRenderProps} />
            </AdminRouterLayout>)
          }
          else if ((role === 0) || (role === 2)) {
            logOut();
          } 
          else {
            return (
              <Redirect
                to={{
                  pathname: "/saarthi-dashboard",
                  state: { from: routeRenderProps.location }
                }}
              />
            )
          }
        }
          return (
            <Redirect
              to={{
                pathname: "/admin-login",
                state: { from: routeRenderProps.location }
              }}
            />
          )
        }
      }
    />
  );
};
export const SaarthiRoute = props => {
  const { token } = useSelector(state => state.authReducer);
  const { role } = useSelector(state => state.authReducer);
  const { component: Component, ...restProps } = props;
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  }
  if (!Component) return null;
  return (
    <Route
      {...restProps}
      render={routeRenderProps =>{
        if(token) {
          if(role === 1) {
            return (
            <AdminRouterLayout>
              <Component {...routeRenderProps} />
            </AdminRouterLayout>)
          } 
          else if ((role === 0) || (role === 2)) {
            logOut();
          } 
          else {
            return (
              <Redirect
                to={{
                  pathname: "/admin-dashboard",
                  state: { from: routeRenderProps.location }
                }}
              />
            )
          }
        }
          return (
            <Redirect
              to={{
                pathname: "/admin-login",
                state: { from: routeRenderProps.location }
              }}
            />
          )
        }
      }
    />
  );
};
