import person from "../../../assets/images/admin/person.svg";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import moment from "moment";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Player } from '../../common/player';
import { FaTimes } from "react-icons/fa";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaRegFileAudio, FaRegFileVideo } from "react-icons/fa";
import Alert from "@material-ui/lab/Alert";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action Toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im"; // ascending order & descending order icon
import ReactTooltip from "react-tooltip"; // React Tooltip
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { CSVLink, CSVDownload } from "react-csv"; //For converting data and downloading CSV file
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";

// these code will be change after API integration
const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(employername, jobpostingdate, lastActivity, jobposition, joblocation, salary, audiojd, videojd, jobstatus, companyMobile, jobOpening, id, companylogo, viewappliedjobseekers, shortlistedCandidates, notShortlistedCandidates, applicantView, total_convo_count, action) {
  return { employername, jobpostingdate, lastActivity, jobposition, joblocation, salary, audiojd, videojd, jobstatus, companyMobile, jobOpening, id, companylogo, viewappliedjobseekers, shortlistedCandidates, notShortlistedCandidates, applicantView, total_convo_count, action };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Job-description Table Columns
const headCells = [
  { id: 'employername', numeric: false, disablePadding: false, label: 'Company' },
  { id: 'jobpostingdate', numeric: false, disablePadding: false, label: 'Job Posting Date', align: 'center' },
  { id: 'lastActivity', numeric: false, disablePadding: false, label: 'Last Activity', align: 'center' }, // User last activity date 
  { id: 'jobposition', numeric: false, disablePadding: false, label: 'Job Post Details', align: 'center' },
  { id: 'audiovideojd', numeric: false, disablePadding: false, label: 'Audio / Video JD', align: 'center' },
  { id: 'jobstatus', numeric: false, disablePadding: false, label: 'Job Status', align: 'center' },
  { id: 'companyMobile', numeric: false, disablePadding: false, label: 'Mobile Number', align: 'center' },
  { id: 'jobOpening', numeric: false, disablePadding: false, label: 'No of Job Opening', align: 'center' },
  { id: 'viewappliedjobseekers', numeric: true, disablePadding: false, label: 'Applied JobSeekers', align: 'center' },
  { id: 'shortlistedCandidates', numeric: true, disablePadding: false, label: 'Shortlisted Candidates', align: 'center' },
  { id: 'notShortlistedCandidates', numeric: false, disablePadding: false, label: 'Not-Shortlisted Candidates', align: 'center' },
  { id: 'applicantView', numeric: false, disablePadding: false, label: 'Applicant View', align: 'center' },
  { id: 'total_convo_count', numeric: true, disablePadding: false, label: 'Total Conversation Count', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

export const JobDescription = (props) => {
  const { job_Status } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const now = moment().format("YYYY-MM-DD");
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const { jobList } = useSelector((state) => state.adminReducer);
  const [jobStatus, setStatus] = useState(job_Status === "active-jobs" ? "0" : "All"); //checking status with params value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 );
  const [table, setTable] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [modal, setModal] = useState({ open: false, type: "" });
  const [deactive, setDeactive] = useState({ isOpen: false, id: "" });
  const [updateTable, setUpdateTable] = useState(false);
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [jobCount, setJobCount] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [setCount, setTotalCount] = useState(0); // storing total count
  const sortingIconClicked= useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const { register, handleSubmit, errors, reset } = useForm({mode: "onChange"});
  const [startDate, setStartDate] = useState(""); //Start date in download CSV modal
  const [endDate, setEndDate] = useState(""); //End date in download CSV modal
  const [endMaxDate, setEndMaxDate] = useState(""); //Max date selection Limit in download CSV modal
  const minDate = moment(startDate).format("YYYY-MM-DD");
  const csvLink = useRef();
  const [csvData, setCSVData] = useState([]); //To store and download CSV data 
  const [openDateModal, setOpenDateModal] = useState(false); //Handling Opening and Closing of Modal
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(pageNumber); // api to fetching table data
  }, [dispatch, jobStatus, from, to, page, updateTable, sort, searched]);

  async function fetchData(pageNumber) {
    setRows([]);
    // if To date is smaller than From date
    if (to <= from) {
      window.alert("to date cannot be smaller than from date");
      return;
    }
    let body;
    if (from && to) {
      if (jobStatus === "All") {
        body = {
          time_filter: {
            from: from,
            to: to,
          },
        };
      } else {
        body = {
          status: parseInt(jobStatus),
          time_filter: {
            from: from,
            to: to,
          },
        };
      }
    } else {
      if (jobStatus === "All") {
        body = {};
      } else {
        body = {
          status: parseInt(jobStatus),
        };
      }
    }
    if (searched && searched.length) {
      body.search = searched;
    }
    if (sort) {
      body.sort = sort;
    }
    dispatch(adminActions.getJobs(body, pageNumber ? pageNumber : page))
      .then((response) => {
        if (response && response.value.success) {
          setJobCount((prev) => response.value.data.count);
          setTotalCount((prev) => response.value.data.count); // get total count
          setTableData(response.value.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // set table values method
  const setTableData = async (jobList) => {
    let jobTable = [];
    if (jobList && jobList.length) {
      let tempArray = [...jobList];
      for (let val of tempArray) {
        let appliedJobseekers = val.users.filter((data) => data.status === 'Applied'); // storing jobseeker whose status 'Applied'
        let shortlistedJobseeker = val.users.filter((data) => data.status === 'Shortlisted'); // storing jobseeker whose status 'Shortlisted'
        let notShortlistedJobseeker = val.users.filter((data) => data.status === 'Rejected'); // storing jobseeker whose status is 'Rejected i.e Not Shortlisted'
        // form table data
        let data = createData(
          val.company_name,
          new Date(val.created_at).toLocaleDateString(),
          val.last_activity && val.last_activity.last_activity ? new Date(val.last_activity.last_activity).toLocaleDateString() : '-', // User last activity date 
          val.job_title,
          val.district,
          val.salary_to,
          val.audio_description,
          val.animated_description,
          val.status,
          val.company_contact, // get company mobile number
          val.openings ? val.openings : '', // get no of job opening
          val._id,
          val.company_logo,
          appliedJobseekers,
          shortlistedJobseeker,
          notShortlistedJobseeker,
          val.emp_view_count, // get employer view count
          val.total_convo_count, // Total Conversation Counts 
        );
        jobTable.push(data);
      }
    }
    setTable(jobTable);
    setRows(jobTable); // get table data
    setTable((state) => {
      return state;
    });
    return jobTable;
  };

   // go to previous page
   const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/job-description', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/job-description', search: `?page=${pageNumber}&sort=${sort}` });
  };
  // method for handling modal
  const onToggleModal = (type) => {
    setModal({ open: !modal.open, type: type });
  };
  // open deactivate dialog
  const openDeactive = (id) => {
    setDeactive({ isOpen: true, id: id });
  };
  // Close deactivate dialog
  const closeDeactive = () => {
    setDeactive({ isOpen: false });
  };

  /**
   * @description method to deactive job
   * @param {param} id 
   */
  const deactivateJob = (id) => {
    let body = {
      status: 4,
    };
    dispatch(adminActions.updateJob(body, id))
      .then((response) => {
        if (response && response.value.success) {
          setSuccess({ open: true, message: `Job Deactivated Successfully` });
          setUpdateTable(!updateTable);
        }
        setTimeout(() => {
          setSuccess({ open: false, message: "" });
        }, 3000);
      })
      .catch((error) => {
        console.log("error", error);
      });
    setDeactive({ isOpen: false });
  };
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/job-description', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/job-description', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by job posting date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by job posting date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };

  /**
   * @description method to view applied jobseeker list/table 
   */
  const viewAppliedJS = (id, status) => {
    history.push({pathname : '/applied-job-seekers', search: "?job_id=" + id + "&status=" + status});
  }
  // Job Status dropdown options array
  const jobDropdown = [
    { name: "All Jobs", value: "All" },
    { name: "Active", value: "0" },
    { name: "Pending", value: "3" },
    { name: "Inactive", value: "4" },
  ];
//  Funtion to calculate min and max date allowed to download Table data
  const maxDate = (e) => {
    let endDate = moment(e.target.value).add(30, "Days"); // Setting end date to 30 days duration
    if (endDate > moment(new Date())) {
      setEndMaxDate(moment(new Date()).format("YYYY-MM-DD")); // Setting Max. date allowed as Todays Date
    } else {
      setEndMaxDate(endDate.format("YYYY-MM-DD"));
    }
  }
  // Function to open Job data download modal
  const dataModalOpen = () => {
    setOpenDateModal(true);
  };
  // Function to close Job data download modal
  const dataModalClose = () => {
    setOpenDateModal(false);
  };
// Function to Fetch and downlaod Job data of One month
  const downloadJobData = async (val) => {
    try {
      let startDate = moment(val.start_date).startOf('days').toString(); // set 12:00 AM of Start date
      let endDate = moment(val.end_date).endOf('days').toString(); // set 11:59 PM Of End date
      let data = {
        time_filter: {
          from: Date.parse(startDate), 
          to: Date.parse(endDate)
        }
      };
      let response = await dispatch(adminActions.downloadJobData(data)); // data: sending date range to get required Job data
      if (response.value.success) {
        let data = []; //Empty array to store required Job Data
        const jobStatusCheck={0:'Active', 3:'Pending', 4:'In-Active'} //Storing value of status according to status code
        const animatedJdCheck={true:'Yes',false:'No'} //Storing Value of Animated JD according to JD status
        for (let val of response.value.data){
          //storing jobs data into an array
          data.push({ 
            'Company Name' : val.company_name,
            'Job Title' : val.job_title,
            'Created At': (new Date(val.created_at)).toLocaleDateString(), 
            'Job Location' : val.job_location,
            'District' : val.district,
            'Status' : jobStatusCheck[val.status],
            'Animated JD' : animatedJdCheck[val.animated_jd_required],
            'Audio JD' : val && val.audio_description && val.audio_description.length ? 'Yes' : 'No' // If AudioJD is available then Sending 'Yes' else 'No'
          })
        }
        setCSVData(data); //Setting required Jobs data Array as(data) in CSVData
        csvLink.current.link.click();
      }
      dataModalClose(); //Closing Download CSV modal after hitting Submit button
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {success.open && (
        <Alert severity="success" className="alert-toast">
          {success.message}
        </Alert>
      )}
      <div className="reg_main job-section">
        <div className="container-fluid">
          {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) ?
          <div className="data_download">
            <button type="button" onClick={dataModalOpen}>  {/* Opens "Download Csv Modal" */}
              Download CSV
            </button>
          </div> : ''}
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Jobs Count : {jobCount}</p>
                <div className="timefilterdropdown">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    defaultValue={jobStatus}  //default selected option with params value
                    onClick={(e) => setStatus(e.target.value)}>
                    {jobDropdown.map(item => {return <option value={item.value}>{item.name}</option>})}
                  </select>
                </div>
                <p> From </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="from"
                    placeholder="From"
                    onChange={(e) => setFrom(Date.parse(e.target.value))}
                  />
                </div>
                <p> To </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="to"
                    placeholder="To"
                    onChange={(e) => {
                      let date = new Date(e.target.value);
                      date.setDate(date.getDate() + 1);
                      setTo(Date.parse(date));
                    }}
                  />
                </div>
              </div>
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
              {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                              <div className="employer_company_details">
                                <img src={row.companylogo} alt="company-logo" className="img-fluid company_logo"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = person;
                                  }}
                                />
                                <span className="employer_company_name">{row.employername}</span>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              {row.jobpostingdate}
                            </TableCell>
                            <TableCell align="center">
                              {row.lastActivity ? row.lastActivity : '-'}
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.jobposition}</p>
                              <p className="my-1">
                                {row.joblocation}, Rs.{row.salary ? row.salary : 'N/A'}
                              </p>
                              <Link to={`/audio-details?id=${row.id}`}>
                                View More
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              <button
                                type="button"
                                className="audio_video_icon"
                                onClick={() => {
                                  onToggleModal(row.audiojd);
                                }}
                                disabled={row.audiojd ? false : true}
                              >
                                <FaRegFileAudio />
                              </button>
                              <button
                                type="button"
                                className="audio_video_icon"
                                onClick={() => {
                                  onToggleModal(row.videojd);
                                }}
                                disabled={row.videojd ? false : true}
                              >
                                <FaRegFileVideo />
                              </button>
                            </TableCell>
                            <TableCell align="center">
                              <span
                                className={
                                  row.jobstatus === 0
                                    ? "active"
                                    : row.jobstatus === 1
                                    ? "complete"
                                    : row.jobstatus === 2
                                    ? "closed"
                                    : row.jobstatus === 4
                                    ? "deactive"
                                    : "pending"
                                }
                              >
                                {row.jobstatus === 0
                                  ? "Active"
                                  : row.jobstatus === 1
                                  ? "Complete"
                                  : row.jobstatus === 2
                                  ? "Closed"
                                  : row.jobstatus === 4
                                  ? "Inactive"
                                  : "Pending"}
                              </span>
                            </TableCell>
                            {/* company-mobile-section start from here */}
                            <TableCell align="center">
                              <span className={!row.companyMobile ? "not_available" : ""}>
                                {row.companyMobile ? row.companyMobile : 'N/A'}
                              </span>
                            </TableCell>
                            {/* company-mobile-section end */}
                            {/* job-opening section start from here */}
                            <TableCell align="center">
                              <span>
                                {row.jobOpening}
                              </span>
                            </TableCell>
                            {/* job-opening section end */}
                            <TableCell align="center">
                              {row.viewappliedjobseekers?.length === 0 ? <span className="jscount inactive">0</span> : (<><span data-tip="View Job Seekers" className="jscount" onClick={() =>viewAppliedJS(row.id, 'Applied')}>{row.viewappliedjobseekers ? row.viewappliedjobseekers?.length : 0}</span><ReactTooltip /></>)}
                            </TableCell>
                            {/* Shortlisted candidates column */}
                            <TableCell align="center">
                              {row.shortlistedCandidates?.length === 0 ? <span className="jscount inactive">0</span> : (<><span data-tip="View Shortlisted Candidates" className="jscount" onClick={() =>viewAppliedJS(row.id, 'Shortlisted')}>{row.shortlistedCandidates ? row.shortlistedCandidates?.length : 0}</span><ReactTooltip /></>)}
                            </TableCell> 
                              {/* Not-Shortlisted candidates column */}
                            <TableCell align="center">
                              {row.notShortlistedCandidates?.length === 0 ? <span className="jscount inactive">0</span> : (<><span data-tip="View Not Shortlisted Candidates" className="jscount" onClick={() =>viewAppliedJS(row.id, 'Rejected')}>{row.notShortlistedCandidates ? row.notShortlistedCandidates?.length : 0}</span><ReactTooltip /></>)}
                            </TableCell>
                            {/* applicant-view-section start from here */}
                            <TableCell align="center">
                              {row.applicantView ? row.applicantView : 0}
                            </TableCell>
                            {/* applicant-view-section end */} 
                            { /* Total Conversation counts starts */}
                            <TableCell align="center">
                              <span className={!row.total_convo_count ? "not_available" : ""}>
                                {row.total_convo_count ? row.total_convo_count : "N/A"}
                              </span>
                            </TableCell>
                            { /* Total Conversation counts ends */}
                            <TableCell align="right">
                              <button
                                type="button"
                                className={commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) ? '' : 'not-clickable'}
                                onClick={() => commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_UPDATE]) ? 
                                  openDeactive(row.id) : ''}
                                disabled={row.jobstatus !== 0 ? true : false}
                              >
                                Deactivate
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Player
                open={modal.open}
                toggleModal={onToggleModal}
                src={modal.type}
              />
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button
                    type="button"
                    onClick={() => next(page + 1, sort)}
                    disabled={
                      jobList && jobList.data && jobList.data.length === 30
                        ? false
                        : true
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* document dialog box */}
      <Dialog
        open={deactive.isOpen}
        onClose={closeDeactive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal deactivate_modal"
      >
        <button className="modal-cross-btn" onClick={closeDeactive}>
          <FaTimes />
        </button>
        <DialogContent style={{ padding: "0px" }}>
          <p>Are you sure you want to deactivate the job post ?</p>
          <div className="deactivate_profile_btns">
            <button type="button" className="no" onClick={closeDeactive}>
              NO
            </button>
            <button
              type="button"
              className="yes"
              onClick={() => {
                deactivateJob(deactive.id);
              }}
            >
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <ReactTooltip />
      {/* ---- Download CSV for Job data Modal starts here ---- */}
      <Dialog
        open={openDateModal}
        onClose={dataModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Enter Start Date & End Date"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={dataModalClose}><FaTimes /></button>  {/* Cross button to close Download CSV Modal */}
        <DialogContent className="reason_modal_content data_modal p-0">
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(downloadJobData)}>  {/* Sending date duration to download Job data */}
              <div className="form-group">
                <label htmlFor="start_date">
                  <span className="datelabel">From</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="start_date"
                  id="start_date"
                  placeholder="Enter Start Date"
                  onChange={(e) => {
                    setStartDate((e.target.value));
                    maxDate(e); //Setting max date range for next 30 days
                  }}
                  className={classNames("form-control", {
                    "is-invalid": errors.start_date,
                  })}
                  ref={register({
                    required: "Please enter Start Date",
                  })}
                  max={new Date()} //Start date range limit with current date
                />
                {errors.start_date && (
                  <span className="error_font text-danger">
                    {errors.start_date.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="end_date">
                  <span className="datelabel">To</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="end_date"
                  id="end_date"
                  placeholder="Enter End Date"
                  className={classNames("form-control", {
                    "is-invalid": errors.end_date,
                  })}
                  ref={register({
                    required: "Please enter End Date",
                  })}
                  onChange={(e) => setEndDate((e.target.value))} // Setting End Date 
                  min={startDate} // Setting minimum end date as the selected start date
                  max={endMaxDate} // Setting max end date as the 30days duration
                />
                {errors.end_date && (
                  <span className="error_font text-danger">
                    {errors.end_date.message}
                  </span>
                )}
              </div>
              <button type="submit" className="reasonbtn">SUBMIT</button>
              <CSVLink 
                data={csvData} // Setting Required Job data
                filename='JobsData.csv' // Downloaded CSV file name
                className='hidden'
                ref={csvLink} 
                target="_blank" 
              />
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
{/* ---- Download CSV for Job data Modal Ends here ---- */}
    </>
  );
};
