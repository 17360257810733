import admin_logo from "../../assets/images/logos/admin_logo.png";
import user_img from "../../assets/images/admin/useri.png";
import user_profile from "../../assets/images/admin/user.svg";
import logout from "../../assets/images/admin/logout.svg";
import change_password from "../../assets/images/admin/change_password.svg";
import { RiDashboardLine } from "react-icons/ri";
import { BsPeople, BsPeopleFill, BsBriefcaseFill, BsPencilSquare } from "react-icons/bs";
import { BiBriefcase, BiTransfer } from "react-icons/bi";
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import storage from "utils/storage";
import { NavHashLink } from 'react-router-hash-link';
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { VscMail } from "react-icons/vsc"; // Icon for Request Screen
import { IoAddCircleOutline, IoPersonAddOutline } from "react-icons/io5"; // roles Icon
import appSetting from '../common/permissionValue';
import * as commonService from "utils/CommonService.js";

export const AdminHeader = (props) => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const [saarthiDetails, setSaarthiDetails] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = storage.get("humlog_user");
  const userRole = storage.get("humlog_user_role");
  const isSuperAdmin = storage.get("humlog_super_admin");
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage

  useEffect(() => {
    if (isSuperAdmin === true || isSuperAdmin === false) {
      // API for get admin data fetch by Id
      let query = {
        _id: userId
      }
      dispatch(userActions.getUserData(query));
    } else if (userRole === 1) {
      dispatch(saarthiActions.saarthiDetails(userId))
        .then((response) => {
          if (response && response.value.success) {
            setSaarthiDetails(response.value.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
    }
  }, [dispatch]);

  /* dropdown */
  const [isMenuShow, setMenu] = useState(false);
  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenu(isMenuShow ? false : true);
  };
  /* method for close dropdown when click outside the dropdown */
  document.addEventListener('click', function () {
    setMenu(false);
  });

  const logOut = async () => {
    storage.clear();
    await dispatch(authActions.logout());
    history.push("/admin-login");
  }

  const viewProfile = () => {
    history.push("/admin-saarthi-profile");
  }

  const changePassword = () => {
    history.push("/change-password");
  }
  return (
    <>
      <header className="admin_header">
        <div className="container-fluid">
          <nav className="navbar">
            <div className="nav_lft">
              <button id="humburger" type="button"
                onClick={props.toggleBox} className={props.isActiveClass ? "hamburger hamburger--squeeze is-active" : "hamburger hamburger--squeeze"}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
            <div className="nav_middle">
              <div className="admin_logo">
                <img src={admin_logo} alt="HumlogJobs" className="img-fluid d-block" />
              </div>
            </div>
            <div className="nav_ryt">
              <div className="admin_name">
                {
                  ('is_super_admin' in userDetails) ?
                    (
                      <h4>
                        {userDetails && userDetails.name && userDetails.name.length > 0
                          ? userDetails.name
                          : ""}
                        <span>Admin</span>
                      </h4>
                    )
                    :
                    (userRole === 1) ?
                      (
                        <h4>
                          {saarthiDetails && saarthiDetails.name && saarthiDetails.name.length > 0
                            ? saarthiDetails.name
                            : ""}
                          {/* <span>Admin Sarthi</span> */}
                          <span>{saarthiDetails && saarthiDetails.hasOwnProperty('saarthi_code') ? saarthiDetails.saarthi_code : ''}</span>
                        </h4>
                      )
                      :
                      ("")
                }
              </div>
              <div className="dropdownbox">
                <button onClick={toggleMenu} className="dropdownbtn" type="button">
                  <img src={user_img} alt="HumlogJobs" className="img-fluid d-block" />
                </button>
                {isMenuShow ? (
                  <div className="dropdownmenu">
                    <ul>
                      {(userRole === 1) ?
                        (<>
                          <li>
                            <a onClick={viewProfile}>
                              <img src={user_profile} alt="profile" className="img-fluid" />
                              Profile
                            </a>
                          </li>
                          <li>
                            <a onClick={changePassword}>
                              <img src={change_password} alt="change password" className="img-fluid" />
                              Change Password
                            </a>
                          </li>
                        </>
                        )
                        :
                        ("")
                      }
                      <li>
                        <a onClick={logOut}>
                          <img src={logout} alt="HumlogJobs" className="img-fluid" />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : ("")}
              </div>
            </div>
          </nav>
        </div>
      </header>
      {(isSuperAdmin === true || isSuperAdmin === false) ?
        (
          <div id="mySidenav" className={props.isActiveClass ? "sidenav admin_sidenav is-active" : "admin_sidenav sidenav"}>
            <ul>
              <li><NavHashLink smooth to='/admin-dashboard'><RiDashboardLine />Dashboard</NavHashLink></li>
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_READ]) ? <li><NavHashLink smooth to='/emp-registration'><BsPencilSquare />Registration</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_READ]) ? <li><NavHashLink smooth to='/authentication-list'><BsPeople />Authentication</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.JOBS.JOB_READ]) ? <li><NavHashLink smooth to='/job-description'><BiBriefcase />Jobs</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.PLANS.PLAN_READ]) ? <li><NavHashLink smooth to='/plans'><BiTransfer />Plans</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMAILS.EMAIL_READ]) ? <li><NavHashLink smooth to='/request-list'><VscMail />Email Change Request</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.ROLES.ROLE_READ]) ? <li><NavHashLink smooth to='/roles'><IoAddCircleOutline />Roles & Permission</NavHashLink></li> : ''}
              {commonService.showHideBaseOnPermission(adminPermission, [appSetting.USERS.USER_READ]) ? <li><NavHashLink smooth to='/hlj-employer'><IoPersonAddOutline />HLJ Employer</NavHashLink></li> : ''}
            </ul>
          </div>
        )
        :
        (
          <div id="mySidenav" className={props.isActiveClass ? "sidenav admin_sidenav is-active" : "admin_sidenav sidenav"}>
            <ul>
              <li><NavHashLink smooth to='/saarthi-dashboard'><RiDashboardLine />Dashboard</NavHashLink></li>
              <li><NavHashLink smooth to='saarthi-list'><BsPeople />Sarthi</NavHashLink></li>
              <li><NavHashLink smooth to='/jobseeker-list'><BiBriefcase />Job Seeker</NavHashLink></li>
              <li><NavHashLink smooth to='/jobs-list'><BsBriefcaseFill />Jobs</NavHashLink></li>
              <li><NavHashLink smooth to='/community-list'><BsPeopleFill />Community</NavHashLink></li>
              <li><NavHashLink smooth to='/performance'><BiBriefcase />Performance</NavHashLink></li>
            </ul>
          </div>
        )
      }
    </>
  );
};
