import { AdminActionTypes } from '../actions/AdminActions/actionType';
import storage from 'utils/storage';

export const initialState = {
    userList: [],
    jobList: [],
    isLoading: false,
    userDetails: {},
    jobDetails: {},
    userDetailsById: {},
    count: 0
}
export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case AdminActionTypes.GET_EMPLOYERS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                userList: [],
              }); 
        case AdminActionTypes.GET_EMPLOYERS_FULFILLED:
            return Object.assign({}, state, {
                isLoading: false,
                userList: action.payload.data
              }); 
        case AdminActionTypes.GET_JOBS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                jobList: [],
              });
        case AdminActionTypes.GET_JOBS_FULFILLED:
          return Object.assign({}, state, {
                isLoading: true,
                jobList: action.payload.data,
              });
        case AdminActionTypes.GET_JOB_DETAILS_PENDING:
          return Object.assign({}, state, {
                isLoading: true,
                jobDetails: {},
              });
        case AdminActionTypes.GET_JOB_DETAILS_FULFILLED:
          return Object.assign({}, state, {
                isLoading: true,
                jobDetails: action.payload.data,
              });
        default: return state;
    }
}