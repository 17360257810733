import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import storage from "utils/storage";
import classNames from "classnames";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const Updatename = ({ handelClose, name, userID }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = name;
    reset(data);
  };

  /**
   * @description method to edit/update name
   */
  const nameUpdate = async (val) => {
    try {
      let data = { ...val, _id: userID };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess("Name Updated Successfully", "Success");
      }
    } catch (error) {
      forError("Something Went Wrong", "Failure");
      console.log(error);
    }
    reset();
    handelClose();
  };

  return (
    <>
      <div className="conatiner">
        <div className="row">
          <div className="col-12">
            <div className="">
              <Form onSubmit={handleSubmit(nameUpdate)}>
                <div className="">
                  <Form.Group controlId="formGroupEmail" className="mb-0">
                    <Form.Label className="prnl_head_aa">
                      Enter Username
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      defaultValue={name ? name : ""}
                      ref={register({
                        required: " Please enter your full name",
                      })}
                      name="name"
                      autoComplete="off"
                      className={classNames(
                        "form-control mt-2 textff font-weight-semibold",
                        {
                          "is-invalid": errors.name,
                        }
                      )}
                    />
                    {errors.name && (
                      <p className="text-danger oom">{errors.name.message}</p>
                    )}
                  </Form.Group>
                  <div className="mt-4">
                    <button className="common_btn mt-4">
                      <FormattedMessage id="save" defaultMessage="Save" />
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
