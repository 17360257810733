import moment from "moment";
import person from "../../../assets/images/admin/person.svg";
import { FaTimes, FaUserPlus } from "react-icons/fa";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { downloadFile } from "../../../utils/fileService";
import { download, get_url_extension } from "../../services/commonservice";
import Pdf from "../../common/pdf";
import EnhancedTableHead from "../../common/enhancedTableHead";
import SearchBar from "material-ui-search-bar";
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im";
import ReactTooltip from "react-tooltip";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { CSVLink, CSVDownload } from "react-csv";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(companyname, organization_type, regdate, authorized_at, empdetails, docname, docdetails, job_posted, doc_no, last_login, action, id, profile_pic,) {
  return {
    companyname, organization_type, regdate, authorized_at, empdetails, docname, docdetails,job_posted, doc_no, last_login, action, id, profile_pic,};
}

// Employee Table Columns
const headCells = [
  { id: 'companyname', numeric: false, disablePadding: false, label: 'Company' },
  { id: 'organization_type', numeric: false, disablePadding: false, label: 'Employer Type', align: 'center' },
  { id: 'regdate', numeric: false, disablePadding: false, label: 'Registration Date', align: 'center' },
  { id: 'authorized_at', numeric: false, disablePadding: false, label: 'Approved Date', align: 'center' },
  { id: 'empdetails', numeric: false, disablePadding: false, label: 'Employer Details', align: 'center' },
  { id: 'docname', numeric: false, disablePadding: false, label: 'Document Name', align: 'center' },
  { id: 'docdetails', numeric: false, disablePadding: false, label: 'Document Details', align: 'center' },
  { id: 'job_posted', numeric: true, disablePadding: false, label: 'Job Posted', align: 'center' },
  { id: 'last_login', numeric: false, disablePadding: false, label: 'Last Login Date', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Custom sort when click on individual column
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const RegistrationList = (props) => {
  const { empStatus } = useParams();
  const context = useContext(Context);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });
  const now = moment().format("YYYY-MM-DD");
  const classes = useStyles();
  const { userList } = useSelector((state) => state.adminReducer);
  const employerStatus = { // If [key]ParamsValue, Then [value]Set Employer status
    'not-initiated': 'Not Initiated',
    'in-progress': 'In Progress',
    'approved': 'Approved',
    'not-approved': 'Declined',
  };
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 ); 
  const [verificationStatus, setStatus] = useState(employerStatus[empStatus] || "All"); //Checking and Setting status with params Value
  const [table, setTable] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [openDoc, setOpenDoc] = useState(false);
  const [employerCount, setEmployerCount] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const sortingIconClicked= useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const minDate = moment(startDate).format("YYYY-MM-DD");
  const [endMaxDate, setEndMaxDate] = useState("");
  const csvLink = useRef();
  const [csvData, setCSVData] = useState([]);
  const [openDateModal, setOpenDateModal] = useState(false);
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage

  useEffect(() => {
    setRows([]);
    // if To date is smaller than From date
    if (to <= from) {
      window.alert("to date cannot be smaller than from date");
      return;
    }
    async function fetchData(pageNumber) {
      let body = {};
      if (from && to) {
        if (verificationStatus === "All") {
          body = {
            time_filter: {
              from: from,
              to: to,
            },
          };
        } else {
          body = {
            verification_status: verificationStatus,
            time_filter: {
              from: from,
              to: to,
            },
          };
        }
      } else {
        if (verificationStatus === "All") {
          body = {};
        } else {
          body = {
            verification_status: verificationStatus,
          };
        }
      }
      if (searched && searched.length) {
        body.search = searched;
      }
      if (sort) {
        body.sort = sort;
      }
      dispatch(adminActions.getEmployers(body, pageNumber ? pageNumber : page))
        .then((response) => {
          if (response && response.value.success) {
            setEmployerCount((prev) => response.value.data.count[0].count);
            setTableData(response.value.data.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchData(pageNumber); // api to fetching table data
  }, [dispatch, verificationStatus, from, to, page, sort, searched]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Close dialog
  const handleClose = () => {
    setOpenDoc(false);
  };

  // set Table data method to update table values
  const setTableData = async (userList) => {
    let employerTable = [];
    if (userList && userList.length) {
      let tempArray = [...userList];
      for (let val of tempArray) {
        let docname = "";
        let docDetails = "";
        let doc_no = "";
        if(val.documents){
          Object.entries(val.documents).forEach(item => { // iterate over documents
            if((item[1].files && item[1].files.length) || item[0] == 'email_id'){
              docname = item[0];
              doc_no = item[1].doc_no;
              docDetails = item[1].files && item[1].files.length ? item[1].files[0] : '';
            } 
          })
        }
        // form table data
        let data = createData(
          val.company_name ? val.company_name : '',
          val.organization_type ? val.organization_type : '',
          new Date(val.created_at).toLocaleDateString(),
          val.authorized_at ?  new Date(val.authorized_at).toLocaleDateString(): '', // get approved date
          "",
          docname,
          docDetails,
          val?.job_posted ? val.job_posted.length : 0,
          doc_no,
          val.last_login ? new Date(val.last_login).toLocaleDateString() : '', // get last_login date
          val.verification_status,
          val._id,
          val.profile_pic + '?' + Date.now(),
        );
        employerTable.push(data);
      }
    }
    setTable(employerTable);
    setRows(employerTable); // get table data
    setTable((state) => {
      return state;
    });
    return employerTable;
  };

  // download file url form fileId
  const getFileUrl = async (fileId) => {
    let fileUrl = "";
    // return if file is not present
    if (!fileId) {
      return;
    }
    if (fileId) {
      fileUrl = await downloadFile(fileId);
    }
    return fileUrl;
  };

  const getFile = async (fileId) => {
    const fileUrl = await getFileUrl(fileId);
    await download(fileUrl);
  };

  // update page count
  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/authentication-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/authentication-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };

  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };

  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/authentication-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/authentication-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };

  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };

  /**
   * @description method for redirecting to add Employer form
   */
    const goToAddEmployer = () => {
    history.push('/create-employer');
  }

  /**
   * @description method to calculate mix and max date allowed to download table data
   * @param {event} e fetch click event 
   */
  const maxDate = (e) => {
    let endDate = moment(e.target.value).add(30, "Days");
    if (endDate > moment(new Date())) {
      setEndMaxDate(moment(new Date()).format("YYYY-MM-DD"));
    } else {
      setEndMaxDate(endDate.format("YYYY-MM-DD"));
    }
  }

  /**
   * @description method to open data download modal
   */
  const dataModalOpen = () => {
    setOpenDateModal(true);
  };

  /**
   * @description method to close data download modal
   */
  const dataModalClose = () => {
    setOpenDateModal(false);
  };

  /**
   * @description method to fetch and downlaod 30 days employer data
   */
  const downloadEmployerData = async (val) => {
    try {
      let startDate = moment(val.start_date).startOf('days').toString(); // set to 12:00
      let endDate = moment(val.end_date).endOf('days').toString(); // set to 11:59
      let data = {
        time_filter: {
          from: Date.parse(startDate), 
          to: Date.parse(endDate)
        }
      };
      let response = await dispatch(adminActions.downloadEmployerData(data));
      if (response.value.success) {
        let data = [];
        for (let val of response.value.data){
          data.push({
            'Company Name' : val.company_name,
            'Organization Type' : val && val.organization_type && val.organization_type.length ? val.organization_type : 'N/A', // If Organization type is not Available sending 'N/A'
            'Industry' : val.company_category,
            'Contact Person Name' : val.name,
            'Email Address' : val.email,
            'Contact Person Number' : val.contact,
            'Company Address' : val.company_address,
            'Approved Date' : val.authorized_at,
            'Pincode' : val.pincode,
            'Status' : val.verification_status,
            'No of job posted' : val.job_posted,
            'Created At': (new Date(val.created_at)).toLocaleDateString() 
          })
        }
        setCSVData(data);
        csvLink.current.link.click();
      }
      dataModalClose();
    } catch (error) {
      console.log(error);
    }
  };
  // Employer dropdown options array
  const EmpDropdown = [
    { name: 'All Registrations', value: 'All' },
    { name: 'In Progress', value: 'In Progress' },
    { name: 'Not Initiated', value: 'Not Initiated' },
    { name: 'Approved', value: 'Approved' },
    { name: 'Not Approved', value: 'Declined' },
  ];
  return (
    <>
      <div className="reg_main employer_section">
        <div className="container-fluid">
          <div className="data_download">
          { commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_UPDATE]) ? 
            <button type="button" onClick={dataModalOpen}>
              Download CSV
            </button>
            : ''}
          </div>
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Employers Count : {employerCount}</p>
                <div className="timefilterdropdown">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    defaultValue={verificationStatus} //default check option from dropdowm with params value
                    onClick={(e) => setStatus(e.target.value)}>
                    {EmpDropdown.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <p> From </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="from"
                    onChange={(e) => setFrom(Date.parse(e.target.value))}
                    placeholder="Choose Date"
                  />
                </div>
                <p> To </p>
                <div className="timefilterdropdown">
                  <input
                    type="date"
                    className="form-control"
                    max={now}
                    id="to"
                    placeholder="Choose Date"
                    onChange={(e) => {
                      let date = new Date(e.target.value);
                      date.setDate(date.getDate() + 1);
                      setTo(Date.parse(date));
                    }}
                  />
                </div>
              </div>
              { commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMPLOYERS.EMPLOYER_CREATE]) ?
                <div className="add_saarthi" onClick={goToAddEmployer}>
                <FaUserPlus />
                <div>
                  <p>Add Employer</p>
                </div>
              </div> : ''
              }
              {/* searching-section start from here */}
              <div className="table_menu_ryt">
                {/* sort-icon-section */}
              {_handleSortIcon()}
                <div className="searchbar_main">
                  <SearchBar
                    value={searched}
                    onRequestSearch={(searchVal) => handleSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className="searchbar"
                  />
                </div>
              </div>
              {/* Showing tooltip for ascending and descending icon */}
              <ReactTooltip /> 
              {/* searching-section end */}
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                            <div className="employer_company_details">
                                <img 
                                  src={row.profile_pic} 
                                  alt="company-logo" 
                                  className="img-fluid company_logo"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = person;
                                  }}
                                />
                                <span className="employer_company_name">{row.companyname}</span>
                            </div>
                            </TableCell>
                            <TableCell align="center">{row.organization_type ?  row.organization_type.charAt(0).toUpperCase() + row.organization_type.slice(1) : 'N/A'}</TableCell>
                            <TableCell align="center">{row.regdate}</TableCell>
                            <TableCell align="center">{row.authorized_at ? row.authorized_at : '-'}</TableCell>
                            <TableCell align="center">
                            <Link to={{pathname: `/employer-profile`, search:`?emp_id=${row.id}`, state: 'registration'}}>View Details</Link>
                            </TableCell>
                            <TableCell align="center">
                              {row.docname ? row.docname.replace('_', ' ').toUpperCase() : "N/A"}
                            </TableCell>
                            <TableCell align="center">
                            {!row.doc_no && !row.docdetails ? ("N/A") : ("")}
                              <p>{row.doc_no}</p>
                              {row.docdetails == "" ? ("") : (
                                (
                                  <>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        getFile(row.docdetails);
                                      }}
                                    >
                                      Download
                                    </button>
                                    <i>|</i>
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        const url = await getFileUrl(row.docdetails);
                                        setFileUrl(url);
                                        setOpenDoc(url && url.length ? true : false);
                                      }}
                                    >
                                      view
                                    </button>
                                  </>
                                )
                              )}
                            </TableCell>
                            <TableCell align="center">{row.job_posted}</TableCell>
                            {/* last-login-date section start from here */}
                            <TableCell align="center">
                              {row.last_login ? row.last_login :'-'}
                            </TableCell>
                            {/* last-login-date section end */}
                            <TableCell align="right">
                              <span
                                className={
                                  row.action === "Approved"
                                    ? "approve"
                                    : row.action === "Declined"
                                    ? "decline"
                                    : row.action === "Not Initiated"
                                    ? "inactive"
                                    : ""
                                }
                              >
                                {row.action}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button
                    type="button"
                    onClick={() => next(page + 1, sort)}
                    disabled={
                      userList && userList.data && userList.data.length === 30
                        ? false
                        : true
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* document dialog box */}
      <Dialog
        open={openDoc}
        onClose={handleClose}
        maxWidth="lg"
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent style={{ padding: "0px" }}>
          {fileUrl && get_url_extension(fileUrl) === "pdf" ? (
            <Pdf pdf={fileUrl} />
          ) : (
            <img src={fileUrl} alt="Document" />
          )}
        </DialogContent>
      </Dialog>
      {/* open table data download modal */}
      <Dialog
        open={openDateModal}
        onClose={dataModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Enter Start Date & End Date"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={dataModalClose}><FaTimes /></button>
        <DialogContent className="reason_modal_content data_modal" style={{ padding: "0px" }}>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(downloadEmployerData)}>
              <div className="form-group">
                <label htmlFor="start_date">
                  <span className="datelabel">From</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="start_date"
                  id="start_date"
                  placeholder="Enter Start Date"
                  onChange={(e) => {
                    setStartDate((e.target.value));
                    maxDate(e);
                  }}
                  className={classNames("form-control", {
                    "is-invalid": errors.start_date,
                  })}
                  ref={register({
                    required: "Please enter Start Date",
                  })}
                  max={new Date()}
                />
                {errors.start_date && (
                  <span className="error_font text-danger">
                    {errors.start_date.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="end_date">
                  <span className="datelabel">To</span>
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  name="end_date"
                  id="end_date"
                  placeholder="Enter End Date"
                  className={classNames("form-control", {
                    "is-invalid": errors.end_date,
                  })}
                  ref={register({
                    required: "Please enter End Date",
                  })}
                  onChange={(e) => setEndDate((e.target.value))}
                  min={startDate}
                  max={endMaxDate}
                />
                {errors.end_date && (
                  <span className="error_font text-danger">
                    {errors.end_date.message}
                  </span>
                )}
              </div>
              <button type="submit" className="reasonbtn">SUBMIT</button>
              <CSVLink 
                data={csvData}
                filename='EmployerData.csv'
                className='hidden'
                ref={csvLink} 
                target="_blank" 
              />
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
