import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import person from "../../../assets/images/admin/person.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import building from "../../../assets/images/admin/building.svg";
import city from "../../../assets/images/admin/city.svg";
import group_people from "../../../assets/images/admin/group_people.svg";
import jobseeker from "../../../assets/images/admin/jobseeker.svg";
import role from "../../../assets/images/admin/role.svg";
import calendra from "../../../assets/images/admin/calendra.svg";
import email from "../../../assets/images/admin/email.svg";
import eye from "../../../assets/images/admin/eye.svg";
import { useHistory } from "react-router-dom";
import { useQuery } from "admin/services/commonservice";

export const SaarthiProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saarthiDetails } = useSelector((state) => state.saarthiReducer);
  const query = useQuery();

  useEffect(() => {
    try{
      // get saarthi details
      dispatch(saarthiActions.saarthiDetails(query.get('user_id')))
    } catch(e){
      console.log('error', e);
    }
  }, [dispatch]);

  return (
    <>
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Sarthi Profile Details</h6>
            <div className="employer_profile_form">
              <button type="button" className="backbtn" onClick={() => history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form action="">
                <div className="profile_img">
                  <img src={person} />
                </div>

                <div className="profile_name">
                  <h4>{saarthiDetails && saarthiDetails.hasOwnProperty('name') ? saarthiDetails.name : ''}</h4>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Name</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('name') ? saarthiDetails.name : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Tehsil</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('tehsil') ? saarthiDetails.tehsil : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={city} alt="industry" className="img-fluid" />
                    <span>District</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('district') ? saarthiDetails.district : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={eye} alt="industry" className="img-fluid" />
                    <span>Sarthi Code</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('saarthi_code') ? saarthiDetails.saarthi_code : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={group_people} alt="industry" className="img-fluid" />
                    <span>Junior Sarthi</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('saarthi_count') ? saarthiDetails.saarthi_count : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={jobseeker} alt="industry" className="img-fluid" />
                    <span>Job Seeker Registered</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('js_count') ? saarthiDetails.js_count : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={role} alt="industry" className="img-fluid" />
                    <span>Sub Role</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('sub_role') ? (
                      saarthiDetails.sub_role === 1
                      ? 'A'
                      : saarthiDetails.sub_role === 2
                      ? 'B'
                      : saarthiDetails.sub_role === 3
                      ? 'C'
                      : ''
                    ) : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={calendra} alt="industry" className="img-fluid" />
                    <span>Registeration Date</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('created_at') ? new Date(saarthiDetails.created_at).toLocaleDateString() : ''}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <img src={email} alt="industry" className="img-fluid" />
                    <span>Email Address</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('email') ? saarthiDetails.email : ''}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
