import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnhancedTableHead from "../../common/enhancedTableHead";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { IoAddCircleOutline } from "react-icons/io5"; // roles Icon
import { Multiselect } from "multiselect-react-dropdown";
import * as adminActions from "redux/actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { RiEdit2Fill } from "react-icons/ri";
import { regexValidation } from "utils/regexValidation";
import { forError } from "../../services/commonservice";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
}));
function createData(id, employerName, email, mobileNumber, createdAt, roleName, action) {
    return {
        id, employerName, email, mobileNumber, createdAt, roleName, action
    };
}

// Employer Table Columns
const headCells = [
    { id: 'employerName', numeric: false, disablePadding: false, label: 'Name', align: 'left' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email', align: 'center' },
    { id: 'mobileNumber', numeric: false, disablePadding: false, label: 'Mobile Number', align: 'center' },
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created Date', align: 'center' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Custom sort when click on individual column
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const AddHLJEmployer = () => {
    const { register, errors, handleSubmit, reset } = useForm({
        mode: "onChange",
    });
    const classes = useStyles();
    const [table, setTable] = useState([]);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the Company Name
    const [rows, setRows] = useState([]); // variable use for storing table data
    const [openDateModal, setOpenDateModal] = useState(false); // open and close employer modal
    const [rolesNameValue, setRolesNameValue] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [roleNameError, setroleNameError] = useState('');
    const [employersCount, setEmployersCount] = useState(0);
    const [employerData, setEmployerData] = useState([]);
    const [roleName, setRoleName] = useState([]);
    const [updateApiValue, setUpdateApi] = useState('');
    const [roleId, setRoleId] = useState('');
    const dispatch = useDispatch();
    const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage

    useEffect(() => {
        apiGetHLJEmployer(); // api hittong for get all employers
        apiGetRoles(); // api hitting for get all roles
    }, [dispatch]);

    // API for get all roles
    const apiGetRoles = async () => {
        try {
            let response = await dispatch(adminActions.getRole());
            if (response.value.success) {
                setRoleName(response.value.data); // get & store roles
            }
        } catch (error) {
            console.log(error);
        }
    };

    // API for get all employers
    const apiGetHLJEmployer = async () => {
        try {
            let response = await dispatch(adminActions.getHLJEmployer());
            if (response.value.success) {
                setEmployersCount(response.value.data.length); // get total employers count
                handleTableData(response.value.data); // get & store employers
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // Open Modal for create and update hlj-employers
    const dataModalOpen = (name, row, roleName) => {
        // Modal for Update hlj-employers
        if (name === 'update-employer') {
            let filterData = row.roleName.filter((value) => value.id === roleName._id);
            setSelectedValue(filterData);
            setOpenDateModal(true);
            setEmployerData(row);
            let selectedData = [];
            for (let val of filterData) {
                const data = roleName.filter((value) => value._id === val);
                selectedData.push(data);
            }
            let data = Array.prototype.concat.apply([], selectedData);
            setRolesNameValue(data);
            setUpdateApi(true);
            setRoleId(row.id);
            // Modal for create hlj-employers
        } else if (name === 'create-employer') {
            setRolesNameValue('');
            setEmployerData('');
            setOpenDateModal(true);
            setUpdateApi(false);
        }
    };

    // Close Modal for create and update hlj-employers
    const dataModalClose = () => {
        setOpenDateModal(false);
    };

    // Select roles name values
    const _handleselectRolesName = (e) => {
        let value = e.map((value) => value._id);
        setRolesNameValue(e);
        setSelectedValue(value);
        setroleNameError("");
    }

    // Remove roles name value
    const _handleRemovedRolesName = (e) => {
        let value = e.map((value) => value._id);
        setRolesNameValue(e);
        setSelectedValue(value);
    }

    // Fill hlj-employers table
    const handleTableData = async (employersDataList) => {
        let hljEmployersTable = [];
        if (employersDataList && employersDataList.length) {
            let tempArray = [...employersDataList]
            for (let val of tempArray) {
                let data = createData(
                    val._id ? val._id : '',
                    val.name ? val.name : '',
                    val.email ? val.email : '',
                    val.contact ? val.contact : '',
                    val.created_at ? new Date(val.created_at).toLocaleDateString() : '',
                    val.roles ? val.roles : ''
                );
                hljEmployersTable.push(data);
            }
        }
        setTable(hljEmployersTable);
        setRows(hljEmployersTable);
        setTable((state) => {
            return state;
        })
        return hljEmployersTable;
    }

    // API for create and update hlj-employers
    const handlePostEmployers = async (val) => {
        if (rolesNameValue.length > 0) {
            try {
                let data = {
                    ...val,
                    _id: roleId,
                    roles: selectedValue
                };
                if (updateApiValue === false) {
                    delete data._id;
                }
                let response = updateApiValue === true ? await dispatch(adminActions.updateHLJEmployer(data)) : await dispatch(adminActions.postHLJEmployer(data));
                if (response.value.success) {
                    apiGetHLJEmployer() // hitting api for get all employers
                    setRolesNameValue('');
                    reset();
                    dataModalClose();
                    setroleNameError("");
                }
            } catch (e) {
                forError(e.data.error_code, "Failure!");
            }
        } else {
            setroleNameError("Please select any role");
        }

    }
    return (
        <>
            <div className="reg_main employer_section">
                <div className="container-fluid">
                    <div className="data_download">
                        {commonService.showHideBaseOnPermission(adminPermission, [appSetting.USERS.USER_CREATE]) ?
                            <div type="button" className="new-role-button" onClick={() => dataModalOpen('create-employer')}>
                                <span><IoAddCircleOutline /> Add Employer</span>
                            </div> 
                            : ''}
                    </div>
                    <div className="reg_inner">
                        <div className="table_menu">
                            <div className="table_menu_lft">
                                <p>HLJ-Employers  Count : {employersCount}</p>
                            </div>
                        </div>
                        <div className="table_box">
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="enhanced table">
                                    {/* Table Action Toolbar Start From Here */}
                                    <EnhancedTableHead
                                        headCells={headCells}
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={table.length}
                                    />
                                    {/* Table Action Toolbar End */}
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                return (
                                                    <TableRow hover key={row.id}>
                                                        <TableCell align="left">{row.employerName}</TableCell>
                                                        <TableCell align="center">{row.email}</TableCell>
                                                        <TableCell align="center">{row.mobileNumber}</TableCell>
                                                        <TableCell align="center">{row.createdAt}</TableCell>
                                                        <TableCell align="right">
                                                            {commonService.showHideBaseOnPermission(adminPermission, [appSetting.USERS.USER_CREATE]) ?
                                                                <span className="pencil-edit-icon" onClick={() => dataModalOpen('update-employer', row, roleName)}>
                                                                    <RiEdit2Fill className="edit-mail-icon" />
                                                                </span> : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={classes.root}>
                                <div className="pagination">
                                    <button
                                        type="button"
                                    >
                                        Prev
                                    </button>
                                    <span>1</span>
                                    <button
                                        type="button"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* create and update hlj-employers modal start from here */}
            <Dialog
                open={openDateModal}
                onClose={dataModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="create-role-modal"
            >
                <DialogTitle id="alert-dialog-title" className="role-title">
                    {updateApiValue === true ? "Update HLJ-Employer" : " Create HLJ-Employer"}
                </DialogTitle>
                <button className="modal-cross-btn" onClick={dataModalClose}><FaTimes /></button>
                <DialogContent className="reason_modal_content">
                    <DialogContentText id="alert-dialog-description">
                        <form onSubmit={handleSubmit(handlePostEmployers)}>
                            <div className="form-group">
                                <label htmlFor="name">
                                    <span className="datelabel">Name</span>
                                </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="enter name"
                                    defaultValue={employerData.employerName ? employerData.employerName : ''}
                                    className={classNames("form-control", {
                                        "is-invalid": errors.name,
                                    })}
                                    ref={register({
                                        required: "Please enter name",
                                        minLength: {
                                            value: 2,
                                            message: "Please enter minimum 2 character",
                                        },
                                        maxLength: {
                                            value: 30,
                                            message: "Please enter maximum 30 character",
                                        },
                                        pattern: {
                                            value: regexValidation.ROLE_NAME,
                                            message: "Numbers and special characters not allowed",
                                        },
                                    })}
                                />
                                {errors.name && (
                                    <span className="error_font text-danger">
                                        {errors.name.message}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">
                                    <span className="datelabel">Email</span>
                                </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="enter email"
                                    defaultValue={employerData.email ? employerData.email : ''}
                                    disabled={employerData.email ? true : false}
                                    className={classNames("form-control", {
                                        "is-invalid": errors.email,
                                    })}
                                    ref={register({
                                        required: "Please enter email",
                                        pattern: {
                                            value: regexValidation.EMAIL,
                                            message: "Please enter correct email address",
                                        }
                                    })}
                                />
                                {errors.email && (
                                    <span className="error_font text-danger">
                                        {errors.email.message}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact">
                                    <span className="datelabel">Contact Number</span>
                                </label>
                                <input
                                    type="number"
                                    autoComplete="off"
                                    name="contact"
                                    placeholder="enter contact"
                                    defaultValue={employerData.mobileNumber ? employerData.mobileNumber : ''}
                                    className={classNames("form-control", {
                                        "is-invalid": errors.contact,
                                    })}
                                    ref={register({
                                        required: "Please enter contact number",
                                        pattern: {
                                            value: regexValidation.PHONE,
                                            message: "Please enter valid mobile number",
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Maximum 10 digits are allowed",
                                        },
                                    })}
                                />
                                {errors.contact && (
                                    <span className="error_font text-danger">
                                        {errors.contact.message}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="rolename">
                                    <span className="datelabel">Role Name</span>
                                </label>
                                <Multiselect options={roleName}
                                    onSelect={(e) => _handleselectRolesName(e)}
                                    onRemove={(e) => _handleRemovedRolesName(e)}
                                    selectionLimit={20}
                                    isObject={true}
                                    placeholder="Select roles"
                                    displayValue="name"
                                    name="roles"
                                    selectedValues={rolesNameValue}
                                />
                                {roleNameError && (
                                    <span className="error_font text-danger">
                                        {roleNameError}
                                    </span>
                                )}
                            </div>
                            <button type="submit" id="submit" className="submit-button">{updateApiValue === true ? 'UPDATE' : 'SUBMIT'}</button>
                        </form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* create and update hlj-employers modal end */}
        </>
    );
};
