import {React, useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdminHeader } from "../../common/header";
import person from "../../../assets/images/admin/person.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import { GiReceiveMoney } from "react-icons/gi";
import { BiBriefcase } from "react-icons/bi";
import { VscBriefcase, VscBell } from "react-icons/vsc";
import { BsTools } from "react-icons/bs";
import { BsPerson } from "react-icons/bs";
import { MdDescription } from "react-icons/md";
import { FaRegFileVideo } from "react-icons/fa";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { uploadFile } from "../../../utils/fileService";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { RiGroup2Line } from "react-icons/ri"; // Icon for job opening
import * as saarthiActions from "redux/actions/SaarthiActions";
import * as adminActions from "redux/actions/AdminActions";

export const JobsListView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  let jobId = query.get("id");
  const { jobDetails } = useSelector((state) => state.saarthiReducer);
  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState({open : false, message: ""});
  const [error, setError] = useState({open : false, message: ""});
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(saarthiActions.getSaarthiJobDetails(jobId))
      .then((response)=> {
        if(response && response.value.success){
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
    }
  }, [loaded, dispatch, jobId]);
  
  return (
    <>
      {error.open && <Alert severity="error" className="alert-toast">{error.message}</Alert>}
      {success.open && <Alert severity="success" className="alert-toast">{success.message}</Alert>}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Job Post Details</h6>
            <div className="employer_profile_form audio_profile_form">
              <button type="button" className="backbtn" onClick={()=>history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form action="">
                <div className="profile_img">
                  <img src={jobDetails && jobDetails.company_logo} alt="company-logo" className="img-fluid company_logo"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = person;
                    }} />
                </div>
                <div className="profile_name job_post_company_name">
                  <h4>{jobDetails && jobDetails.company_name && jobDetails.company_name.length ?  jobDetails.company_name : ""}</h4>
                </div>

                <div className="job_profile_details">
                  <h2>{jobDetails && jobDetails.job_title && jobDetails.job_title.length ? jobDetails.job_title : ""}</h2>
                  <p>{jobDetails && jobDetails.job_location && jobDetails.job_location.length ? jobDetails.job_location : ""} </p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <GiReceiveMoney />
                    <span>Salary</span>
                  </label>
                  <p><span>{jobDetails && jobDetails.salary_from ? jobDetails.salary_from : ""}</span> - <span>{jobDetails && jobDetails.salary_to? jobDetails.salary_to : ""}</span> / month</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <BiBriefcase />
                    <span>Experience Required</span>
                  </label>
                  <p>{jobDetails && jobDetails.experience===0
                  ? "Fresher"
                  : jobDetails && jobDetails.experience===1
                  ? "Less than 1 year"
                  : jobDetails && jobDetails.experience===2
                  ? "1-2 years"
                  : jobDetails && jobDetails.experience===3
                  ? "2-4 years"
                  : jobDetails && jobDetails.experience===4
                  ? "4-6 years"
                  : jobDetails && jobDetails.experience===5
                  ? "6-8 years"
                  : jobDetails && jobDetails.experience===6
                  ? "8-10 years"
                  : jobDetails && jobDetails.experience===7
                  ? "10+ years"
                  : ""}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <BsPerson />
                    <span>Gender</span>
                  </label>
                  <p>{jobDetails && jobDetails.gender === 0
                    ?"All"
                    : jobDetails && jobDetails.gender === 1
                    ?"Female"
                    : jobDetails && jobDetails.gender === 2
                    ?"Male"
                    : jobDetails && jobDetails.gender === 3
                    ? "Other"
                    : ""}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <BiBriefcase />
                    <span>Job Type</span>
                  </label>
                  <p>{jobDetails && jobDetails.job_type === 0
                      ? "Office"
                    :jobDetails && jobDetails.job_type === 1
                    ? "Field"
                    : jobDetails && jobDetails.job_type === 2
                    ? "Shift"
                     : jobDetails && jobDetails.job_type === 3
                    ? "Part Time"
                  :""}</p>
                </div>
                {/* job-opening-section start from here */}
                <div class="form-group">
                  <label htmlFor="">
                    <RiGroup2Line />
                    <span>Job Opening</span>
                  </label>
                  <p>{jobDetails.openings}</p>
                </div>
                {/* job-opening-section end */}
                <div class="form-group">
                  <label htmlFor="">
                    <VscBell />
                    <span>Job Timing</span>
                  </label>
                  <p>
                    {jobDetails.job_timings === 0
                    ? "General"
                    : jobDetails.job_timings === 1
                    ? "Rotation"
                    : ""}
                  </p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <BsTools />
                    <span>Skills Required</span>
                  </label>
                  <p>{jobDetails && jobDetails.skills && jobDetails.skills.length ? jobDetails.skills : ""}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <MdDescription />
                    <span>Job Description</span>
                  </label>
                  <p className="description">{jobDetails && jobDetails.description && jobDetails.description.length ? jobDetails.description : ""}</p>
                </div>

                <div class="form-group">
                  <label htmlFor="">
                    <FaRegFileVideo/>
                    <span>Animated Jd Required</span>
                  </label>
                  <p>{jobDetails && jobDetails.hasOwnProperty('animated_jd_required') && jobDetails.animated_jd_required ? "Yes" : "No" }</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
