export const regexValidation = {
  EMAIL: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
  PHONE: /^[6-9]\d{9}$/,
  COMPANY_NAME: /^[a-zA-Z0-9\s]([a-zA-Z0-9]|[- '&'._%^$*+-?()[{}\\\]—@#"|/!`~<>])*$/,
  COMPANY_ADDRESS: /^[a-zA-Z0-9\s]([a-zA-Z0-9]|[- ,.])*$/,
  PERSON_NAME: /^[a-zA-Z\s._]*$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  SAARTHIPASSWORD: /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  YOUTUBE: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  ROLE_NAME: /^[a-zA-Z\s._:-]*$/,
  EMAIL_FILTERS: /\bgmail\b|\yahoo\b|\hotmail\b|\outlook\b|\zoho\b|\icloud\b|\aol\b|\aim\b/gi
};
