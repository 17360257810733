import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import { FaTimes } from "react-icons/fa";
import person from "../../../assets/images/admin/person.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import email from "../../../assets/images/admin/email.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import * as adminActions from "redux/actions/AdminActions";
import appSetting from '../../common/permissionValue';
import storage from "utils/storage";
import * as commonService from "utils/CommonService.js";

export const EmailRequest = () => {
  const query = new URLSearchParams(useLocation().search);
  let userId = query.get("id");
  const [open, setOpen] = useState(false); // variable use for open modal for decline reason
  const [loaded, setLoaded] = useState(false); // variable use for show loader
  const [fileId, setFileId] = useState("");
  const [reason, setReason] = useState(""); // variable use for storing declined reason
  const [success, setSuccess] = useState({ open: false, message: "" }); // variable use for success toast-notification
  const [error, setError] = useState({ open: false, message: "" });
  const { userDetailsById } = useSelector((state) => state.userReducer); // get user details
  const adminPermission = storage.get("humlog_emp_permission"); // get permisison from local storage
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      // API for get user data fetch by Id
      let query = {
        emp_id: userId
      }
      dispatch(userActions.getUserDataBySarthi(query))
        .then((response) => {
          if (response && response.value.success) {
            setFileId(response.value.data.documents);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loaded, dispatch, userId]);
  // showing open reason modal
  const handleReasonModal = () => {
    setOpen(true);
  };
  // closing open reason modal
  const handleClose = () => {
    setOpen(false);
  };
  const handleOthersChange = (event) => {
    setReason(event.target.value);
  };
  // Api to Approved email address
  /**
   * @description method to approved email address
   * @param {Object} val new email address
   */
  const approvedEmailAddress = (val) => {
    try {
      let data = {
        update_email_request: "Approved",
        email: val.newEmail,
      };
      dispatch(adminActions.updateUser(data, userId))
        .then((res) => {
          if (res.value.success) {
            setSuccess({ open: true, message: "Email Approved Successfully!" });
            setTimeout(() => {
              history.push("/request-list");
            }, 2000);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  // Api to declined email address
  /**
   * @description method to declined email address
   * @param {Object} val reason value
   */
  const declinedEmailAddress = (value) => {
    try {
      let data = {
        update_email_request: value,
        update_email_feedback: reason,
      };
      dispatch(adminActions.updateUser(data, userId))
        .then((res) => {
          if (res.value.success) {
            setSuccess({ open: true, message: "Email Declined Successfully!" });
            handleClose();
            setTimeout(() => {
              history.push("/request-list");
            }, 2000);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* error-toast-notification-section start from here */}
      {error.open && (
        <Alert severity="error" className="alert-toast">
          {error.message}
        </Alert>
      )}
      {/* error-toast-notification-section end */}
      {/* success-toast-notification-section start from here */}
      {success.open && (
        <Alert severity="success" className="alert-toast">
          {success.message}
        </Alert>
      )}
      {/* success-toast-notification-section end */}
      {/* email-request-card-section start from here */}
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Email Request</h6>
            <div className="employer_profile_form">
              {/* back-button-section start from here */}
              <button
                type="button"
                className="backbtn"
                onClick={() => history.goBack()}
              >
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              {/* back-button-section end */}
              {/* form-section start from here */}
              <Form onSubmit={handleSubmit(approvedEmailAddress)}>
                <div className="profile_img">
                  {/* user-image-section start from here */}
                  <img
                    src={userDetailsById && userDetailsById.profile_pic}
                    alt="company-logo"
                    className="img-fluid company_logo"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = person;
                    }}
                  />
                </div>
                {/* user-image-section end */}
                {/* user-name-section start from here */}
                <div className="profile_name">
                  <h4>
                    {userDetailsById &&
                    userDetailsById.company_name &&
                    userDetailsById.company_name.length > 0
                      ? userDetailsById.company_name
                      : ""}
                  </h4>
                </div>
                {/* user-name-section end */}
                {/* old-email-section start from here */}
                <Form.Group className="mt-3">
                  <Form.Label className="">
                    <img
                      src={email}
                      alt="industry"
                      className="img-fluid mail-icon"
                    />
                    <span>
                      <h2 className="prnl_head_aa">Old Email</h2>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    require
                    autoComplete="off"
                    placeholder="Enter Email Address"
                    name="email"
                    disabled
                    defaultValue={userDetailsById.email}
                  />
                </Form.Group>
                {/* old-email-section end */}
                {/* new-email-section start from here */}
                <Form.Group className="mt-3">
                  <Form.Label className="">
                    <img
                      src={email}
                      alt="industry"
                      className="img-fluid mail-icon"
                    />
                    <span>
                      <h2 className="prnl_head_aa">New Email</h2>
                    </span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    require
                    autoComplete="off"
                    placeholder="Please enter email"
                    name="newEmail"
                    defaultValue={
                      userDetailsById && userDetailsById.email
                        ? userDetailsById.new_email
                        : ""
                    }
                    className={classNames("form-control", {
                      "is-invalid": errors.newEmail,
                    })}
                    ref={register({
                      required: "Please enter email address",
                      pattern: {
                        value: regexValidation.EMAIL,
                        message: "Please enter valid email address",
                      },
                    })}
                  />
                  {errors.newEmail && (
                    <span className="text-danger txt">
                      {errors.newEmail.message}
                    </span>
                  )}
                </Form.Group>
                {/* new-email-section end */}
                {/* Approved-button-section start from here */}
                <div className="employer_profile_btns">
                  {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMAILS.EMPLOYER_UPDATE]) ? 
                  <button className="approvebtn">Approve</button> : ''}
                  {/* Approved-button-section end */}
                  {/* Declined-button-section start from here */}
                  {commonService.showHideBaseOnPermission(adminPermission, [appSetting.EMAILS.EMPLOYER_UPDATE]) ?
                  <button
                    type="button"
                    className="declinebtn"
                    onClick={handleReasonModal}
                  >
                    Decline
                  </button> : ''}
                  {/* Declined-button-section end */}
                </div>
              </Form>
              {/* form-section end */}
            </div>
          </div>
        </div>
      </div>
      {/* email-request-card-section end */}
      {/* Declined-modal-section start from here */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal"
      >
        <DialogTitle id="alert-dialog-title" className="reason_modal_title">
          {"Reason"}
        </DialogTitle>
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <DialogContent className="reason_modal_content">
          <DialogContentText id="alert-dialog-description">
            <form>
              <div class="form-group">
                <textarea
                  class="form-control"
                  id="otherreasontext"
                  onChange={handleOthersChange}
                  rows="3"
                  maxlength="100"
                  placeholder="Write a reason"
                ></textarea>
              </div>
              <button
                type="button"
                className="reasonbtn"
                onClick={() => {
                  declinedEmailAddress("Declined");
                }}
              >
                Submit
              </button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Declined-modal-section end */}
    </>
  );
};
