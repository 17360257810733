import React from "react";
import { Card, CardContent, Typography, Icon } from "@material-ui/core";
import { BiErrorCircle } from "react-icons/bi";

const style = {
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    fontSize: 160,
    lineHeight: "160px"
  }
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, classes: style };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Card className="" style={this.state.classes.root}>
            <CardContent>
              <div className="text-center p-5">
                <Icon style={this.state.classes.icon} color="primary" className="text-center">
                  <BiErrorCircle />
                </Icon>
                <Typography
                  variant="h2"
                  color="primary"
                  className="text-center p-5"
                >
                  Something Went Wrong!
                </Typography>
              </div>
            </CardContent>
          </Card>
        </>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;