import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as saarthiActions from "redux/actions/SaarthiActions";
import { uploadFile } from "../../../utils/fileService";

export const  UpdateVideoResume = ({ handelClose, userID }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });
  // save company video
  const [vedioErr,setVedioErr] = useState(null)
  const [companyVideo, setCompanyVideo] = useState();

  // save company video
  const uploadVedioResume = async (e) => {
    if (e.target.files[0].size < 52428800){
      setCompanyVideo(e.target.files[0])
      setVedioErr(null)
    }else {
      setVedioErr("File size should be less than 50 MB")
    }
  };
  const update = async (val) => {
    try {
    let videoUrl;
    let data = { ...val, _id: userID };
    //upload company video
    if(companyVideo){
      videoUrl = await uploadFile(companyVideo,'video', '', userID);
      data.video_resume = videoUrl;
    }
    dispatch(saarthiActions.updateJSProfile(data))
      .then((res) => {
        if (res.value.success) {
          console.log('res', res);
        }
      })
      .catch((err) => console.log('err', err));
    }catch (e) {
      // setErrorMessage(e);
      console.log("failed to update profile",e)
    }
    handelClose();
  };
  return (
    <>
    <div>
      <form onSubmit={handleSubmit(update)}>
        <div className="changevideobox">
          <input type="file" accept="video/*" className="" id="vedio-resume" onChange={uploadVedioResume} />
          <button 
            type="submit"
            className="canceltn"> upload 
          </button>
        </div>
      </form>
    </div>
    </>
  );
};