import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as adminActions from "redux/actions/AdminActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../services/commonservice";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from "../../common/enhancedTableHead";
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im";
import ReactTooltip from "react-tooltip";
import { useForm } from "react-hook-form";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import gear from "../../../assets/images/admin/gear.svg";
import location_pin from "../../../assets/images/admin/location-pin.svg";
import video from "../../../assets/images/admin/video.svg";
import language from "../../../assets/images/admin/language.svg";
import birthday from "../../../assets/images/admin/birthday.svg";
import gender from "../../../assets/images/admin/gender.svg";
import qualification from "../../../assets/images/admin/qualification.svg";
import technical_certificate from "../../../assets/images/admin/technical_certificate.svg";
import skills from "../../../assets/images/admin/skills.svg";
import briefcase from "../../../assets/images/admin/briefcase.svg";
import { FaTimes, FaRegPlayCircle } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { Player } from "admin/common/player";

const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(id, jobSeeker, regdate, homeDistrict, contact, action) {
  return { id, jobSeeker, regdate, homeDistrict, contact, action};
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'jobSeeker', numeric: false, disablePadding: false, label: 'Job Seeker' },
  { id: 'regdate', numeric: false, disablePadding: false, label: 'Registration Date', align: 'center' },
  { id: 'homeDistrict', numeric: false, disablePadding: false, label: 'Home District', align: 'center' },
  { id: 'contact', numeric: true, disablePadding: false, label: 'Contact', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

export const AppliedJobSeekers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
  });
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [table, setTable] =useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [rows, setRows] = useState([]);
  const [sort, setSort] = useState();
  const sortingIconClicked= useRef(false);
  const query = useQuery();
  const jobID = query.get("job_id");
  const jobSeekerStatusValue = query.get("status"); // get jobseekers status value
  const [appliedJS, setappliedJS] = useState([]);

  useEffect(() => {
    fetchData();
    setTable([]);
  }, [dispatch, page, sort]);

  const fetchData = async () => {
    try {
      let query = {
        job_id: jobID,
        status: jobSeekerStatusValue
      };
      if (page >= 0) {
        query.page = page;
      }
      if (sort) {
        query.sort = sort;
      }
      dispatch(adminActions.getAppliedJobseekerList(query))
      .then((res) => {
        if (res && res.value.success) {
          setTableData(res.value.data.users_list);
          setappliedJS(res.value.data.users_list);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    } catch (error) {
      console.log("error",error);
    }
  } 

  // Handling sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @description set Table data method to update table values
   */
  const setTableData = async (jobSeekerList) => {
    let jobseekerTable = [];
    if (jobSeekerList && jobSeekerList.length) {
      let tempArray = [...jobSeekerList]
      for (let val of tempArray) {
        let data = createData(
          val._id ? val._id : '',
          val.name ? val.name.charAt(0).toUpperCase() + val.name.substring(1) : '',
          val.created_at ? new Date(val.created_at).toLocaleDateString() : '',
          val.address_home ? val.address_home : '',
          val.contact ? val.contact : '-'
        );
        jobseekerTable.push(data);
      }
    }
    setTable(jobseekerTable);
    setRows(jobseekerTable);
    setTable((state) => {
      return state;
    })
    return jobseekerTable;
  }

  // update page count
  const handleChange =(event, value) => {
    setPage(value - 1);
  };

  // go to previous page
  const prev = () => {
    setPage(page-1);
  }

  // got to next page
  const next = () => {
    setPage(page+1);
  }

  /**
   * @description method to open/close the menu options
   * @param {param} e event 
   */
   const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('table_dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }

  /* function to close the dropdown when clicked outside */
  window.onclick = function (event) {
    if (!event.target.matches('.table_dropbtn')) {
      var dropdowns = document.getElementsByClassName('table_dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  const jobSeekerProfile = (e, id) => {
    history.push({pathname : '/applied-jobseeker-profile', search: "?job_id=" + id});
  }

  const handleSorting = () => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
    } else {
      setSort('Desc');
    }
  };

  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={handleSorting}
            clickable="true"
            className="sorting-icon mr-0"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={handleSorting}
            clickable="true"
            className="sorting-icon mr-0"
          />
        </span>
      );
    }
  };


  const [open, setOpen] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const handleClickOpen = (index) => {
    setOpen(true);
    setProfileDetails(appliedJS[index]);
  };
  const handleClose = () => {
    setOpen(false);
    setProfileDetails({});
  };
  const [modal, setModal] = useState(false);
  const onToggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft"></div>
              <div className="table_menu_ryt">
               {_handleSortIcon()}
              </div>
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                   <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">{row.jobSeeker}</TableCell>
                            <TableCell align="center">{row.regdate}</TableCell>
                            <TableCell align="center">{row.homeDistrict}</TableCell>
                            <TableCell align="center">{row.contact}</TableCell>
                            <TableCell align="right" style={{position: "relative"}}>
                                <div className="table_dropdownbox">
                                    <button type="button" className="table_moreoptns table_dropbtn" onClick={toggleMenu}>
                                    <FiMoreVertical />
                                    </button>
                                    <div className="table_dropdownmenu table_option_menu table_dropdown-content">
                                        <ul>
                                          <li>
                                              <a href="javascript:void(0)" onClick={()=>handleClickOpen(index)}>
                                                Open Profile
                                              </a>
                                          </li>
                                        </ul>
                                    </div>
                                </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination"> 
                  <button type="button" onClick={prev} disabled={page===0? true : false}>Prev</button>
                  <span>{page + 1}</span>
                  <button type="button" onClick={next} disabled={appliedJS && appliedJS.length === 30 ? false : true}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip />
      <Dialog
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal profile_details_modal"
      >
        <DialogContent style={{ padding: "0px" }}>
        <button className="modal-cross-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <div className="employer_profile applied_js_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Job Seeker Profile</h6>
            <div className="employer_profile_form jobseeker_profile_form">
              <button type="button" className="backbtn" data-tip="Back" onClick={() => history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <ReactTooltip />
              <form>
                <div className="profile_box">
                  <div className="profile_img">
                    <img
                      src={(profileDetails && profileDetails.profile_pic) ? profileDetails.profile_pic : person}
                      alt="Profile Picture"
                      className="img-fluid company_logo"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = person;
                      }}
                    />
                  </div>
                </div>

                <div className="profile_name">
                  <h4>
                    {profileDetails &&
                      profileDetails.name &&
                      profileDetails.name.length > 0
                      ? profileDetails.name
                      : ""}
                  </h4>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Name</span>
                  </label>
                  <p>
                    {
                      profileDetails &&
                      profileDetails.name
                      ? profileDetails.name
                      : ("")
                    }
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>About Me</span>
                  </label>
                  <p>
                    {profileDetails &&
                      profileDetails.about_me
                      ? profileDetails.about_me
                          .map((data) => data.label)
                          .join(", ")
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={gear} alt="industry" className="img-fluid" />
                    <span>Job Interested In</span>
                  </label>
                  <p>
                    {profileDetails &&
                      profileDetails.job_intrested &&
                      profileDetails.job_intrested.length > 0 &&
                      profileDetails.job_intrested
                        .map((val) => val.job_category)
                        .join(", ")}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={briefcase} alt="role" className="img-fluid" />
                    <span>Years Of Experience</span>
                  </label>
                  <p>
                    {profileDetails && profileDetails.experience === 0
                      ? "Fresher"
                      : profileDetails && profileDetails.experience === 1
                      ? "Less than 1 Year"
                      : profileDetails && profileDetails.experience === 2
                      ? "1-2 years"
                      : profileDetails && profileDetails.experience === 3
                      ? "2-4 years"
                      : profileDetails && profileDetails.experience === 4
                      ? "4-6 years"
                      : profileDetails && profileDetails.experience === 5
                      ? "6-8 years"
                      : profileDetails && profileDetails.experience === 6
                      ? "8-10 years"
                      : profileDetails && profileDetails.experience === 7
                      ? "10+ years"
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={skills} alt="industry" className="img-fluid" />
                    <span>Skills</span>
                  </label>
                  <p style={{ height: "auto", minHeight: "42px" }}>
                    {profileDetails &&
                      profileDetails.job_intrested &&
                      profileDetails.job_intrested.length > 0 &&
                      profileDetails.job_intrested.map((val) => {
                        return val.skills.map((skill) => skill).join(", ");
                      })}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={qualification}
                      alt="industry"
                      className="img-fluid"
                    />
                    <span>Qualification</span>
                  </label>
                  <p>
                    {profileDetails &&
                    parseInt(profileDetails.qualification) === 0
                      ? "below 10"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 1
                      ? "10 th pass"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 2
                      ? "12 th pass"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 3
                      ? "B.A"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 4
                      ? "B.Sc"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 5
                      ? "B.Com"
                      : profileDetails &&
                        parseInt(profileDetails.qualification) === 6
                      ? "Other"
                      : profileDetails.qualification}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={technical_certificate}
                      alt="industry"
                      className="img-fluid"
                    />
                    <span>Technical Certification</span>
                  </label>
                  <p>
                    {profileDetails && profileDetails.technical_cert
                      ? profileDetails.technical_cert.map((value, index) => {
                          return (
                            <span className="">
                              {value.cert}{" "}
                              {value.branch ? <>( {value.branch} )</> : <></>}
                            </span>
                          );
                        })
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={phone} alt="phone" className="img-fluid" />
                    <span>Mobile Number</span>
                  </label>
                  <p className="">
                    {profileDetails &&
                      profileDetails.contact &&
                      profileDetails.contact.length > 0
                      ? profileDetails.contact
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={gender} alt="industry" className="img-fluid" />
                    <span>Gender</span>
                  </label>
                  <p className="">
                    {profileDetails && profileDetails.gender === 0
                      ? "Female"
                      : profileDetails && profileDetails.gender === 1
                      ? "Male"
                      : profileDetails && profileDetails.gender === 2
                      ? "Others"
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={birthday} alt="industry" className="img-fluid" />
                    <span>Date Of Birth</span>
                  </label>
                  <p>
                    {(profileDetails &&
                      profileDetails.dob &&
                      profileDetails.dob.length) > 0
                      ? new Date(profileDetails.dob).toLocaleDateString()
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={language} alt="industry" className="img-fluid" />
                    <span>Languages I Know</span>
                  </label>
                  <p>
                    {profileDetails &&
                      profileDetails.language_known &&
                      profileDetails.language_known.length > 0
                      ? profileDetails.language_known.join(", ")
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={language} alt="industry" className="img-fluid" />
                    <span>Speak English</span>
                  </label>
                  <p>
                    {(profileDetails &&
                      profileDetails.speak_english &&
                      profileDetails.speak_english.length) > 0
                      ? profileDetails.speak_english
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={location_pin}
                      alt="industry"
                      className="img-fluid width11"
                    />
                    <span>Current District</span>
                  </label>
                  <p>
                    {(profileDetails &&
                      profileDetails.address_current &&
                      profileDetails.address_current.length) > 0
                      ? profileDetails.address_current
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={location_pin} alt="industry" className="img-fluid width11" />
                    <span>Home District</span>
                  </label>
                  <p className="">
                    {profileDetails &&
                      profileDetails.address_home &&
                      profileDetails.address_home.length > 0
                      ? profileDetails.address_home
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={video} alt="industry" className="img-fluid" />
                    <span>Video Resume</span>
                  </label>
                  <p>
                    {profileDetails && profileDetails.video_resume ? (
                      <>
                        <span>
                          {profileDetails &&
                            profileDetails.name &&
                            profileDetails.name.length > 0
                            ? profileDetails.name
                            : ""}
                        </span>
                        <button
                          type="button"
                          className="ml-2"
                          onClick={onToggleModal}
                          disabled={
                            profileDetails && profileDetails.video_resume
                              ? false
                              : true
                          }
                        >
                          <FaRegPlayCircle className="btn_icon" />
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <Player
                  open={modal}
                  toggleModal={onToggleModal}
                  src={profileDetails && profileDetails.video_resume}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

