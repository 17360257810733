// import "../../css/commonmodal.scss";
import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import others from "../../../assets/images/other.svg";
import classNames from "classnames";
import * as saarthiActions from "redux/actions/SaarthiActions";
import * as jobcategory from "redux/actions/jobcategory";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";



export const Industryinterested = ({ handelClose, jobInterested, userID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [input, setInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  // set job category list (26 categories)
  const [JobCategoryList, setJobCategoryList] = useState([]);
  const [JobIntrestedList, setJobIntrested] = useState([]);
  const [jobDisabled, setJobDisabled] = useState([]);
  
  // set jobInterested of user coming from props
  const [usersJob, setUsersJob] = useState(
    jobInterested.job_intrested && jobInterested.job_intrested.length
      ? jobInterested.job_intrested
      : []
  );

  const fetchJobCategoryList = async () => {
    try {
      let res = await dispatch(jobcategory.getJobCategoryListData());
      let jobInterestedList = [];
      if (res) {
        jobInterestedList = res.value.data;
      }
      setJobCategoryList(jobInterestedList); // set job category list (26 categories)
      const userJobIntrestedList = getJobIntrestedList();
      setJobIntrested(userJobIntrestedList);
      setJobDisabled([...userJobIntrestedList]);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchJobCategoryList();
  }, [dispatch]);

  // get list of job categories of the user
  const getJobIntrestedList = () => {
    let userJobIntrestedCategory = [];
    if (
      jobInterested.job_intrested && jobInterested.job_intrested.length
    ) {
      for (let val of jobInterested.job_intrested) {
        userJobIntrestedCategory.push(val.job_category);
      }
    }
    return userJobIntrestedCategory;
  };

  const { watch, register, handleSubmit, reset, errors } = useForm({
    mode: "onChange",
  });

  let job_intrested = watch("job_intrested", []);
  if(typeof(job_intrested) === "boolean"){
    job_intrested = [];
  }
  // const checkedCount = job_intrested.filter(Boolean).length;
  let jobSelected = [];
  jobSelected = JobIntrestedList;
  let count = jobSelected.length;
  // get all selected intrested jobs
  const setIndustry = (e) => {
    if (jobSelected.includes(e.target.value) || input) {
      const index = jobSelected.indexOf(e.target.value);
      jobSelected.splice(index, 1);
      count = count - 1;
    } else {
      if (e.target.value !== "") {
        jobSelected.push(e.target.value);
        count = count + 1;
      }
    }
  };

  // Method to set other job category field
  const onClickOther = (e) => {
    setIndustry(e);
    setInput(!input);
  };

  /**
   * @description method to update ntrsted job categories
   * @param {Object} val selected job categories
   */
  const update = async (val) => {
    try {
      if (val.others && count < 3) {
        jobSelected.push(val.others);
      }
      let job_intrested = usersJob;
      const jobintrestedList = getJobIntrestedList();
      //update job_intrested data
      for (let job of jobSelected) {
        if (!jobintrestedList.includes(job)) {
          let jobData = { job_category: job, skills: [] };
          job_intrested.push(jobData);
        }
      }
      for (let i = 0; i< job_intrested.length; i++){
        if(!jobSelected.includes(job_intrested[i].job_category)){
          job_intrested.splice(i, 1);
        }
      }
      console.log("job_intrested",job_intrested);
      let data = {
        job_intrested: job_intrested,
        _id: userID,
      };
      let res = await dispatch(saarthiActions.updateJSProfile(data));
      if (res.value.success) {
        dispatch(saarthiActions.jobSeekerDetails(userID));
        forSuccess('Job Interested is Updated Successfully', 'Success');
      }
    } catch (error) {
      forError('Something Went Wrong', 'Failure');
      console.log(error);
    }
    reset();
    handelClose();
  };

  const loadProfile = () => {
    reset(jobInterested);
  };

  return (
    <>
      <div className=" fixed fixedHeight">
        <div className="d-flex justify-content-between pt-1 fixed positionFixed ">
          <h6 className="in">
            <FormattedMessage
              id="jobInterest"
              defaultMessage="Job Interested In"
            />
          </h6>
        </div>
        <div className="d-flex justify-content-end pt-1"></div>
        <div className="afterPositio">
          <hr className="hrr" className="p-0 m-0" />
          <p className="in_a text-left mb-1">
            <FormattedMessage
              id="selectThree"
              defaultMessage="Select maximum 3"
            />
          </p>
        </div>
        <form onSubmit={handleSubmit(update)} className="fixed" id="myForm">
          <div className="job_cat">
            {JobCategoryList.map((val, index) => {
              return (
                <>
                  <input
                    type="checkbox"
                    id={val._id}
                    className="d-none"
                    name="job_intrested"
                    disabled={ !jobSelected.includes(val.name) && count >= 3
                          ? true
                          : false
                    }
                    checked={JobIntrestedList.includes(val.name) ? true : false}
                    value={val.name}
                    ref={register({
                      required: "Please select atleast 1 field",
                    })}
                    onClick={(e) => setIndustry(e)}
                  />
                  <label htmlFor={val._id} className="d-inline">
                    <div className="col-4 mt-3 d-inline-flex justify-content-around ">
                      <div>
                        <div className="d-flex justify-content-center">
                          <img
                            src={val.image}
                            style={{width: "80px"}}
                            className=" filter-green"
                          />
                        </div>
                        <h6 style={{ fontSize: "12px" }} className="text-center mt-2">
                          {val.name}
                        </h6>
                      </div>
                    </div>
                  </label>
                </>
              );
            })}
            <input
              type="checkbox"
              id="other"
              className="d-none w-75 border-primary"
              name="job_intrested"
              disabled={
                (!job_intrested.includes(otherInput)) && count >= 3 ? true : false
              }
              value={otherInput}
              ref={register({
                required: "Please select atleast 1 field",
              })}
              onClick={(e) => onClickOther(e)}
            />
            <label htmlFor="other" className="d-inline">
              <div className="col-4 mt-2 d-inline-flex justify-content-around">
                <div disabled={count >= 3 ? true : false}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={others}
                      style={{width: "80px"}}
                      className="filter-green"
                    />
                  </div>
                  <h6 style={{fontSize: "12px"}} className="text-center mt-2">
                    other
                  </h6>
                </div>
              </div>
            </label>
            {input && (
              <div className="mt-3 px-3 pb-4">
                <input
                  type="text"
                  name="others"
                  onInput={(e) => {
                    setOtherInput(e.target.value);
                  }}
                  placeholder="Enter your job category"
                  autoComplete="off"
                  className={classNames("other_input py-1 pl-2 form-control", {
                    "is-invalid": errors.others,
                  })}
                  ref={register({
                    required: "This field is required ",
                    maxLength: {
                      value: 30,
                      message: "Maximum 30 digit can be entered",
                    },
                  })}
                />
                {errors.others && (
                  <p className="text-danger oom text-center">
                    {errors.others.message}
                  </p>
                )}
              </div>
            )}
          </div>

          {errors.job_intrested && (
            <p className="text-danger oom text-center">
              {errors.job_intrested.message}
            </p>
          )}

          <div className="mt-3 pstt fixed">
            <button className="common_btn" type="submit">
              <FormattedMessage id="submit" defaultMessage="Submit" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
