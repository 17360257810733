import React from "react";
import { AdminHeader } from "../../common/header";

export const AdminWrapper = ({children}) => {
  /* method for sidebar */
  const [isActiveClass, setActiveClass] = React.useState(false);
  const toggleBox = (e) => {
    e.stopPropagation();
    setActiveClass(isActiveClass ? false : true);
  };
  /* function to close sidebar when click outside the sidebar except sidebar and humburger button */
  const mySidenav = document.getElementById('mySidenav');
  const humburger = document.getElementById('humburger');
  document.addEventListener('click', function(e) {
    if (e.target != mySidenav && e.target != humburger) {
      setActiveClass(false);
    }
  });
  return (
    <>
    <div>
      <AdminHeader toggleBox={toggleBox} isActiveClass={isActiveClass} setActiveClass={setActiveClass} />
      <div className={isActiveClass ? "wrapper_main slideright": "wrapper_main slidenormal"}>{children}</div>
    </div>
    </>
  );
};
