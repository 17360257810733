export const IndustryData = [
    "",
    "Apparel / Export houses",
    "Automobile / Auto Parts",
    "Aviation / Airlines",
    "Beauty / Cosmetics", 
    "BFSI / Banks / Insurance / Finance",
    "Construction",
    "Delivery / Cab",
    "Distribution / Warehouse",
    "Domestic Workers / Maids",
    "Education / Colleges / School", 
    "Education / PreSchool/ Nursery", 
    "Electronics",
    "Food Catering / Events", 
    "Food Industry / Food Outlets",
    "Furniture & Fittings",
    "Handicrafts", 
    "Healthcare / Hospital",
    "Hospitality / Hotel",
    "Housekeeping",
    "IT-Ites / BPO",
    "Landscaping / Gardening",
    "Logistics / Transport", 
    "Maintenance & Repair",
    "Manufacturing / Factory",
    "Parlour / Saloon",
    "Power / Electricity",
    "Production / Factory",
    "Recycling / Sanitation",
    "Restaurants / Bakery",
    "Retailers / Shops",
    "Security",
    "Skill Development",
    "Technical Installation",
    "Technology / Call Centre / BPO",
    "Telecom / Tower / Mobile Repair",
    "Textile Manufacturing",
    "Textile Mill / Manufacturing",
    "Tourism / Travel",
    "Waste Management",
    "Other"
]