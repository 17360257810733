export const CONFIG_URL = () => {
    let url = "https://dev.humlogjobs.com";
    if (process.env.REACT_APP_ENV === "staging") {
        url = "https://staging.humlogjobs.com";
    }
    if (process.env.REACT_APP_ENV === "production") {
        url = "https://humlogjobs.com";
    }
    return url;
};
// Experience Enum
export const Experience = {
    0 : "Fresher",
    1 : "Less than 1 year",
    2 : "1-2 years",
    3 : "2-4 years",
    4 : "4-6 years",
    5 : "6-8 years",
    6 : "8-10 years",
    7 : "10+ years"
}
// Job Type Enum
export const JobType = {
    0 : "Office",
    1 : "Field",
    2 : "Shift",
    3 : "Part Time"
}
// Job Preferable Gender Enum
export const Gender = {
    0 : "All",
    1 : "Female",
    2 : "Male",
    3 : "Others"
}
// User Gender Enum
export const UserGender = {
    0 : "Female",
    1 : "Male",
    2 : "Others"
}