import { SaarthiActionTypes } from '../actions/SaarthiActions/actionType';
import storage from 'utils/storage';

export const initialState = {
    jobList: [],
    saarthiDetails :  {},
    jobSeekerDetails : {},
    dashboardCount: {},
    jobDetails: {},
    userList: [],
}
export const saarthiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SaarthiActionTypes.GET_SAARTHI_DETAILS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                saarthiDetails: {},
              }); 
        case SaarthiActionTypes.GET_SAARTHI_DETAILS_FULFILLED:
            return Object.assign({}, state, {
                isLoading: false,
                saarthiDetails: action.payload.data
              });
        case SaarthiActionTypes.GET_JOBSEEKER_DETAILS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                jobSeekerDetails: {},
              }); 
        case SaarthiActionTypes.GET_JOBSEEKER_DETAILS_FULFILLED:
            return Object.assign({}, state, {
                isLoading: false,
                jobSeekerDetails: action.payload.data[0]
              });
        case SaarthiActionTypes.GET_DASHBOARD_COUNT_PENDING:
          return Object.assign({}, state, {
              isLoading: true,
              dashboardCount: {},
            }); 
        case SaarthiActionTypes.GET_DASHBOARD_COUNT_FULFILLED:
            return Object.assign({}, state, {
                isLoading: false,
                dashboardCount: action.payload.data
              });
        case SaarthiActionTypes.GET_JOBS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                jobList: [],
            });
        case SaarthiActionTypes.GET_JOB_DETAILS_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                jobDetails: {},
            });
        case SaarthiActionTypes.GET_JOB_DETAILS_FULFILLED:
            return Object.assign({}, state, {
                isLoading: true,
                jobDetails: action.payload.data,
            });
        case SaarthiActionTypes.GET_SAARTHI_JOBSEEKER_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                userList: [],
              }); 
        case SaarthiActionTypes.GET_SAARTHI_JOBSEEKER_FULFILLED:
            return Object.assign({}, state, {
                isLoading: false,
                userList: action.payload.data
              }); 
        default: return state;
    }
}