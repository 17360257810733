import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
    makeStyles
} from "@material-ui/core";

import * as commonService from "../../../../utils/CommonService.js";

const useStyles = makeStyles({
  root: {
    minWidth: 500,
    '@media (max-width:500px)': {
      minWidth: "400px"
    },
    '@media (max-width:400px)': {
      minWidth: "350px"
    },
    '@media (max-width:350px)': {
      minWidth: "300px"
    }

  },
  btn_center:  {
  width: "auto",
  margin: "auto",
  left: "auto",
  minWidth: "130px",
  '@media (max-width:350px)': {
    minWidth: "110px!important",
  }
},
dialog_width : {
  width: "350px",
  '@media (max-width:400px)': {
    width: "auto !important",
    padding: "30px 15px 15px !important"
  }
}
})
const ConfirmDialog = (props) => {
  const classes = useStyles();
  const defaultOptions = {
    open: props.open,
    data: { message: props.message },
    cancelText: "Cancel",
    confirmText: "Yes",
    onConfirm: () => {},
    onCancel: () => {}
  };
  const [dialogOptions, setDialogOptions] = useState(defaultOptions);

  // commonService.isDialogOpen.subscribe(data => {
  //   if (data && !dialogOptions.open) setDialogOptions(data);
  //   else if (!data && dialogOptions.open) setDialogOptions(defaultOptions);
  // });

  const {
    open,
    data,
    cancelText,
    confirmText,
    onConfirm,
    onCancel
  } = dialogOptions;

  const handleConfirm = () => {
    props.confirm(props.val);
    if (typeof onConfirm !== "undefined") onConfirm();
  };

  const handleClose = () => {
    if(props.cancel) {
      props.cancel();
    }
    props.setState({open: false, message: ""});
    if (typeof onCancel !== "undefined") onCancel();
  };
  return (
    <Dialog
      onClose={handleClose}
      className={classes.root}
      aria-labelledby="common-dialog-title"
      open={open}
      maxWidth={"md"}
    >

      <DialogContent className={`p-5 text-center ${classes.dialog_width}`} style={{width: 350}}>
        {data.title && (
            <DialogTitle id="common-dialog-title">{data.title}</DialogTitle>
        )}
        {data.message}</DialogContent>
      <Divider></Divider>
      {open && (
        <DialogActions className="p-2">
          <Button
            variant="outlined"
            size="medium"
            className={classes.btn_center}
            onClick={() => handleClose(true)}
          >
            {cancelText}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={`btn-primary ${classes.btn_center}`}
            onClick={() => handleConfirm()}
          >
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
