import admin_logo from "../../assets/images/logos/admin_logo.png";
import { useDispatch } from "react-redux";
import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "Languages/Wrapper/Wrapper";
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import Alert from "@material-ui/lab/Alert";
import * as adminActions from "redux/actions/AdminActions";
import { forSuccess } from "../services/commonservice";
import { forError } from "../services/commonservice";
import { forWarning } from "../services/commonservice";

export const UpdateSaarthiPassword = () => {
  const context = useContext(Context);
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const [error, setError] = useState({ open: false, message: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const password = useRef({});
  password.current = watch("password", "");
  /**
   * @description method for admin login
   * @param {param} data values of email and password 
   */
  const updateSaarthiPassword = async (data) => {
    try {
      const upadteDetails = {
        email : history.location.state.email,
        otp: data.otp,
        password: data.password,
        repeat_password : data.c_password
      };
      let res = await dispatch(adminActions.updateSaarthiPassword(upadteDetails))
      if (res.value.success) {
        setIsSubmitting(true);
        forSuccess('Your Password is successfully reset. Please login using your new password', 'Success');
        setTimeout(() => {
          history.push("/admin-login");
        }, 3000);
      }
    } catch (err) {
      setError({ open: true, message: err.data.message });
      setIsSubmitting(false);
      setTimeout(() => { setError({ open: false, message: "" }) }, 3000);
    }
  }

  return (
    <>
      {error.open && <Alert severity="error">{error.message}</Alert>}
      <div className="admin_login">
        <div className="container">
          <div className="admin_inner">
            <div className="admin_logo">
              <img src={admin_logo} alt="HumlogJobs" className="img-fluid d-block" />
            </div>
            <div className="flip-card">
              <div className="dashboard_login_box">
                <div className="admin_login_box flip-card-front">
                  <div className="admin_login_form update_password_form">
                    <h1>Reset Password</h1>
                    <form onSubmit={handleSubmit(updateSaarthiPassword)}>
                      <div class="form-group">
                        <label>OTP <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="text" 
                          name="otp" 
                          placeholder="Enter OTP" 
                          ref={register({
                            required: "You must enter OTP",
                          })} />
                        {errors.otp && (
                          <p className="help is-danger">{errors.otp.message}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <label>New Password <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="password" 
                          name="password" 
                          placeholder="New Password"
                          ref={register({
                            required: "This field is required.",
                            validate: {
                              validation: (value) =>
                                regexValidation.PASSWORD.test(value) ||
                                "Please enter at least 8 characters",
                            },
                          })} 
                        />
                        {errors.password && (
                          <p className="help is-danger">{errors.password.message}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <label>Confirm Password <sup className="red">*</sup></label>
                        <input 
                          autoComplete="off" 
                          className="form-control" 
                          type="password" 
                          name="c_password" 
                          placeholder="Confirm Password"
                          ref={register({
                            required: "This field is required.",
                            validate: {
                              validation: (value) =>
                                value === password.current || "The passwords do not match"
                            },
                          })}
                        />
                        {errors.c_password && (
                          <p className="help is-danger">{errors.c_password.message}</p>
                        )}
                      </div>
                      <div className="">
                        <button className="submitbtn" type="submit">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
