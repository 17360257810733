import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import gear from "../../../assets/images/admin/gear.svg";
import { useHistory } from "react-router-dom";
import location_pin from "../../../assets/images/admin/location-pin.svg";
import video from "../../../assets/images/admin/video.svg";
import language from "../../../assets/images/admin/language.svg";
import birthday from "../../../assets/images/admin/birthday.svg";
import gender from "../../../assets/images/admin/gender.svg";
import qualification from "../../../assets/images/admin/qualification.svg";
import technical_certificate from "../../../assets/images/admin/technical_certificate.svg";
import skills from "../../../assets/images/admin/skills.svg";
import briefcase from "../../../assets/images/admin/briefcase.svg";
import { FaCamera, FaRegPlayCircle } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import { useQuery } from "admin/services/commonservice";
import { Player } from "admin/common/player";
import CommonModal from "../../common/common-modal/index";
import { Updatename } from "../../common/formmodal/Updatename";
import { Aboutself } from "../../common/formmodal/Aboutself";
import { Industryinterested } from "../../common/formmodal/Industryinteresred";
import { Experience } from "../../common/formmodal/Experience";
import { Certificationform } from "../../common/formmodal/certification";
import { SkillsForm } from "../../common/formmodal/skillsForm";
import { Educatiofrm } from "../../common/formmodal/Educationfrm";
import { Userprofileinput } from "../../common/formmodal/Userprofileinput";
import { UpdateVideoResume } from "../../common/formmodal/updatevideoresume";
import ReactTooltip from 'react-tooltip';
import { uploadFile } from "../../../utils/fileService";
import { forSuccess } from "../../services/commonservice";
import { forError } from "../../services/commonservice";
import { forWarning } from "../../services/commonservice";

export const JobSeekerProfile = () => {
  const dispatch = useDispatch();
  const { jobSeekerDetails } = useSelector((state) => state.saarthiReducer);
  const history = useHistory();
  const query = useQuery();
  const userID = query.get("user_id");
  const [modal, setModal] = useState(false);
  const [isEditShow, setEditShow] = useState(false);

  const getJobSeekerDetails = async () => {
    try {
      let res = await dispatch(saarthiActions.jobSeekerDetails(userID));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getJobSeekerDetails();
  }, [dispatch]);

  const onToggleModal = () => {
    setModal(!modal);
  };

  const jobSeekerProfileEdit = () => {
    setEditShow(true);
  };
  const cancelEdit = () => {
    setEditShow(false);
  };

  let defaultForm = () => <div></div>;
  const [state, setState] = useState({ open: false });
  const [form, setForm] = useState({ defaultForm });
  const handelModal = (component) => {
    setState({ open: true });
    setForm(component);
  };

  const handelClose = () => {
    setState({ open: false });
  };
  const [picture, setPicture] = useState(person);
  const onChangePicture = async (e) => {
    try {
      const imageUrl = await uploadFile(e.target.files[0], "profile", '', userID);
      if (picture && picture.length > 0) {
        let formData = {
          profile_pic: imageUrl,
          _id: userID,
        };
        await dispatch(saarthiActions.updateJSProfile(formData))
          .then((res) => {
            if (res.value.success) {
              dispatch(saarthiActions.jobSeekerDetails(userID));
              forSuccess("Profile Picture Updated Successfully", "Success");
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (e) {
      forError("Something Went Wrong", "Failure");
      console.log("failed to upload image", e);
    }
  };

  return (
    <>
      <CommonModal open={state.open} handelClose={handelClose}>
        {form}
      </CommonModal>
      <div className="employer_profile ">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>{isEditShow ? ("Edit Job Seeker Profile Details") : ("Job Seeker Profile Details")}</h6>
            <div className="employer_profile_form jobseeker_profile_form">
              <button
                type="button"
                className="backbtn"
                data-tip="Back"
                onClick={() => history.goBack()}
              >
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <ReactTooltip />
              <form action="">
                <div className="profile_box">
                  <div className="profile_img">
                    <img
                      src={(jobSeekerDetails && jobSeekerDetails.profile_pic) ? jobSeekerDetails.profile_pic : person}
                      alt="Profile Picture"
                      className="img-fluid company_logo"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = person;
                      }}
                    />
                  </div>
                  {!isEditShow ? (
                    <div className="profile_editbtn" data-tip="Edit Profile">
                      <span onClick={jobSeekerProfileEdit}>
                        <BiEdit />
                      </span>
                      <ReactTooltip />
                    </div>
                  ) : (
                    <div className="cam_position">
                      <input
                        type="file"
                        className="d-none"
                        id="cam"
                        onChange={onChangePicture}
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="cam">
                        <FaCamera className="" />
                      </label>
                    </div>
                  )}
                </div>

                <div className="profile_name">
                  <h4>
                    {jobSeekerDetails &&
                    jobSeekerDetails.name &&
                    jobSeekerDetails.name.length > 0
                      ? jobSeekerDetails.name
                      : ""}
                  </h4>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Name</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Updatename
                                handelClose={handelClose}
                                name={jobSeekerDetails.name}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {
                      jobSeekerDetails &&
                      jobSeekerDetails.name
                      ? jobSeekerDetails.name
                      : ("")
                    }
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>About Me</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Aboutself
                                handelClose={handelClose}
                                aboutme={jobSeekerDetails.about_me}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {jobSeekerDetails &&
                    jobSeekerDetails.about_me
                      ? jobSeekerDetails.about_me
                          .map((data) => data.label)
                          .join(", ")
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={gear} alt="industry" className="img-fluid" />
                    <span>Job Interested In</span>
                      {
                        isEditShow ? (
                          <span className="edit_btn">
                            <BsPencil
                              onClick={() =>
                                handelModal(
                                  <Industryinterested
                                    handelClose={handelClose}
                                    jobInterested={{
                                      job_intrested: jobSeekerDetails.job_intrested,
                                    }}
                                    userID={userID}
                                  />
                                )
                              }
                            />
                          </span>
                        ) : (
                          ""
                        )
                      }
                  </label>
                  <p>
                    {jobSeekerDetails &&
                      jobSeekerDetails.job_intrested &&
                      jobSeekerDetails.job_intrested.length > 0 &&
                      jobSeekerDetails.job_intrested
                        .map((val) => val.job_category)
                        .join(", ")}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={briefcase} alt="role" className="img-fluid" />
                    <span>Years Of Experience</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Experience
                                handelClose={handelClose}
                                // experience={jobSeekerDetails.experience}
                                userData={{
                                  experience: jobSeekerDetails.experience,
                                  }}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {jobSeekerDetails && jobSeekerDetails.experience === 0
                      ? "Fresher"
                      : jobSeekerDetails && jobSeekerDetails.experience === 1
                      ? "Less than 1 Year"
                      : jobSeekerDetails && jobSeekerDetails.experience === 2
                      ? "1-2 years"
                      : jobSeekerDetails && jobSeekerDetails.experience === 3
                      ? "2-4 years"
                      : jobSeekerDetails && jobSeekerDetails.experience === 4
                      ? "4-6 years"
                      : jobSeekerDetails && jobSeekerDetails.experience === 5
                      ? "6-8 years"
                      : jobSeekerDetails && jobSeekerDetails.experience === 6
                      ? "8-10 years"
                      : jobSeekerDetails && jobSeekerDetails.experience === 7
                      ? "10+ years"
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={skills} alt="industry" className="img-fluid" />
                    <span>Skills</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <SkillsForm
                                handelClose={handelClose}
                                skill={jobSeekerDetails.job_intrested}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p style={{ height: "auto", minHeight: "42px" }}>
                    {jobSeekerDetails &&
                      jobSeekerDetails.job_intrested &&
                      jobSeekerDetails.job_intrested.length > 0 &&
                      jobSeekerDetails.job_intrested.map((val) => {
                        return val.skills.map((skill) => skill).join(", ");
                      })}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={qualification}
                      alt="industry"
                      className="img-fluid"
                    />
                    <span>Qualification</span>
                    {isEditShow ? (
                        <span className="edit_btn">
                          <BsPencil
                            onClick={() =>
                              handelModal(
                                <Educatiofrm
                                  handelClose={handelClose}
                                  qualification={jobSeekerDetails.qualification}
                                  userID={userID}
                                />
                              )
                            }
                          />
                        </span>
                      ) : (
                        ""
                      )}
                  </label>
                  <p>
                    {jobSeekerDetails &&
                    parseInt(jobSeekerDetails.qualification) === 0
                      ? "below 10"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 1
                      ? "10 th pass"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 2
                      ? "12 th pass"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 3
                      ? "B.A"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 4
                      ? "B.Sc"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 5
                      ? "B.Com"
                      : jobSeekerDetails &&
                        parseInt(jobSeekerDetails.qualification) === 6
                      ? "Other"
                      : jobSeekerDetails.qualification}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={technical_certificate}
                      alt="industry"
                      className="img-fluid"
                    />
                    <span>Technical Certification</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Certificationform
                                handelClose={handelClose}
                                certification={jobSeekerDetails.technical_cert}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {jobSeekerDetails && jobSeekerDetails.technical_cert
                      ? jobSeekerDetails.technical_cert.map((value, index) => {
                          return (
                            <span className="">
                              {value.cert}{" "}
                              {value.branch ? <>( {value.branch} )</> : <></>}
                            </span>
                          );
                        })
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={phone} alt="phone" className="img-fluid" />
                    <span>Mobile Number</span>
                    {/* {
                      isEditShow ? (
                        <span className="edit_btn">
                          <BsPencil
                            onClick={() =>
                              handelModal(
                                <Userprofileinput
                                  section="mobile"
                                  handelClose={handelClose}
                                  userData={{
                                    contact: jobSeekerDetails.contact,
                                  }}
                                  userID={userID}
                                />
                              )
                            }
                          />
                        </span>
                      ) : (
                      ""
                    )} */}
                  </label>
                  <p className="disabledfield">
                    {jobSeekerDetails &&
                    jobSeekerDetails.contact &&
                    jobSeekerDetails.contact.length > 0
                      ? jobSeekerDetails.contact
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={gender} alt="industry" className="img-fluid" />
                    <span>Gender</span>
                    {
                      isEditShow ? (
                        <span className="edit_btn">
                          <BsPencil
                            onClick={() =>
                              handelModal(
                                <Userprofileinput
                                  section="gender"
                                  handelClose={handelClose}
                                  userData={{
                                    gender: jobSeekerDetails.gender,
                                  }}
                                  userID={userID}
                                />
                              )
                            }
                          />
                        </span>
                      ) : (
                      ""
                    )}
                  </label>
                  <p className="">
                    {jobSeekerDetails && jobSeekerDetails.gender === 0
                      ? "Female"
                      : jobSeekerDetails && jobSeekerDetails.gender === 1
                      ? "Male"
                      : jobSeekerDetails && jobSeekerDetails.gender === 2
                      ? "Others"
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={birthday} alt="industry" className="img-fluid" />
                    <span>Date Of Birth</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Userprofileinput
                                section="dob"
                                handelClose={handelClose}
                                userData={{ dob: jobSeekerDetails.dob }}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {(jobSeekerDetails &&
                      jobSeekerDetails.dob &&
                      jobSeekerDetails.dob.length) > 0
                      ? new Date(jobSeekerDetails.dob).toLocaleDateString()
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={language} alt="industry" className="img-fluid" />
                    <span>Languages I Know</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Userprofileinput
                                section="language"
                                handelClose={handelClose}
                                userData={{
                                  language_known:
                                    jobSeekerDetails.language_known,
                                }}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {jobSeekerDetails &&
                    jobSeekerDetails.language_known &&
                    jobSeekerDetails.language_known.length > 0
                      ? jobSeekerDetails.language_known.join(", ")
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={language} alt="industry" className="img-fluid" />
                    <span>Speak English</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Userprofileinput
                                section="english"
                                handelClose={handelClose}
                                userData={{
                                  speak_english: jobSeekerDetails.speak_english,
                                }}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {(jobSeekerDetails &&
                      jobSeekerDetails.speak_english &&
                      jobSeekerDetails.speak_english.length) > 0
                      ? jobSeekerDetails.speak_english
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={location_pin}
                      alt="industry"
                      className="img-fluid width11"
                    />
                    <span>Current District</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                        <BsPencil
                          onClick={() =>
                            handelModal(
                              <Userprofileinput
                                section="currentAddress"
                                handelClose={handelClose}
                                userData={{
                                  address_current:
                                    jobSeekerDetails.address_current,
                                }}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {(jobSeekerDetails &&
                      jobSeekerDetails.address_current &&
                      jobSeekerDetails.address_current.length) > 0
                      ? jobSeekerDetails.address_current
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img
                      src={location_pin}
                      alt="industry"
                      className="img-fluid width11"
                    />
                    <span>Home District</span>
                  </label>
                  <p className="disabledfield">
                    {jobSeekerDetails &&
                    jobSeekerDetails.address_home &&
                    jobSeekerDetails.address_home.length > 0
                      ? jobSeekerDetails.address_home
                      : ""}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={video} alt="industry" className="img-fluid" />
                    <span>Video Resume</span>
                    {isEditShow ? (
                      <span className="edit_btn">
                      <BsPencil
                          onClick={() =>
                            handelModal(
                              <UpdateVideoResume
                                handelClose={handelClose}
                                userID={userID}
                              />
                            )
                          }
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <p>
                    {jobSeekerDetails && jobSeekerDetails.video_resume ? (
                      <>
                        <span>
                          {jobSeekerDetails &&
                          jobSeekerDetails.name &&
                          jobSeekerDetails.name.length > 0
                            ? jobSeekerDetails.name
                            : ""}
                        </span>
                        <button
                          type="button"
                          className="ml-2"
                          onClick={onToggleModal}
                          disabled={
                            jobSeekerDetails && jobSeekerDetails.video_resume
                              ? false
                              : true
                          }
                        >
                          <FaRegPlayCircle className="btn_icon" />
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <Player
                  open={modal}
                  toggleModal={onToggleModal}
                  src={jobSeekerDetails && jobSeekerDetails.video_resume}
                />
                {isEditShow ? (
                  <button
                    type="button"
                    className="canceltn"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
