import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Hindi from '../lang/hi.json';
import English from '../lang/en.json';
import Storage from 'utils/storage';

export const Context = React.createContext();
const current_lang =  Storage.get('lang')

const local = current_lang ? current_lang : 'en';

let lang;
if (local === 'en') {
    lang = English;
} else {
    lang = Hindi;
}

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(lang) {
        const newLocale = lang;
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
             setMessages(Hindi);
            
        }
    }
    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;