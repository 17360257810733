import search from "../../../assets/images/admin/search.svg";
import { FaUserPlus } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as saarthiActions from "redux/actions/SaarthiActions";
import { Context } from "Languages/Wrapper/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
import EnhancedTableHead from "../../common/enhancedTableHead"; // Common table action toolbar
import SearchBar from "material-ui-search-bar"; // searching bar
import { ImSortAmountAsc, ImSortAmountDesc} from "react-icons/im";
import ReactTooltip from "react-tooltip"; // React Tooltip

const useStyles = makeStyles((theme)=>({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(id, saarthi, regdate, tehsil, district, jobSeekerCount, set, status) {
  return {id, saarthi, regdate, tehsil, district, jobSeekerCount, set, status};
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'saarthi', numeric: false, disablePadding: false, label: 'Sarthi' },
  { id: 'regdate', numeric: false, disablePadding: false, label: 'Registration Date', align: 'center' },
  { id: 'tehsil', numeric: false, disablePadding: false, label: 'Working Tehsil', align: 'center' },
  { id: 'district', numeric: false, disablePadding: false, label: 'District', align: 'center' },
  { id: 'jobSeekerCount', numeric: false, disablePadding: false, label: 'Job Seeker Registered', align: 'center' },
  { id: 'set', numeric: false, disablePadding: false, label: 'Set', align: 'center' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', align: 'center' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', align: 'right' },
];

export const SaarthiList = (props) => {
  const query = new URLSearchParams(useLocation().search);
  let pageNumber = query.get("page"); // get page number
  let sortValue = query.get("sort"); // get sort value
  const [page, setPage] = useState(JSON.parse(pageNumber) ? JSON.parse(pageNumber) : 0 ); 
  const context = useContext(Context);
  const history = useHistory();
  const classes = useStyles();
  const { userList } = useSelector((state) => state.adminReducer);
  const [saarthiCount, setSaarthiCount] =useState(0);
  const [table, setTable] =useState([]);
  const [tableCount, setTableCount] =useState(0);
  const token = storage.get('humlog_authToken');
  const decodedToken = jwt_decode(token);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(); // set the default sorting order on the saarthi Name
  const [searched, setSearched] = useState(""); // variable use for storing searching text value
  const [rows, setRows] = useState([]); // variable use for storing table data
  const [setCount, setTotalCount] = useState(0); // storing total count
  const sortingIconClicked= useRef(false);
  const [sort, setSort] = useState(sortValue ? sortValue : ''); // storing sort values
 
  const createSortHandler = (property) => (event) => {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  };
  
  const dispatch = useDispatch();
  useEffect(() => {
    setTable([]);
    async function fetchData(pageNumber) {
      let query = {};
      if (page >= 0) {
        query.page = pageNumber ? pageNumber : page;
      }
      if (searched && searched.length) {
        query.search = searched;
      }
      if (sort) {
        query.sort = sort;
      }
      dispatch(saarthiActions.getSaarthiList(query))
        .then((response) => {
          if (response && response.value.success) {
            setTableData(response.value.data.data);
            setSaarthiCount(response.value.data.count);
            setTableCount(response.value.data.data.length);
            setTotalCount(response.value.data.count); // get and set Total count
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchData(pageNumber); // api to fetching table data
  }, [dispatch, page, sort, searched]);

  /**
   * @description method Set saarthi list data in table 
   * @param {Array} userList list of saarthis
   * @returns mapped table data
   */
  const setTableData = async (userList) => {
    let saarthiTable = [];
    if (userList && userList.length) {
      let tempArray = [...userList]
      for (let val of tempArray) {
        let data = createData(
          val._id,
          val.name ? val.name : '',
          val.created_at ? new Date(val.created_at).toLocaleDateString() : '',
          val.tehsil ? val.tehsil : '',
          val.district ? val.district : '',
          val.js_count ? val.js_count : 0,
          val.sub_role ? val.sub_role : '',
          'Active'
        );
        saarthiTable.push(data);
      }
    }
    setTable(saarthiTable);
    setRows(saarthiTable); // get table data
    setTable((state) => {
      return state;
    })
    return saarthiTable;
  }

  // update page count
  const handleChange =(event, value) => {
    setPage(value - 1);
  };
  // go to previous page
  const prev = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/saarthi-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  // got to next page
  const next = (pageNumber, sort) => {
    setPage(pageNumber);
    history.push({ pathname: '/saarthi-list', search: `?page=${pageNumber}&sort=${sort}` });
  };

  /**
   * @description method to open/close the menu options
   * @param {param} e event 
   */
   const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('table_dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }
  /**
   * @description method to close the dropdown when clicked outside
   * @param {param} event event 
   */
  window.onclick = function (event) {
    if (!event.target.matches('.table_dropbtn')) {
      var dropdowns = document.getElementsByClassName('table_dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  // method for redirecting to add saarthi form
  const goToAddSaarthi = () => {
    history.push('/create-saarthi');
  }
  // method to redirect to a saarthi profile
  const saarthiProfile = (e,id) => {
    history.push({pathname:'/saarthi-profile',search: "?user_id=" + id});
  }

  // function for handling filter sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // function for handling global Search
  const handleSearch = (searchedVal) => {
    if (
      searchedVal !== undefined &&
      searchedVal !== null
    ) {
      setSearched(searchedVal);
    }
  };
  // Handling search bar cross icon
  const cancelSearch = () => {
    setSearched("");
    handleSearch();
  };
  const handleSorting = (page) => {
    sortingIconClicked.current = !sortingIconClicked.current;
    if(sortingIconClicked.current === true) {
      setSort('Asc');
      history.push({ pathname: '/saarthi-list', search: `?page=${page}&sort=${'Asc'}` });
    } else {
      setSort('Desc');
      history.push({ pathname: '/saarthi-list', search: `?page=${page}&sort=${'Desc'}` });
    }
  };
  // show-hide ascending descending icon
  const _handleSortIcon = () => {
    if (sortingIconClicked.current === true) {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountAsc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    } else {
      return (
        <span data-tip="Sort by registration date">
          <ImSortAmountDesc
            onClick={() => handleSorting(page)}
            clickable="true"
            className="sorting-icon"
          />
        </span>
      );
    }
  };
  return (
    <>
      <div className="reg_main">
        <div className="container-fluid">
          <div className="reg_inner">
            <div className="table_menu">
              <div className="table_menu_lft">
                <p>Sarthi Count: {saarthiCount}</p>
              </div>
              <div className="table_menu_ryt">
                {decodedToken.sub_role === 0 ? (
                  <div className="add_saarthi" onClick={goToAddSaarthi}>
                    <FaUserPlus />
                    <div>
                      <p>Add Sarthi</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* sort-icon-section */}
                 {_handleSortIcon()}
                <div className="searchbox">
                  {/* searching-section start from here */}
                  <div className="table_menu_ryt">
                    <div className="searchbar_main">
                      <SearchBar
                        value={searched}
                        onRequestSearch={(searchVal) => handleSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                        className="searchbar"
                      />
                    </div>
                  </div>
                  {/* searching-section end */}
                </div>
                {/* Showing tooltip for ascending and descending icon */}
                <ReactTooltip />
              </div>
            </div>
            <div className="table_box">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="enhanced table">
                  {/* Table Action Toolbar Start From Here */}
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={table.length}
                  />
                  {/* Table Action Toolbar End */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.saarthi}
                            </TableCell>
                            <TableCell align="center">{row.regdate}</TableCell>
                            <TableCell align="center">{row.tehsil}</TableCell>
                            <TableCell align="center">{row.district}</TableCell>
                            <TableCell align="center">
                              {row.jobSeekerCount}
                            </TableCell>
                            <TableCell align="center">
                              {row.set === 1
                                ? "A"
                                : row.set === 2
                                ? "B"
                                : row.set === 3
                                ? "C"
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                                <span className="active">
                                  {row.status}
                                </span>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ position: "relative" }}
                            >
                              {
                                <div className="table_dropdownbox">
                                  <button
                                    type="button"
                                    className="table_moreoptns table_dropbtn"
                                    onClick={toggleMenu}
                                  >
                                    <FiMoreVertical />
                                  </button>
                                  <div className="table_dropdownmenu table_option_menu table_dropdown-content">
                                    <ul>
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={(e) =>
                                            saarthiProfile(e, row.id)
                                          }
                                        >
                                          Open Profile
                                        </a>
                                      </li>
                                      {/* <li>
                                            <a href="javascript:void(0)" onClick={() => {}}>
                                              Open Dashboard
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={() => {}}>
                                              view Job Seekers
                                            </a>
                                        </li> */}
                                    </ul>
                                  </div>
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.root}>
                <div className="pagination">
                  <button
                    type="button"
                    onClick={() => prev(page - 1, sort)}
                    disabled={page === 0 ? true : false}
                  >
                    Prev
                  </button>
                  <span>{page + 1}</span>
                  <button type="button" onClick={() => next(page + 1, sort)} disabled={tableCount === 30 ? false : true}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
