import { React, useState, useEffect } from 'react';
import * as saarthiActions from "redux/actions/SaarthiActions";
import { useDispatch, useSelector } from "react-redux";
import person from "../../../assets/images/admin/person.svg";
import phone from "../../../assets/images/admin/phone.svg";
import backbtn from "../../../assets/images/admin/backbtn.svg";
import building from "../../../assets/images/admin/building.svg";
import email from "../../../assets/images/admin/email.svg";
import eye from "../../../assets/images/admin/eye.svg";
import { useHistory } from "react-router-dom";
import storage from "utils/storage";

export const AdminSaarthiProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saarthiDetails } = useSelector((state) => state.saarthiReducer);
  const userId = storage.get('humlog_user')

  useEffect(() => {
    try{
      // get saarthi details
      dispatch(saarthiActions.saarthiDetails(userId));
    } catch(e){
      console.log('error', e);
    }
  }, [dispatch]);

  return (
    <>
      <div className="employer_profile">
        <div className="container-fluid">
          <div className="e_profile_inner">
            <h6>Admin Sarthi Profile Details</h6>
            <div className="employer_profile_form">
              <button type="button" className="backbtn" onClick={() => history.goBack()}>
                <img src={backbtn} alt="backbtn" className="img-fluid" />
              </button>
              <form action="">
                <div className="profile_img">
                  <img src={person} />
                </div>

                <div className="profile_name">
                  <h4>{saarthiDetails && saarthiDetails.hasOwnProperty('name') ? saarthiDetails.name : ''}</h4>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={person} alt="person" className="img-fluid" />
                    <span>Name</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('name') ? saarthiDetails.name : ''}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={eye} alt="industry" className="img-fluid" />
                    <span>Sarthi Code</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('saarthi_code') ? saarthiDetails.saarthi_code : ''}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    <img src={building} alt="industry" className="img-fluid" />
                    <span>Country</span>
                  </label>
                  <p>India</p>
                </div>
                {/* <div class="form-group">
                  <label htmlFor="">
                    <img src={phone} alt="phone" className="img-fluid" />
                    <span>Mobile Number</span>
                  </label>
                  <p>999 xxxx xxx </p>
                </div> */}
                <div className="form-group">
                  <label htmlFor="">
                    <img src={email} alt="industry" className="img-fluid" />
                    <span>Email Address</span>
                  </label>
                  <p>{saarthiDetails && saarthiDetails.hasOwnProperty('email') ? saarthiDetails.email : ''}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
