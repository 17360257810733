export const UserActionTypes = {
    GET_User: "GET_USER",
    GET_USER_PENDING: "GET_USER_PENDING",
    GET_USER_FULFILLED: "GET_USER_FULFILLED",

    GET_ALL_User: "GET_ALL_User",
    GET_ALL_USER_PENDING: "GET_ALL_USER_PENDING",
    GET_ALL_USER_FULFILLED: "GET_ALL_USER_FULFILLED",

    GET_User_CREATED_BY: "GET_User_CREATED_BY",
    GET_USER_CREATED_BY_PENDING: "GET_USER_CREATED_BY_PENDING",
    GET_USER_CREATED_BY_FULFILLED: "GET_USER_CREATED_BY_FULFILLED",


    GET_USER_BY_SARTHI: "GET_USER_BY_SARTHI",
    GET_USER_BY_SARTHI_PENDING: "GET_USER_BY_SARTHI_PENDING",
    GET_USER_BY_SARTHI_FULFILLED: "GET_USER_BY_SARTHI_FULFILLED",

    UPDATE_USER_DATA: "UPDATE_USER_DATA",
    UPDATE_USER_DATA_PENDING: "UPDATE_USER_DATA_PENDING",
    UPDATE_USER_DATA_FULFILLED: "UPDATE_USER_DATA_FULFILLED",
      
    UPDATE_SOCIAL_USER_DATA: "UPDATE_USER_DATA",
    UPDATE_SOCIAL_USER_DATA_PENDING: "UPDATE_USER_DATA_PENDING",
    UPDATE_SOCIAL_USER_DATA_FULFILLED: "UPDATE_USER_DATA_FULFILLED",


    UPSERT_USER_DATA: "UPSERT_USER_DATA",
    UPSERT_USER_DATA_PENDING: "UPSERT_USER_DATA_PENDING",
    UPSERT_USER_DATA_FULFILLED: "UPSERT_USER_DATA_FULFILLED",

    CONFIRM_OTP: "CONFIRM_OTP",
    CONFIRM_OTP_PENDING: "CONFIRM_OTP_PENDING",
    CONFIRM_OTP_FULFILLED: "CONFIRM_OTP_FULFILLED"
}
export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}