import { useIntl } from 'react-intl';
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
/* Admin Routers */
import { AdminLogin } from "../admin/login/adminlogin";
import { AdminDashboard } from "../admin/admin-employer/components/admindashboard";
import { SaarthiDashboard } from "../admin/admin-saarthi/components/saarthidashboard";
import { ResetSaarthiPassword } from "../admin/login/resetpassword";
import { UpdateSaarthiPassword } from "../admin/login/updatepassword";
// terms & conditions
import { Condition } from "../admin/common/ConditionPage";
import { PrivacyPage } from "../admin/common/PrivacyPage";
// admin
import { RegistrationList } from "../admin/admin-employer/components/registrationlist";
import { JobDescription } from "../admin/admin-employer/components/jobdescription";
import { AppliedJobSeekers } from "../admin/admin-employer/components/appliedjobseekers";
import { EmployerProfile } from "../admin/admin-employer/components/employerprofile";
import { AddEmployer } from 'admin/admin-employer/components/addEmployer';
import { ViewAudioJD } from "../admin/admin-employer/components/viewaudiojd";
import { Plans } from "../admin/admin-employer/components/plans";
import { EmailRequest } from "../admin/admin-employer/components/emailrequest";
import { RequestList } from "../admin/admin-employer/components/request";
import { CreateRoles } from "../admin/admin-employer/components/createRoles";
import { AddHLJEmployer} from '../admin/admin-employer/components/addHLJEmployer';
import { EmpRegistration} from '../admin/admin-employer/components/empRegistration';
// saarthi
import { JobseekerList } from 'admin/admin-saarthi/components/jobseekerList';
import { SaarthiList } from 'admin/admin-saarthi/components/saarthiList';
import { CommunityList } from 'admin/admin-saarthi/components/communityList';
import { AddSaarthi } from 'admin/admin-saarthi/components/addSaarthiForm';
import { JobsList } from 'admin/admin-saarthi/components/jobsList'; // saarthi job list
import { JobsListView } from 'admin/admin-saarthi/components/jobsListView'; // saarthi job view list
import { Performance } from "../admin/admin-saarthi/components/performance"; // Performance Screen
import { SaarthiJobSeekerProfile } from "../admin/admin-saarthi/components/saarthiJobSeekerProfile"; // Job Seeker Profile

import { AdminSaarthiProfile } from "../admin/admin-saarthi/components/adminsaarthiprofile";
import { SaarthiProfile } from "../admin/admin-saarthi/components/saarthiprofile";
import { JobSeekerProfile } from "../admin/admin-saarthi/components/jobseekerprofile";
import { ChangePassword } from "../admin/login/changepassword";

export const publicRoutes = [
  {
    key: "frontpage",
    path: "/",
    component: AdminLogin,
    exact: true,
  },
  {
    key: "admin-login",
    path: "/admin-login",
    component: AdminLogin,
    exact: true,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    component: ResetSaarthiPassword,
    exact: true,
  },
  {
    key: "update-password",
    path: "/update-password",
    component: UpdateSaarthiPassword,
    exact: true,
  },
  {
    key: "conditions",
    path: "/terms&conditions",
    component: Condition,
    exact: true,
  },
  {
    key: "privacyplocy",
    path: "/privacypolicy",
    component: PrivacyPage,
    exact: true,
  },
];
export const adminRoutes = [
  {
    key: "admin-dashboard",
    path: "/admin-dashboard",
    component: AdminDashboard,
    exact: true,
  },
  {
    key: "emp-registration",
    path: "/emp-registration",
    component: EmpRegistration,
    exact: true,
  },
  {
    key: "authentication-list",
    path: "/authentication-list",
    component: RegistrationList,
    exact: true,
  },
  {
    key: "authentication-list",
    path: "/authentication-list/:empStatus?", //for params use
    component: RegistrationList,
  },
  {
    key: "job-description",
    path: "/job-description",
    component: JobDescription,
    exact: true,
  },
  {
    key: "job-description",
    path: "/job-description/:job_Status?", //for params use
    component: JobDescription,
  },
  {
    key: "applied-job-seekers",
    path: "/applied-job-seekers",
    component: AppliedJobSeekers,
    exact: true,
  },
  {
    key: "employer-profile",
    path: "/employer-profile",
    component: EmployerProfile,
    exact: true,
  },
  // Email Request Screen
  {
    key: "email-request",
    path: "/email-request",
    component: EmailRequest,
    exact: true,
  },
  // Request Screen
  {
    key: "request-list",
    path: "/request-list",
    component: RequestList,
    exact: true,
  },
  {
    key: "audio-details",
    path: "/audio-details",
    component: ViewAudioJD,
    exact: true,
  },
  {
    key: "plans",
    path: "/plans",
    component: Plans,
    exact: true,
  },
  {
    key: "addEmployer",
    path: "/create-employer",
    component: AddEmployer,
    exact: true,
  },
  // Add roles component
  {
    key: "createRoles",
    path: "/roles",
    component: CreateRoles,
    exact: true,
  },
  // Add hlj-employer component
  {
    key: "addHLJEmployer",
    path: "/hlj-employer",
    component: AddHLJEmployer,
    exact: true,
  },
]
export const saarthiRoutes = [
  {
    key: "dashboard",
    path: "/",
    component: SaarthiDashboard,
    exact: true,
  },
  {
    key: "saarthi-dashboard",
    path: "/saarthi-dashboard",
    component: SaarthiDashboard,
    exact: true,
  },
  {
    key: "saarthi-list",
    path: "/saarthi-list",
    component: SaarthiList,
    exact: true,
  },
  {
    key: "Jobseeker-list",
    path: "/jobseeker-list",
    component: JobseekerList,
    exact: true,
  },
  {
    key: "Jobseeker-list",
    path: "/jobseeker-list/:js_status?",
    component: JobseekerList,
  },
  // Saarthi Job List Component
  {
    key: "Jobs-list",
    path: "/jobs-list",
    component: JobsList,
    exact: true,
  },
  // Saarthi Job View List
  {
    key: "jobs-list-specific",
    path: "/jobs-list-specific",
    component: JobsListView,
    exact: true,
  },
  {
    key: "addSaarthiForm",
    path: "/create-saarthi",
    component: AddSaarthi,
    exact: true,
  },
  {
    key: "admin-saarthi-profile",
    path: "/admin-saarthi-profile",
    component: AdminSaarthiProfile,
    exact: true,
  },
  {
    key: "saarthi-profile",
    path: "/saarthi-profile",
    component: SaarthiProfile,
    exact: true,
  },
  {
    key: "jobseeker-profile",
    path: "/jobseeker-profile",
    component: JobSeekerProfile,
    exact: true,
  },
  {
    key: "change-password",
    path: "/change-password",
    component: ChangePassword,
    exact: true,
  },
  {
    key: "community-list",
    path: "/community-list",
    component: CommunityList,
    exact: true,
  },
  // Performance Screen
  {
    key: "performance",
    path: "/performance",
    component: Performance,
    exact: true,
  },
  // Job Seeker Profile
  {
    key: "saarthi-jobseeker-profile",
    path: "/saarthi-jobseeker-profile",
    component: SaarthiJobSeekerProfile,
    exact: true,
  },
]
